import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { applyFormResourcesFilters } from 'components/tabulator/apply_filters';
import { loadTabulatorFormResouces } from 'components/tabulator/form_resources';

export default class extends Controller {
  static targets = ['queryInput', 'cleanQuery']
  static values = {
    url: String
  }

  connect() {
    displayPlaceholder();
    loadData(this.urlValue);
    this.queryInputTarget.value = localStorage.getItem('form_resources_query') || '';
    this.toggleIcon();
  }

  // Search part
  query() {
    this.toggleIcon();
    this.updateFilter();
    applyFormResourcesFilters();
  }

  cleanQuery() {
    this.queryInputTarget.value = '';
    this.toggleIcon();
    this.updateFilter();
    applyFormResourcesFilters();
  }

  updateFilter() {
    const query = this.queryInputTarget.value;
    localStorage.setItem('form_resources_query', query);
  }

  toggleIcon() {
    if (this.queryInputTarget.value.length > 0) {
      this.cleanQueryTarget.classList.remove('invisible');
    } else {
      this.cleanQueryTarget.classList.add('invisible');
    }
  }

  openResource(event) {
    // target is name div, env div, external_id div or form count div
    let target = event.currentTarget;
    // the parent is the full row, where we attached url dataset
    const parent = target.parentElement.parentElement;

    const tabulator = Tabulator.findTable('#form_resources')[0];
    $(parent).find("[tabulator-field='name'] a")[0].click();
  }

  export() {
    const tabulator = Tabulator.findTable('#form_resources')[0];
    tabulator.download('xlsx', 'export_forms.xlsx', { sheetName: 'export' });
  }
}
function loadData(url) {
  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
  .then(response => response.json())
  .then(responseJSON => {
    const resources = responseJSON.data;
    const config = responseJSON.config;
    document.getElementById('form_resources').outerHTML = `<div id="form_resources"></div>`;

    loadTabulatorFormResouces(resources, config);
  });
}
function displayPlaceholder() {
  document.getElementById('form_resources').innerHTML = document.getElementById('placeholder').innerHTML;
}
