// OLD FILTER, KEEP FOR OLD PAGE TESTS

import { BaseFilterController } from "./base_filter_controller";
import {
  displayPlaceholder,
  filterRequest,
  getFilters
} from "./utils";

export default class extends BaseFilterController {
  static targets = ["select", "menu"];

  filter(event) {
    displayPlaceholder();
    setAdvancedFilter(event.currentTarget.dataset.filterType)
    filterRequest()
    const icon = document.querySelector(`.advanced-filter-icon`);
    this.selectTarget.classList.add('active');
    icon.classList.add('fa-times');
    icon.classList.remove('fa-chevron-up', 'fa-chevron-down');
    icon.parentElement.setAttribute("data-action", `click->desktop--hr--filters--advanced-filter#resetFilter`);
  }

  resetFilter() {
    const icon = document.querySelector(`.advanced-filter-icon`);
    this.selectTarget.classList.remove('active');
    icon.classList.add('fa-chevron-down');
    icon.classList.remove('fa-times');
    setAdvancedFilter('')
    filterRequest()
    this.menuTarget.querySelectorAll('input[name="advanced"]').forEach(input => input.checked = false);
    this.toggleMenu()
  }
}

const setAdvancedFilter = filterType => {
  const objectFilters = getFilters();
  objectFilters['smart_filter'] = filterType;
  document.getElementById('filters').dataset.filters = JSON.stringify(objectFilters);
}
