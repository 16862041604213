import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['icon', 'secret']

  password() {
    let secret = this.secretTarget;
    let icon = this.iconTarget;

    if (secret.type == 'password') {
      icon.classList.remove('fa-eye-slash');
      icon.classList.add('fa-eye');
      secret.type = 'text';
    } else {
      icon.classList.remove('fa-eye');
      icon.classList.add('fa-eye-slash');
      secret.type = 'password';
    }
  }
}
