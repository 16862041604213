import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper', 'input']

  check() {
    let valueIsPresent = this.checkPresence();
    if (valueIsPresent && this.checkFormat()) {
      this.validateInput();
    } else {
      this.invalidateInput(valueIsPresent);
    }
  }

  // ----------------------------------------------------
  // Check methods
  // ----------------------------------------------------
  checkPresence() {
    if (this._isRequired()) {
      // the value is required : cannot be blank
      return (this.inputTarget.value.trim() != '')
    } else {
      // the value is not required : anyway
      return true
    }
  }

  checkFormat() {
    if (this.inputTarget.value) { return (this.checkCollection() && this.checkRegex()); } else { return true }
  }

  checkCollection() {
    let collection = this.inputTarget.options;

    if (collection) {
      // input has a collection : the value need to be included in the collection
      let collection_values = [...collection].map(el => el.value)
      collection_values.shift(); // remove the blank option

      return collection_values.length != 0 ? collection_values.includes(this.inputTarget.value) : true
    } else {
      // input hasn't a collection : anyway
      return true;
    }
  }

  checkRegex() {
    let regex_str = this.inputTarget.dataset.regex;

    if (regex_str == 'iban') {
      let IBAN = require('iban');
      return IBAN.isValid(this.inputTarget.value);
    } else if (regex_str == 'nir') {
      return isValidNir(this.inputTarget.value);
    } else if (regex_str) {
      // input has a regex : the value need to match with the regex
      let regex = this.createRegex(regex_str);
      return this.inputTarget.value.match(regex);
    } else {
      // input hasn't a regex : anyway
      return true;
    }
  }

  _isRequired() {
    return this.inputTarget.required == 'true' || this.inputTarget.ariaRequired == 'true'
  }

  // ----------------------------------------------------
  // Validation methods
  // ----------------------------------------------------
  validateInput() {
    this.inputTarget.dataset.validated = true;
    this.wrapperTarget.classList.remove('has-error');
    this.removeErrorMessage();
  }

  invalidateInput(valueIsPresent) {
    this.inputTarget.dataset.validated = false;
    this.wrapperTarget.classList.add('has-error');
    this.addErrorMessage(valueIsPresent);
  }

  // ----------------------------------------------------
  // Messages methods
  // ----------------------------------------------------
  addErrorMessage(valueIsPresent) {
    this.removeErrorMessage();
    let errorMessageId = this.defineErrorMessageId(valueIsPresent);
    let messageContent = document.getElementById(errorMessageId).innerHTML;
    this.wrapperTarget.insertAdjacentHTML('beforeend', messageContent);
  }

  defineErrorMessageId(valueIsPresent) {
    if (valueIsPresent) { return 'error-format'; } else { return 'error-presence'; }
  }

  removeErrorMessage() {
    let message = this.wrapperTarget.querySelector('span.help-block');
    if (message) { message.remove(); }
  }

  // ----------------------------------------------------
  // Messages methods
  // ----------------------------------------------------
  createRegex(string) {
    let matchArray = string.match(/^\/(.+)\/(.+)$/)
    if (matchArray) {
      return new RegExp(matchArray[1], matchArray[2]);
    } else {
      return new RegExp(string);
    }
  }
}


function isValidNir(string) {
  // traitement cas de la Corse on rend la zone a tester numérique
  var s7 = string.substring(6, 7)
  if ((s7 == "A") || (s7 == "B"))
    string = string.substring(0, 6) + "0" + string.substring(7, 15);
  // La zone doit alors etre numérique et ne contenir que 15 digits on valide par expression régulière
  var filter = /^\d{15}$/;
  if (!filter.test(string))
    return false
  else
  // la zone est numérique avec 15 caractères
  {
    // First digit must be 1,2 (for regular nir) or 7,8 (for temp nir)
    if (!["1", "2", "7", "8"].includes(string.substring(0, 1))) {
      return false;
    }
    var deb13 = parseInt(string.substring(0, 13));
    // traiter le cas de la corse
    if (s7 == "A") deb13 -= 1000000;
    if (s7 == "B") deb13 -= 2000000;
    // La Clé SS est égale au reste de la division par 97
    var div97 = 97 - (deb13 % 97);
    var sdiv97 = String(div97).padStart(2, '0'); // make sure the key is 2 digits

    if (string.substring(13, 15) != sdiv97) {
      return false;
    }
  }
  return true;
}
