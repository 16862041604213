import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token"

export default class extends Controller {
  static targets = ["input", "preview", "link", "deleteButton" ]
  static values = { deletePath: String }

  preview() {
    if (this.inputTarget.files && this.inputTarget.files[0]) { this.previewImage(); }
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.classList.remove('d-none')
    }
  }

  previewImage() {
    let previewElement = this.previewTarget;
    let file = this.inputTarget.files[0];
    let link = this.linkTarget;
    let reader = new FileReader();

    reader.onload = function (e) {
      if (file.type.match('image')) {
        $(previewElement).attr('src', e.target.result)
        link.innerText = ''
        $(link).attr('href', '#')
      } else {
        $(previewElement).attr('src', '#')
        link.innerText = file.name
        $(link).attr('href', e.target.result)
      }
    };
    reader.readAsDataURL(file);
  }

  previewLink(e) {
    e.preventDefault();
    let pdfWindow = window.open("", '_blank')
    pdfWindow.document.write(`<iframe width='100%' height='100%' src="${this.linkTarget.href}"></iframe>`, '_blank')
  }

  emptyPreview() {
    this.inputTarget.value = ''
    this.previewTarget.src = ''
    this.linkTarget.innerText = ''
    this.linkTarget.setAttribute('href', '#');

    if (this.deletePathValue != '') {
      fetch(this.deletePathValue, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken()
        },
      })
    }

    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.classList.add('d-none')
    }
  }
}
