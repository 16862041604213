import flatpickr from "flatpickr";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import { French } from "flatpickr/dist/l10n/fr.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import { Czech } from "flatpickr/dist/l10n/cs.js";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { Slovak } from "flatpickr/dist/l10n/sk.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { Polish } from "flatpickr/dist/l10n/pl.js";

const locales = {
  fr: French,
  en: 'default',
  de: German,
  es: Spanish,
  nl: Dutch,
  ar: Arabic,
  cs: Czech,
  pt: Portuguese,
  sk: Slovak,
  vi: 'default',
  it: Italian,
  pl: Polish
}

export function flatpickrFilter() {
  flatpickr('#definedDaysFrom', {
    mode: "range",
    dateFormat: "d-m-Y",
    locale: document.querySelector('html').lang,
    showMonths: 2,
    plugins: [new rangePlugin({ input: "#definedDaysTo"})],
    onChange: function(dates) {
      displayHiddenDays();
      if (dates.length === 2) {
        const from = dates[0];
        const to = dates[1];
        document.getElementById('definedDaysFrom').dataset.dateFormat = moment(from).format('YYYY-MM-DD');
        document.getElementById('definedDaysTo').dataset.dateFormat = moment(to).format('YYYY-MM-DD');
      }
    },
    onOpen: function(_selectedDates, _dateStr, _instance) {
      displayHiddenDays();
    },
    onMonthChange: function() {
      displayHiddenDays();
    },
    onYearChange: function() {
      displayHiddenDays();
    }
  });
};

// display other month day, otherwise all start the same day
const displayHiddenDays = () => {
  [...document.querySelectorAll('.flatpickr-day.nextMonthDay')].forEach(el => el.classList.remove('hidden'));
  [...document.querySelectorAll('.flatpickr-day.prevMonthDay')].forEach(el => el.classList.remove('hidden'));
}
