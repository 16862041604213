const updateManagerDashboardCount = () => {
  updateActionsCounterWith(actionsList());
  updateTracksCounterWith(actionsList());
  updateText('actions')
  updateText('tracks')
};

export { updateManagerDashboardCount };

// returns an array with the number of actions for each block -> [1, 3, 2, 4]
const actionsList = () => {
  const employeeBlocks = document.querySelectorAll('.employee-block');
  const numberOfActionsList = []
  employeeBlocks.forEach(block => numberOfActionsList.push(+block.dataset.actionsCount));
  return numberOfActionsList;
};

// addition all numbers of the actionsList and update the number in the header
const updateActionsCounterWith = (list) => {
  const actionsCount = list.length > 0 ? list.reduce((a, b) => a + b) : 0
  const actionsCountDiv = document.getElementById('actions-count');
  if (actionsCountDiv) { actionsCountDiv.innerHTML = actionsCount }
};

// count the number of tracks and update the number in the header
const updateTracksCounterWith = (list) => {
  const tracksCountDiv = document.getElementById('tracks-count');
  if (tracksCountDiv) { tracksCountDiv.innerHTML = list.length }
};

// change text for singular or plural
const updateText = (type) => {
  const element = document.getElementById(`${type}-count`);
  if (element) {
    const count = +element.innerText;
    const label = document.getElementById(`${type}-count-label`);
    let labelValue = ''
    switch (count) {
      case 0:
        labelValue = label.dataset.labelZero;
        break;
      case 1:
        labelValue = label.dataset.labelOne;
        break;
      default:
        labelValue = label.dataset.labelMore;
        break;
    }
    label.innerHTML = labelValue;
  }
}
