import { Controller } from "@hotwired/stimulus";

// This answer method is to show the hr how the survey from the collaborator view would look like
// It doesn't save anything
export default class extends Controller {
  answer(event) {
    const element = event.currentTarget;
    const question = document.getElementById(`question-resource-content-${element.dataset.questionId}`);
    const type = question.dataset.type;

    switch (type) {
      case 'unique_choice':
        removeSelectedClassTo(question, 'unique_choice');
        element.classList.add('selected');
        break;
      case 'multiple_choice':
        element.classList.toggle('selected');
        break;
      case 'scale':
        removeSelectedClassTo(question, 'scale_choice');
        element.classList.add('selected');
        break;
    }
  }
}

const removeSelectedClassTo = (question, className) => {
  question.querySelectorAll(`.${className}`).forEach(el => el.classList.remove('selected'));
};
