import { Controller } from "@hotwired/stimulus";
import {
  resetColumnFilters,
  cleanFiltersColors,
  displayCategoryFilters,
  hideCategoryFilters,
  cleanPagination,
  hide,
  displayPlaceholder,
  getFilters,
  delayRequestFor,
  filterRequest
} from "./utils";

const subfilters = { alert: ["alertPending", "alertDisengaged"],
                     paperwork: ["paperworkToValidate", "paperworkCompleted", "paperworkUncompleted"],
                     feedback: ["feedbackLowPulse", "feedbackHighPulse", "feedbackCompletedSurvey"],
                     action: ["actionToDo"] }

export default class extends Controller {
  static targets = ["alert", "paperwork", "feedback", "action", "alertPending", "alertDisengaged", "paperworkToValidate", "paperworkCompleted", "paperworkUncompleted", "feedbackLowPulse", "feedbackHighPulse", "feedbackCompletedSurvey", "actionToDo", "query", "cleanQuery"];
  static values = { url: String };

  connect() {
    resetColumnFilters();
    displayPlaceholder();
    filterRequest();
  }

  filter(event) {
    const target = event.currentTarget.dataset['desktop-Hr-Filters-SmartFiltersTarget'];
    const isActive = event.currentTarget.classList.contains('active');

    if (isActive && !isSmartFilter(target)) {
      return;
    } else if (isActive) {
      this.desactivate(target);
    } else {
      this.activate(target);
    }
  }

  desactivate(target) {
    cleanFiltersColors();
    cleanPagination();
    if (isSmartFilter(target)) {
      this[`${target}Target`].classList.remove('active');
    } else {
      this[`${target}Target`].classList.remove('active');
      document.querySelectorAll('.subfilter-category').forEach(subfilter => hide(subfilter));
    }
    hideCategoryFilters();
    this.setFilters('smart_filter', '');
    delayRequestFor('tracksDelay', 'medium');
  }

  activate(target) {
    let filterToToggle;
    cleanFiltersColors();
    cleanPagination();
    if (isSmartFilter(target)) {
      const subfilter = this.defineSubfilter(target);
      subfilter.classList.toggle('active');
      filterToToggle = subfilter.dataset['desktop-Hr-Filters-SmartFiltersTarget'];
      // color the smart filter itself
      this[`${target}Target`].classList.toggle('active');
      displayCategoryFilters(target);
    // clicked directly on a subfilter
    } else {
      filterToToggle = target;
      // color the smart filter itself
      this[`${target}Target`].classList.toggle('active');
      const smartFilter = this.defineSmartFilter(target);
      smartFilter.classList.toggle('active');
    }
    this.setFilters('smart_filter', filterToToggle);
    delayRequestFor('tracksDelay', 'medium');
  }

  query() {
    const query = this.queryTarget.value
    query != '' ? this.cleanQueryTarget.classList.remove('invisible') : this.cleanQueryTarget.classList.add('invisible');
    this.setFilters('query', query)

    // request with delay
    delayRequestFor('tracksDelay', 'medium');
  }

  cleanQuery() {
    this.queryTarget.value = '';
    this.query();
  }

  setFilters(filterKey, filterValue) {
    const objectFilters = getFilters();
    objectFilters[filterKey] = filterValue;
    document.getElementById('filters').dataset.filters = JSON.stringify(objectFilters);
  }

  defineSubfilter(filterName) {
    const subfilter = subfilters[filterName][0];
    return this[`${subfilter}Target`];
  }

  defineSmartFilter(filterName) {
    const smartFilter = Object.keys(subfilters).find(element => filterName.includes(element))
    return this[`${smartFilter}Target`];
  }
}

// the smart filter doesn't have a '-'
// only the subfilters have the '-' as they are the name is composed
function isSmartFilter(filterName) {
  return Object.keys(subfilters).includes(filterName);
}
