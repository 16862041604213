import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']
  static values = { url: String }

  connect() {
    this.clickHandler = event => {
      const filterDiv = document.querySelector('div[data-controller="desktop--hr--charts--level-organizations"]');
      if (!filterDiv.contains(event.target)) {
        const menu = document.getElementById('level-organization-menu');
        menu.classList.remove('menu-opened');
        menu.classList.add('menu-closed');
       };
    };

    document.addEventListener('click', this.clickHandler);
  }

  disconnect() {
    document.removeEventListener('click', this.clickHandler);
  }

  select(event) {
    const levelOrganizationId = event.currentTarget.value;
    document.getElementById('level-organization-name').innerText = event.currentTarget.previousElementSibling.innerText;
    $.ajax({
      url: this.urlValue,
      type: 'POST',
      data: {
        filters: JSON.parse(document.querySelector('div[data-desktop--hr--chart-filters-filters-value]').dataset['desktop-Hr-ChartFiltersFiltersValue']),
        level_organization_id: levelOrganizationId
      },
      dataType: 'json'
    }).then(data => {
      this.toggleMenu();
      const controller = Stimulus.controllers
                                 .filter(controller => controller.chart)
                                 .find(el => el.chart.canvas.parentNode.attributes['data-desktop--hr--chart-url-value'].value.match('track_horizontal_bars'));
      controller.chart.destroy();
      controller.draw(data);
    });
  }

  toggleMenu() {
    const menu = document.getElementById('level-organization-menu');
    if (menu.classList.contains('menu-opened')) {
      menu.classList.remove('menu-opened');
      menu.classList.add('menu-closed');
    } else {
      menu.classList.remove('menu-closed');
      menu.classList.add('menu-opened');
    }
  }
}
