import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  delay = 400 // ms

  connect() {
    this.search();
  }

  // Call on input event
  updateSearch() {
    // Kill the previous delay
    window.clearTimeout(this.timer);
    // Set the new search delay
    this.timer = window.setTimeout(() => { this.search() }, parseInt(this.delay))
  }

  search() {
    $.ajax({
      url: this.element.dataset.url,
      type: 'GET',
      dataType: 'script',
      data: {
        query: this.inputTarget.value,
        exception_ids: this._exception_ids()
      }
    });
  }

  _exception_ids() {
    return [...document.querySelectorAll("#env-tag-list .env-tag")].map(el => el.dataset.unitOrganizationId)
  }
}
