// This controller will take all 'line-class' elements and remove all the designated active class
// and set the active class on the element clicked.
//
// How to set up the trigger for this controller :
// Example :
//   data-controller=                                     'utils--selecting-elements'
//   data-action=                                         'click->utils--selecting-elements#call'
//   data-utils--selecting-elements-active-class-value=   'active'
//   data-utils--selecting-elements-line-class-value=     'question-line'
//
// Don't forget to set a css class with the given active class !!

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    lineClass: String,
    activeClass: String
  }

  call() {
    let activeClass = this.activeClassValue;
    let lineClass = this.lineClassValue;
    this.removeAllActiveClass(activeClass, lineClass);
    this.addActiveClass(activeClass);
  }

  removeAllActiveClass(activeClass, lineClass) {
    document.querySelectorAll(`.${lineClass}`).forEach(line => {
      line.classList.remove(activeClass)
    })
  }

  addActiveClass(activeClass) {
    this.element.classList.add(activeClass);
  }
}
