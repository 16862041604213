import { reconnectStimulusControllerFor } from 'utils/reconnect_stimulus_controller';

export function reloadAdministrativeTabProgress() {
  reloadAdministrativeProgress();
  reloadFillablePdfsProgress();
}

function reloadAdministrativeProgress() {
  const administrative = document.getElementById('download-all-forms');
  if(!administrative) return;
  reconnectStimulusControllerFor(administrative);
}

function reloadFillablePdfsProgress() {
  const fillable_pdfs = document.querySelectorAll('div[data-controller=desktop--hr--tabs--fillable-pdfs]')

  fillable_pdfs.forEach(function (fillable_pdf) {
    // remove all elements that are not a template
    fillable_pdf.querySelectorAll(":not(template)").forEach((el) => { el.remove() });
    reconnectStimulusControllerFor(fillable_pdf);
  })
}


