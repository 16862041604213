import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { unsavedChanges: Boolean }

  connect() {
    this.unsavedChangesValue = false;
    window.addEventListener('beforeunload', function (event) {
      let unsavedChanges = document.body.dataset['desktop-Actor-SavingsUnsavedChangesValue'] == 'true';
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  change() {
    this.unsavedChangesValue = true;
  }

  save() {
    this.unsavedChangesValue = false;
  }

  disconnect() {
    this.unsavedChangesValue = false;
  }
}
