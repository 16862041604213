import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static values = {
    applicationId: String,
    publicKey: String,
    railsEnv: String,
    placeholder: String
  }

  connect() {
    algoliaSearchUnitOrganizations(
      this.publicKeyValue,
      this.placeholderValue,
      this.applicationIdValue,
      this.railsEnvValue
    )
  }
}

function algoliaSearchUnitOrganizations(public_key, placeholder, applicationIdValue, railsEnvValue){
  const client = instantsearch({
    searchClient: algoliasearch(
      applicationIdValue,
      public_key
    ),
    indexName: `UnitOrganization_${railsEnvValue}`
  });
  const UnitOrganizationsHits = instantsearch.widgets.hits({
    container: document.querySelector('#organizations'),
    escapeHTML: true,
    templates: {
      item: getTemplate('unit_organization'),
      empty: getTemplate('no-results'),
    }
  });
  const searchBox = instantsearch.widgets.searchBox({
    container: document.querySelector('#search-input'),
    placeholder: placeholder,
  });
  const pagination = instantsearch.widgets.pagination({
    container: '#pagination',
    scrollTo: false
  });
  const hitsPerPage = instantsearch.widgets.hitsPerPage({
    container: document.querySelector('#hits-per-page-selector'),
    items: [
      {value: 10, default: true}
    ]
  });
  client.addWidget(UnitOrganizationsHits);
  client.addWidget(searchBox);
  client.addWidget(pagination);
  client.addWidget(hitsPerPage);
  client.start();
};
