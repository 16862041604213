import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // We listen to two different events:
  // beforeinstallprompt@window->mobile--pwa-prompt#beforeInstall
  // click->mobile--pwa-prompt#showPrompt

  // beforeinstallprompt is emitted automatically by the browser when there is
  // an installable PWA.

  // To allow a PWA to be installed you need :
  // - a valid manifest and
  // - a valid service worker

  beforeInstall(event) {
    event.preventDefault();

    // We store the event in this.deferredPrompt to be able
    // to use it later in showPrompt().
    this.deferredPrompt = event;

    // If the browser emitted beforeinstallprompt it means that we can
    // display the button used to launch the prompt.
    this.element.classList.remove('d-none')
  }

  showPrompt() {
    // When the user click on the button we call the function prompt()
    // stored on the event we stored above in this.deferredPrompt. This function
    // launch the native prompt on Android.
    this.deferredPrompt.prompt();
  }
}
