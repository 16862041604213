import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accessRestrictionsSelectize", "accessRestrictionsInput", "checkbox"];

  connect() {
    this._checkAccessRestrictions();
    this.initialAccessRestrictionsValue = this.accessRestrictionsInputTarget.value;
  }

  _checkAccessRestrictions() {
    const existingValues = this.accessRestrictionsInputTarget.dataset.existingValues.split(',').filter(item => item !== "");
    this.checkboxTarget.checked = existingValues.length > 0;
    this.toggleAccessRestrictions();
  }

  toggleAccessRestrictions() {
    if (this.checkboxTarget.checked) {
      this.accessRestrictionsSelectizeTarget.style.display = "block";
      this.accessRestrictionsInputTarget.value = this.initialAccessRestrictionsValue;
    } else {
      this.accessRestrictionsSelectizeTarget.style.display = "none";
      this.initialAccessRestrictionsValue = this.accessRestrictionsInputTarget.value;
      this.accessRestrictionsInputTarget.value = "";
    }
  }
}
