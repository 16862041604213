import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let observer = new MutationObserver(this.observeAttributes.bind(this));
    observer.observe(this.element, { subtree: true, attributes: true });
    setTimeout(this.updateVisibility.bind(this), 100)
  }

  observeAttributes(mutationsList) {
    mutationsList.forEach((mutation) => {
      // Target is a field AND mutation is on hidden attribute
      if (mutation.target.dataset.fieldId && mutation.type === 'attributes' && mutation.attributeName === "hidden") {
        this.updateVisibility();
      }
    });
  }

  updateVisibility() {
    (this.isEmpty()) ? this.hide() : this.show()
  }

  isEmpty() {
    return this.element.querySelectorAll("[data-field-id][data-resource-id]:not([hidden]):not([initial-hidden='true'])").length == 0;
  }

  hide() {
    this.element.setAttribute('hidden', '');
  }

  show() {
    this.element.removeAttribute('hidden');
  }
}
