import { Controller } from "@hotwired/stimulus";
// Tooltip itself, you can set any style you want
// traingle is the arrow
//
//  <div id='tooltip-<%= dom_id(element) %>' class='tooltip-card otherClasses'>
//    <span class='triangle'></span>
//  </div>
//
// Element on which the tooltip will be triggered
// the element with id 'hover-element' have to have the same class name as the id set on the tooltip.
// for that reason, you cannot set another class directly on this element
// but you can set it on the parent element.
//
// <div id='hover-element' class='tooltip-<%= dom_id(element) %>' data-controller='utils--css-tooltips'></div>

export default class extends Controller {
  connect() {
    this.element.addEventListener('mouseover', (event) => {

      let className = event.target.getAttribute("class");
      if (className) {
        let tooltipElement = document.getElementById(className);
        tooltipElement.style.display = "block";

        document.addEventListener('mouseout', () => {
          tooltipElement.style.display = "none";
        });

        window.onmousemove = (mouseEvent) => {
          let x = mouseEvent.x, y = mouseEvent.y;
          tooltipElement.style.left = (x - 50) + 'px';
          tooltipElement.style.top = (y - 50) + 'px';
        };
      }
    });
  }
}
