import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list", "templateLine", "empty", 'input'];

  triggerInput() {
    this.inputTarget.click();
  }

  addFileLine() {
    const paperworkId = this.element.dataset.id;
    const files = [...this.inputTarget.files];
    files.forEach(file => this.apprendLine(file, paperworkId));
    this.emptyTarget.classList.remove('d-flex');
    this.emptyTarget.classList.add('d-none');
  }

  removeFileLine(e) {
    const name = e.currentTarget.dataset.name
    document.getElementById(`file-${name}`).remove();
    if (this.listTarget.children.length === 0) {
      this.emptyTarget.classList.remove('d-none');
      this.emptyTarget.classList.add('d-flex');
    }
  }

  apprendLine(file, paperworkId) {
    this.listTarget.insertAdjacentHTML('beforeend', this.renderHtml(file.name, paperworkId));
  }

  renderHtml(name, paperworkId) {
    return this.templateLineTarget.innerHTML.replaceAll('{{{name}}}', name).replaceAll('{{{paperworkId}}}', paperworkId);
  }
}
