export const toggleActive = (filter, select) => {
  isActive(filter, select) ? activate(select) : desactivate(select);
}

const isActive = (filter, select) => {
  return select.parentElement.querySelectorAll(`.${filter}-filter[data-selected='false']`).length > 0
};
export const activate = select => select.dataset.active = true;
export const desactivate = select => select.dataset.active = false;

export const updateLocalStorage = (page, filterType, menu) => {
  const values = [...menu.querySelectorAll(`.${filterType}-filter[data-selected='true']`)].map(el => el.dataset.value);
  localStorage.setItem(`${page}_${filterType}s`, JSON.stringify(values));
}

export const selectAllElements = filterType => {
  document.querySelectorAll(`.${filterType}-filter`).forEach(el => el.dataset.selected = true);
};

export const removeAllElements = filterType => {
  document.querySelectorAll(`.${filterType}-filter`).forEach(el => el.dataset.selected = false);
};

export const setFiltersWithLocalStorage = (page, filterType, menu) => {
  const values = [...menu.querySelectorAll(`.${page}-filter`)].map(el => el.dataset.value);
  const currentFilters = localStorage.getItem(`${page}_${filterType}s`) || localStorage.setItem(`${page}_${filterType}s`, JSON.stringify(values));
  const elements = document.querySelectorAll(`.${page}-filter.${filterType}-filter`);
  const ids = [...elements].map(el => el.dataset.value);
  let selectedIds;
  try {
    selectedIds = JSON.parse(currentFilters);
  } catch (error) {
    selectedIds = ids;
  }
  const unselected = [...elements].map(el => el.dataset.value).filter(id => !selectedIds.includes(id));
  unselected.forEach(id => unselect(menu.querySelector(`.${filterType}-filter[data-value="${id}"]`)))
}

const unselect = element => {
  element.dataset.selected = false;
}
