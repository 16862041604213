import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    acceptedExtensions: String
  }

  connect() {
    Dropzone.autoDiscover = false;

    const dropzone = new Dropzone (this.element, {
      paramName: 'translate_email_template[photo]',
      acceptedFiles: this.acceptedExtensionsValue,
      maxFilesize: 10
    });

    dropzone.on('queuecomplete', (_file) => {
      $.ajax({
        url: `${this.element.action}?drop=${drop}`,
        type: "PATCH"
      });
    });

    let drop = '' // need to define the drop variable
    dropzone.on('error', function(_file, _errorMessage, _xhr) {
      return drop = 'error'
    });
    dropzone.on('success', function(_file, _errorMessage, _xhr) {
      return drop = 'success'
    });
  }
}
