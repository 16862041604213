import { Controller } from "@hotwired/stimulus";

// Reminder :
//  a descendant of one resource is a template
//  a descendant of one paperwork_resource is a form_resource
//  an ascendant of one form_resource is a paperwork_resource
//  an ascendant of one template is a resource
//  there is no descendant of template
//  there is no ascendant of resource (except form_resource)
//  there is no ascendant of paperwork_resource
export default class extends Controller {
  static values = { 
    ascendanceDeletingError: String,
    descendanceDeletingError: String,
    ascendanceAddingError: String,
    sharedDescendanceAddingError: String,
    unsharedDescendanceAddingError: String,
    necessaryDescendanceWarning: String
  };
  input = document.getElementById('unit_organization_ids_input');

  delete(event) {
    let currentTag = event.target.closest('[data-unit-organization-id]');
    if (cannotDeleteUnitOrganization()) {
      displayMessage(this.ascendanceDeletingErrorValue);
    } else if (cannotBeDeleted(currentTag)) {
      displayMessage(this.descendanceDeletingErrorValue);
    } else {
      this._deleteEnvTag(currentTag);
    }
  }

  add(event) {
    let currentItem = event.target.closest('[data-unit-organization-id]');

    if (isUnacceptableForAscendance(currentItem)) {
      // ERROR : One or more items of information on the form are not authorised in this environment
      displayMessage(this.ascendanceAddingErrorValue);
    } else if (hasDescendants() && hasSharedDescendants()) {
      // ERROR : This resource is used on one or more shared models
      displayMessage(this.sharedDescendanceAddingErrorValue);
    } else if (hasDescendants() && (hasDescendantsInOtherUnitOrganizations(currentItem) && isFromSharedEnv())) {
      // ERROR : This resource is used in models that are not in this environment
      displayMessage(this.unsharedDescendanceAddingErrorValue);
    } else if (hasDescendants() && isFromSharedEnv()) {
      displayMessage(this.necessaryDescendanceWarningValue, 'warning');
      this._addNecessaryUnitOrganizationsOfDescendance();
    } else {
      this._addEnvTag(currentItem);
    }
  }

  _addNecessaryUnitOrganizationsOfDescendance() {
    let descendants = descendantElements();
    let unsharedDescendants = [...descendants].filter(descendant => !!descendant.dataset.unitOrganizationIds);
    let addableUnitOrganizationIds = union(unitOrganizationIdsListFor(unsharedDescendants));

    addableUnitOrganizationIds.forEach(id => {
      let item = this.element.querySelector(`[item][data-unit-organization-id="${id}"]`)
      this._addEnvTag(item)
    })
  }

  _addEnvTag(currentItem) {
    document.getElementById('env-tag-list').insertAdjacentHTML('beforeend', newTagFor(currentItem));
    let new_value = this.input.value + ` ${currentItem.dataset.unitOrganizationId}`
    this._changeInputValueBy(new_value);
    removeItem(currentItem);
  }

  _deleteEnvTag(currentTag) {
    let unitOrganizationId = currentTag.dataset.unitOrganizationId;
    let new_value = this.input.value.split(' ').filter(id => id !== unitOrganizationId).join(' ')
    this._changeInputValueBy(new_value);
    currentTag.remove();
    document.getElementById('environment-search-button').click();
  }

  _changeInputValueBy(new_value) {
    // Change value
    this.input.value = new_value;
    // Trigger change event of input
    let event = new Event('change');
    this.input.dispatchEvent(event)
  }
}

// We can't add a unitOrganization if there's not shared ascendant AND all ascendants have the unitOrganization
const isUnacceptableForAscendance = (currentItem) => {
  let ascendants = ascendantElements()
  let unsharedAscendants = [...ascendants].filter(ascendant => !!ascendant.dataset.unitOrganizationIds);
  let addableUnitOrganizationIds = intersection(unitOrganizationIdsListFor(unsharedAscendants));
  
  return unsharedAscendants.length > 0 && !addableUnitOrganizationIds.includes(currentItem.dataset.unitOrganizationId);
}

const hasDescendantsInOtherUnitOrganizations = (currentItem) => {
  let descendants = descendantElements();
  let unsharedDescendants = [...descendants].filter(ascendant => !!ascendant.dataset.unitOrganizationIds);
  let descendantsUnitOrganizationIds = union(unitOrganizationIdsListFor(unsharedDescendants));

  return !descendantsUnitOrganizationIds.includes(currentItem.dataset.unitOrganizationId)
}

const isFromSharedEnv = () => {
  let envTags = document.getElementById('env-tag-list').children;
  return envTags.length == 0;
}

const hasSharedDescendants = () => {
  let descendants = descendantElements();
  return containsEmptySubarray(unitOrganizationIdsListFor(descendants))
}

const hasDescendants = () => {
  let descendants = descendantElements();
  return descendants.length >= 1
}

// We can't deleted the last unitOrganization if a ascendant has unitOrganizations
const cannotDeleteUnitOrganization = () => {
  let envTags = document.getElementById('env-tag-list').children;
  let ascendants = ascendantElements();
  let undeletableUnitOrganizationIds = union(unitOrganizationIdsListFor(ascendants));
  // We remove the shared environment
  undeletableUnitOrganizationIds = undeletableUnitOrganizationIds.filter(el => !!el)

  return envTags.length == 1 && undeletableUnitOrganizationIds.length >= 1
}


// We can't deleted a unitOrganization if a descendant has it
const cannotBeDeleted = (currentTag) => {
  let descendants = descendantElements();
  let undeletableUnitOrganizationIds = union(unitOrganizationIdsListFor(descendants));

  return undeletableUnitOrganizationIds.includes(currentTag.dataset.unitOrganizationId);
}

// Return list of lists
// Examples :
//    [['1', '2', '3'], ['1', '3'], ['3']] -> applied in 3 descendants
//    [['']]                               -> shared case
const unitOrganizationIdsListFor = (elements) => {
  return [...elements].map(el => el.dataset.unitOrganizationIds.trim().split(' '));
}

const descendantElements = () => {
  return document.querySelectorAll('[descendant]');
}

const ascendantElements = () => {
  return document.querySelectorAll('[ascendant]');
}

const newTagFor = (currentItem) => {
  let newTag = document.getElementById('env-tag-template').innerHTML;
  newTag = newTag.replace('{{id}}', currentItem.dataset.unitOrganizationId);
  newTag = newTag.replace('{{name}}', currentItem.innerText);
  return newTag;
}

// If the item is the last, remove the group
const removeItem = (item) => {
  if ([...item.parentElement.querySelectorAll('[item]')].length == 1) {
    item.parentElement.remove();
  } else {
    item.remove();
  }
}

const intersection = (arrays) => {
  if (arrays.length === 0) return [];
  return arrays.reduce((acc, array) => acc.filter(item => array.includes(item)));
}

const union = (arrays) => {
  return [...new Set(arrays.flat())];
}

const containsEmptySubarray = (list) => {
  for (let subArray of list) {
    if (isEmptyStringArray(subArray)) { return true; }
  }
  return false;
}

const isEmptyStringArray = (list) => {
  return Array.isArray(list) && list.length === 1 && list[0] === ''
}

const displayMessage = (message, type = 'alert') => {
  console.log(message);
  if (message) { flash(message, type) };
}
