function changeSpecificFileData(element) {
  $.ajax({
    url: element.dataset['url'],
    type: defineMethod(element.checked)
  });

  function defineMethod(checked) {
    if (checked) { return 'PATCH' } else { return 'DELETE' }
  }
}
export { changeSpecificFileData }
