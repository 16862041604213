import { Controller } from "@hotwired/stimulus";
import { Tabulator } from "tabulator-tables";

export default class extends Controller {
  static targets = ['elements', 'mobility', 'unitOrganizations', 'button', 'spinner']
  static values = { url: String }

  update(event) {
    event.preventDefault();
    this.displaySpinner();
    const mobilities = this.mobilityTargets.filter(el => el.checked).map(el => el.value)
    const elements = JSON.parse(this.elementsTarget.dataset.data)
    const unit_organization_ids = this.hasUnitOrganizationsTarget ? this.unitOrganizationsTarget.value.trim() : null

    fetch(`${this.urlValue}/${elements[0].id}`, {
      method: 'PUT',
      headers: headers(),
      body: JSON.stringify({
        mobilities: mobilities,
        training_resource: {
          unit_organization_ids: unit_organization_ids
        },
        elements: elements
      })
    })
    .then(response => response.json())
    .then(data => {
      this.refreshResourcesAndClean(data.flash_message);
    }).catch(error => {
      console.log(error);
    })
  }

  destroy(event) {
    event.preventDefault();
    this.displaySpinner();
    const elements = JSON.parse(this.elementsTarget.dataset.data)
    fetch(`${this.urlValue}/${elements[0].id}`, {
      method: 'DELETE',
      headers: headers(),
      body: JSON.stringify({
        elements: elements
      })
    })
    .then(response => response.json())
    .then(data => {
      this.refreshResourcesAndClean(data.flash_message);
    }).catch(error => {
      console.log(error);
    })
  }

  refreshResourcesAndClean(flash_message) {
    // reload tabulator
    Stimulus.controllers.find(el => el.identifier == 'desktop--settings--resources--resources').connect()

    // sort and filter to show new resources
    sortAndFilter();

    // close modal and display back buttons
    $('#modal-edit-resources').modal('hide');
    $('#modal-edit-resources').on('hidden.bs.modal', () => {
      this.buttonTargets.forEach(el => el.classList.remove('d-none'));
      this.spinnerTarget.classList.add('d-none');
    })

    // confirmation message
    flash(flash_message, 'notice');

    // close sidebar
    Stimulus.controllers.find(el => el.identifier == 'desktop--settings--resources--creation-sidebar').closeSidebar();

    // reset stored data
    this.elementsTarget.dataset.data = '';

    // reset dropzone
    [...document.querySelectorAll('#file-dropzone .dz-preview')].forEach(el => el.remove());
    document.querySelector('#file-dropzone').classList.remove('dz-started', 'dz-drag-hover');
  }

  displaySpinner() {
    this.buttonTargets.forEach(el => el.classList.add('d-none'));
    this.spinnerTarget.classList.remove('d-none');
  }
}

const headers = () => {
  return {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
}

const sortAndFilter = () => {
  // sort tabulator by modification date to show the new resources
  Tabulator.findTable('#resources')[0].setSort('updated_at_value', 'desc');
  setTimeout(() => {
    document.querySelector('div[tabulator-field="updated_at"]')?.setAttribute('aria-sort', 'descending');
    // filter on training_resource
    document.querySelector("[data-action='click->desktop--actor--filter--resource-component--resource-component#removeAll']")?.click();
    document.querySelector("[data-action='click->desktop--actor--filter--resource-component--resource-component#filter'][data-value='training_resource'] span")?.click();
  }, 300);
};

