import { Controller } from "@hotwired/stimulus";

import {
  displayError,
  hideError,
  checkPasswordLenght,
  checkPasswordVariety,
  checkUniformity,
  passwordIsValid,
  passwordConfirmationValid,
  displayElement,
} from '../../pages/sign_in/password_utils';

export default class extends Controller {
  static targets = [
    "form",
    "passwordInput",
    "passwordConfirmationInput",
    "conditionInput",
    "conditionError",
    "submitInput"
  ]

  checkPassword() {
    let passwordInput = this.passwordInputTarget;

    checkPasswordLenght(passwordInput)
    checkPasswordVariety(passwordInput);
    if (passwordIsValid(passwordInput.value)) { displayElement('#password-confirmation-block') }
    this.checkPasswordConfirmation();
    this.globalCheck();
  }

  checkPasswordConfirmation() {
    checkUniformity(this.passwordInputTarget, this.passwordConfirmationInputTarget);
    this.globalCheck();
  }

  checkConditions() {
    if (this.conditionInputTarget.checked) {
      hideError(this.conditionErrorTarget.id);
      displayElement(this.submitInputTarget.id);
    } else {
      displayError(this.conditionErrorTarget.id);
    }
    this.globalCheck();
  }

  checkBeforeSubmit(event) {
    let password = this.passwordInputTarget.value;
    let confirmation = this.passwordConfirmationInputTarget.value;

    event.preventDefault();

    if (passwordConfirmationValid(password, confirmation) && this.conditionIsChecked()) {
      this.formTarget.submit();
    } else {
      this.checkPassword();
      this.checkPasswordConfirmation();
      this.checkConditions();
    }
  }

  globalCheck() {
    let isConfirmed = passwordConfirmationValid(this.passwordInputTarget.value, this.passwordConfirmationInputTarget.value);
    let isChecked = this.conditionIsChecked();

    if (isConfirmed && isChecked) {
      this.submitInputTarget.disabled = false;
      this.submitInputTarget.classList.remove('invisible');
    } else {
      this.submitInputTarget.disabled = true;
    }
  }

  conditionIsChecked() {
    if (this.hasConditionInputTarget) { return (this.conditionInputTarget.checked); } else { return true }
  }
}
