import { BaseResourcesController } from "./base_resources_controller";

export default class extends BaseResourcesController {
  static targets = ['select', 'menu', 'search']

  defineName() {
    this.pluralName = 'templates';
    this.singularName = 'template';
  }
}
