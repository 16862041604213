import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['more', 'less', 'list']

  toggle() {
    ['list', 'more', 'less'].forEach(type => {
      this[`${type}Target`].classList.toggle('d-none');
    })
  }
}


