import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['survey']

  search(event) {
    const value = event.currentTarget.value;
    const elements = this.surveyTargets;
    elements.forEach(el => el.classList.remove('d-none'));
    const names = elements.map(el => el.innerText)
    const unmatching_elements = [];
    names.forEach((name, i) => {
      if (!name.toLowerCase().includes(value.toLowerCase())) {
        unmatching_elements.push(elements[i]);
      }
    });
    unmatching_elements.forEach(el => el.classList.add('d-none'));
  }
}
