import { Controller } from "@hotwired/stimulus"
import { filterRequest } from "./utils";

// This controller handles the delaying of the filter refresh resquests
// How it's works :
//   - At connect, we set an MutationObserver on this controller's element
//   - The observer runs its callback when one data-attributes has been changed
//   - The callback : kills the previous timer
export default class extends Controller {
  connect() {
    let observer = new MutationObserver(this.delayRequest);
    observer.observe(this.element, { attributes: true });
  }

  // Executed callback when a mutation is observed
  delayRequest(mutationsList) {
    for(let mutation of mutationsList) {
      let tracksDelay = mutation.target.dataset.tracksDelay

      if ((mutation.attributeName == 'data-tracks-delay') && (tracksDelay)) {
        delayTrackSelection(tracksDelay)
      }
    }
  }
}

// Need this varaible to store and clear previous timeout
let tracksTimer;
// Clear previous tracksTimer and launch filter
function delayTrackSelection(delay) {
  window.clearTimeout(tracksTimer);
  tracksTimer = window.setTimeout(() => { filterRequest() }, parseInt(delay))
}
