import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { buildInitialFilter } from '../../../../../../../components/tabulator/apply_filters'
import { updateCounter, blankState, escapeHtml } from '../../../../../../../components/tabulator/utils'

export default class extends Controller {
  static targets = ['forms' ]
  static values = {
    url: String,
    locale: String
  }

  connect() {
    this.displayPlaceholder();
    this.buildTabulator();
  }

  displayPlaceholder() {
    this.formsTarget.innerHTML = document.getElementById('placeholder').innerHTML;
  }

  buildTabulator() {
    let currentController = this;
    const initialSort = JSON.parse(localStorage.getItem('forms_sorters'));
    const translations = JSON.parse(this.formsTarget.parentElement.dataset.translations);
    const additionalColumns = JSON.parse(this.formsTarget.parentElement.dataset.paperworks);

    const tabulator = new Tabulator('#forms', {
      layout: "fitDataFill",
      langs: { 'fr-fr': {} },
      initialSort: initialSort || [{ column: 'name', dir: 'asc' } ],
      movableColumns: true,
      placeholder: blankState(),
      height: "100%",
      rowHeight: 60,
      initialFilter: buildInitialFilter(),
      filterMode:"remote",
      sortMode: "remote",
      paginationMode: "remote",
      pagination: true,
      paginationSize: 20,
      paginationCounter: function(pageSize, currentRow, currentPage, totalRows, totalPages){
        return `${translations['results']} ${currentRow} ${translations['to']} ${currentRow + pageSize - 1}`;
      },
      ajaxURL: this.urlValue,
      ajaxConfig: {
        method:"POST",
        headers: {
          'X-CSRF-Token': csrfToken()
        }
      },
      ajaxResponse: function(url, params, response){
        if(response['count'] !== undefined) {
          updateCounter('forms', response['count']);
        }
        const followupController = currentController.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller*='tabulator-bulk-selection']"), 'tabulator-bulk-selection')

        if (followupController) {
          followupController.uncheckAll();
        }

        return response; //return the response data to tabulator
      },
      columns: [
        { field: 'bulk_select', vertAlign: 'middle', hozAlign: 'left', frozen: true, headerHozAlign: 'left', headerSort:false},
        { title: translations['collaborator'], headerSort: false, width: 250, field: 'collaborator', frozen: true, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left'},
        { field: 'recipient_label', visible: false },
        { field: 'environment_id', visible: false },
        { field: 'resource_id', visible: false },
        { title: translations['recipient'], headerSort: false, resizable: true, minWidth: 200, field: 'recipient', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left'},
        { title: translations['environment'], minWidth: 200, resizable: true, field: 'environment', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'middle'},
        { title: translations['start_date'], minWidth: 200, resizable: true, field: 'start_date', vertAlign: 'middle', hozAlign: 'middle', headerHozAlign: 'middle'},
        { title: translations['due_date'], minWidth: 200, resizable: true, field: 'due_date', vertAlign: 'middle', hozAlign: 'middle', headerHozAlign: 'middle'},
        { title: translations['status'], headerSort: false, minWidth: 200, field: 'status', vertAlign: 'middle', hozAlign: 'middle', headerHozAlign: 'middle'},
      ].concat(additionalColumns).concat([
        { field: 'form_actions', vertAlign: 'middle', hozAlign: 'right', headerHozAlign: 'middle', minWidth: 380 }
      ]),
      rowFormatter: function (row) {
        var data = row.getData();
        var rowEl = row.getElement();
        rowEl.querySelector(`[tabulator-field="collaborator"]`).classList.add("pointer");
        rowEl.querySelector(`[tabulator-field="collaborator"]`).dataset.controller = "tracking";
        rowEl.querySelector(`[tabulator-field="collaborator"]`).dataset.action = "click->desktop--hr--tracks#select click->tracking#track";
        rowEl.querySelector(`[tabulator-field="collaborator"]`).dataset.employeeId = data.employee_id;
        rowEl.querySelector(`[tabulator-field="collaborator"]`).dataset.trackId = data.track_id;

        const implication_scope = document.getElementById("switch-implication") ? document.getElementById("switch-implication").checked : true;

        const trackedData = {
          event: "Get to boardee profil page",
          status: data.status_key,
          mobility: data.mobility,
          employee_id: data.employee_id,
          implication_scope: implication_scope,
          search: document.getElementById("search_collaborator_input").value
        };

        const element = rowEl.querySelector(`[tabulator-field="collaborator"]`);

        for (const key in trackedData) {
            if (trackedData.hasOwnProperty(key)) {
                element.setAttribute(`data-tracked-${key}`, trackedData[key]);
            }
        }


        rowEl.style='style="display: flex;align-items: center;height: 100%;"';
        rowEl = currentController.renderAdditionalColumns(additionalColumns, rowEl, data)
        currentController.renderHtmlFor(rowEl, data, tabulator);
        rowEl
      }
    });
    const locale = document.querySelector('html').lang

    tabulator.on('dataSorted', function (sorters, rows) {
      const saved_sorters = [];
      sorters.forEach((sorter) => {
        saved_sorters.push({ column: sorter.column.getField(), dir: sorter.dir });
      });
      localStorage.setItem('forms_sorters', JSON.stringify(saved_sorters));
    });

    tabulator.on("tableBuilt", function(){
      const columnStatesJSON = localStorage.getItem("tabulator-#" + tabulator.element.getAttribute("id"));
      if (columnStatesJSON) {
        const columnStates = JSON.parse(columnStatesJSON);
        const columns = tabulator.getColumns();
        columns.forEach((column) => {
          const columnName = column.getField();
          const columnState = columnStates[columnName];
          if (columnState && columnState.visible == false) {
            tabulator.toggleColumn(columnName);
          }
        });
      }
      tabulator.setFilter(buildInitialFilter());
      updateCounter('forms');
    });

    setTimeout(() => {
      tabulator.setLocale(`${locale}-${locale}`);
      updateCounter('forms');
    }, 100);
  }

  renderAdditionalColumns(additionalColumns, rowEl, data){
    additionalColumns.forEach((column) => {
      var paprID = column.field.split('-')[1]
      var papID = data["papr-id-" + paprID]
      if(papID){
        var id = papID.split('-')[1]
        const fieldSelector = rowEl.querySelector(`[tabulator-field="${column.field}"]`);
        fieldSelector.classList.add("text-14px");
        fieldSelector.dataset.controller = "paperwork tracking";
        fieldSelector.dataset.paperworkIdValue = id;
        fieldSelector.dataset.paperworkType = data["pap-type-" + id];
        fieldSelector.dataset.paperworkStatusValue = data["pap-status-" + id];
        fieldSelector.dataset.paperworkFormStatusValue = data["status_key"];
        fieldSelector.dataset.paperworkTarget = "element";
        fieldSelector.dataset.locale = this.localeValue;
        if (data["pap-type-" + id].includes('file')) {
          fieldSelector.dataset.action = "click->tracking#track click->desktop--actor--analyse--form-resources--forms--sidebar#openSidebar";
          fieldSelector.dataset.paperworkFilesCount = data["pap-files-count-" + id];

          const trackedData = {
            event: "Open file preview",
            mobility: data.mobility,
            employee_id: data.employee_id,
            form_id: data.id,
            paperwork_id: papID,
            url: window.location.href,
            referer: document.referrer,
            event_time: new Date().toISOString()
          };

          for (const key in trackedData) {
              if (trackedData.hasOwnProperty(key)) {
                fieldSelector.setAttribute(`data-tracked-${key}`, trackedData[key]);
              }
          }


        }
      } else {
        const fieldSelector = rowEl.querySelector(`[tabulator-field="${column.field}"]`);
        fieldSelector.style.cursor = "not-allowed";
      }
    });
    return rowEl;
  }

  renderHtmlFor(row, data, tabulator) {


    // the following object is used to render the html for the row
    // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
    // the values are the attributes to be replaced in the html, matching the properties of the data we receive
    const matchingObj = {
      recipient: ["recipient_label"],
      collaborator: ["collaborator_full_name", "collaborator_mobility", "image_url"],
      environment: ["collaborator_environment"],
      start_date: ["start_date"],
      due_date: ["due_date"],
      status: ["status", "status_key", "hr_status", "hr_status_key"],
      form_actions: ["form_approvals", "resource_id", "status_key", "paperworks", "followup_date", "collaborator_full_name", "hr_status_key"],
      bulk_select: ["resource_id"]
    };

    Object.keys(matchingObj).forEach(template_id => {
      let templateId = template_id;

      let finalHtml = document.getElementById(templateId).innerHTML;
      matchingObj[template_id].forEach(attribute => {
        finalHtml = finalHtml.replaceAll(`{{${attribute}}}`, escapeHtml(data[attribute]));
      });

      var position = tabulator.getRowPosition(row);
      finalHtml = finalHtml.replaceAll('{{row_position}}', escapeHtml(position));

      var locale = document.querySelector('html').lang;
      finalHtml = finalHtml.replaceAll('{{locale}}', locale);

      row.querySelector(`[tabulator-field="${template_id}"]`).innerHTML = finalHtml;
    });
  }
}


const clickOn = (target, ctrlKey = false) => {
  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    ctrlKey: ctrlKey,
    metaKey: ctrlKey // for mac
  });

  target.dispatchEvent(event);
}
