export const scrollToElement = (selector, offset = 0, who_scroll_selector = 'html, body') => {
  const element = document.querySelector(selector);

  if (element) {
    const contentContainer = document.querySelector(who_scroll_selector);
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const containerPosition = contentContainer.getBoundingClientRect().top + window.pageYOffset;

    contentContainer.scrollBy({
      top: elementPosition - containerPosition - offset,
      behavior: 'smooth'
    });
  }
};
