import Sortable from 'sortablejs';
import { csrfToken } from "utils/csrf_token";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    new Sortable(this.element, {
      animation: 150,
      filter: '.undraggable',
      ghostClass: 'opacity-40',
      onEnd: function (event) {
        const ids = [];
        [...event.target.children].forEach((el, i) => {
          const id = el.dataset.id;
          if (id) { ids.push(id) };
        });
        $.ajax({
          url: '/actor/analyse/chart_assignment_positions',
          type: 'post',
          headers: {
            'X-CSRF-Token': csrfToken()
          },
          data: { chart_assignment: ids },
          dataType: 'script'
        });
      }
    });
  }
}
