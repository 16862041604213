import { BaseFilterController } from "./base_filter_controller";

export default class extends BaseFilterController {
  static targets = ["select", "menu", "onboarding", "crossboarding", "offboarding"];

  controller() {
    return 'Mobility';
  }

  column() {
    return 'mobility';
  }

  // return the controller name in html
  htmlTarget() {
    return 'mobility';
  }
}
