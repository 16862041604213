import { Controller } from "@hotwired/stimulus"

// Example :
//   <% if current_user && current_user == true_user %>
//     <div data-controller='tracking'
//           data-tracking-connect-value="true"
//           data-tracked-event="Get to analytic page"
//           data-tracked-url="<%= request.url %>"
//           data-tracked-referrer="<%= request.referrer %>"
//           data-tracked-current_sign_in_at="<%= current_user.current_sign_in_at.to_i %>">
//     </div>
//   <% end %>
export default class extends Controller {
  static values = { connect: Boolean }

  connect() {
    if (this.connectValue) { setTimeout(this.track.bind(this), 100) };
  }

  track() {
    if (document.body.dataset.trueUser == 'true') {
      let dataset = this.element.dataset
      let eventProperties = defineProperties(dataset);
      let eventName = dataset.trackedEvent

      analytics.track(eventName, eventProperties);
    }
  }
}

// Define event properties from data-attributes'tracked-xxxx'
function defineProperties(dataset) {
  let properties = {};
  const propertiesKeys = Object.keys(dataset).filter(key => (key.startsWith("tracked") && (key != 'trackedEvent')));

  propertiesKeys.forEach(function(key) {
    properties[key.replace('tracked', '').toLowerCase()] = dataset[key]
  })
  properties['event_time'] = new Date().toISOString();

  return properties
}
