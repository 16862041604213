function addNextActions(event, url, onboardee_id, filter = null) {
  if (event) { event.preventDefault() };
  let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'script',
    data: {'iterate_count': iterate_count, 'filter': filter}
  });
}
export { addNextActions }

function planMeeting(event, url, onboardee_id, filter = null) {
  event.preventDefault();
  let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
  let cursor = document.getElementById('actions-list-' + onboardee_id).dataset.cursor;
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'script',
    data: {'iterate_count': iterate_count, 'cursor': cursor, 'filter': filter, 'dashboard_manager': true }
  });
}
export { planMeeting }

function destroyMeeting(event, url, onboardee_id, filter = null) {
  if (document.getElementById('actions-section')) {
    event.preventDefault();
    let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
    let cursor = document.getElementById('actions-list-' + onboardee_id).dataset.cursor;
    $.ajax({
      url: url,
      type: 'DELETE',
      dataType: 'script',
      data: {'iterate_count': iterate_count, 'cursor': cursor, 'filter': filter, 'dashboard_manager': true }
    });
  } else if (document.getElementById('manager-calendar')) {
    event.preventDefault();
    $.ajax({
      url: url,
      type: 'DELETE',
      dataType: 'script'
    });
  }
}
export { destroyMeeting }

function validateAction(event, url, method, onboardee_id, filter = null, locale = 'fr') {
  event.preventDefault();
  let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
  let cursor = document.getElementById('actions-list-' + onboardee_id).dataset.cursor;
  let dashboardId = document.querySelector('[data-dashboard-id]').dataset["dashboardId"]
  $.ajax({
    url: url,
    type: method,
    dataType: 'script',
    data: {'iterate_count': iterate_count, 'cursor': cursor, 'filter': filter, 'dashboard_id': dashboardId, 'locale': locale}
  });
}
export { validateAction }

function dismissAction(event, url, onboardee_id, filter = null, action) {
  event.preventDefault();
  let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
  let cursor = document.getElementById('actions-list-' + onboardee_id).dataset.cursor;
  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'script',
    data: {'iterate_count': iterate_count, 'cursor': cursor, 'filter': filter, event: action}
  });
}
export { dismissAction }

function uploadPaperworkDocument(event, onboardee_id, filter = null, paperwork_id, locale) {
  event.preventDefault();
  let iterate_count = document.getElementById('actions-list-' + onboardee_id).dataset.iteration;
  let cursor = document.getElementById('actions-list-' + onboardee_id).dataset.cursor;
  $.ajax({
    url: '/' + locale + '/paperworks/' + paperwork_id + '/dashboard_uploaded_paperwork_documents/new',
    type: 'GET',
    dataType: 'script',
    data: {'iterate_count': iterate_count, 'cursor': cursor, 'filter': filter}
  });
}
export { uploadPaperworkDocument }

function openResourceContent(event, url, link, resource_type) {
  event.preventDefault();
  window.open(link, '_blank');
  $.ajax({
    url: url,
    type: 'PATCH',
    dataType: 'script',
    data: {'resource': resource_type}
  });
}
export { openResourceContent }

function scrollToAlertAction() {
  var action = document.querySelector('.highlighted-action');
  if (action) {
    window.addEventListener('load', function() {
      window.scrollTo(0, action.offsetTop - 145);
    }, false )
  }
}
export { scrollToAlertAction }

function filterActions(event, filter, url) {
  var current_filter = document.getElementById('actions-cards').dataset.filter;
  if (current_filter) {
    $('#'+ current_filter).removeClass('active-filter');
  }
  if (current_filter == filter) {
    filter = null
  } else {
    $('#'+ filter).toggleClass('active-filter');
  }

  document.getElementById('actions-cards').dataset.filter = filter;
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'script',
    data: {'filter': filter}
  });
}
export { filterActions }

function initShowOnboardeeBlock() {
  document.querySelectorAll('.onboardee-block-header').forEach(function(onboardee_block) {
    onboardee_block.addEventListener('click', function(event){
      if (this.dataset.selected == 'false') {
        $.ajax({
          url: this.dataset.path,
          type: 'GET',
          dataType: 'script',
        });
      } else if (this.dataset.selected == 'true') {
        window.open(this.dataset.path, '_self');
      }
    })
  })
}
export { initShowOnboardeeBlock }

function initCloseOnboardeeBlock(onboardee_id, url) {
  document.getElementById('back_navbar').addEventListener('click', function(event) {
    event.preventDefault();
    document.getElementById(`onboardee-block-header-${onboardee_id}`).dataset.selected = "false";
    document.getElementById(`onboardee-block-header-${onboardee_id}`).dataset.path = url;
    // Reset onboardee_block's data
    let action_list_data = document.getElementById(`actions-list-${onboardee_id}`)
    action_list_data.dataset.iteration = action_list_data.dataset.startIteration;
    action_list_data.dataset.cursor = action_list_data.dataset.startCursor;
    stopBottomLoading();
    var displayAll = new Promise(function(resolve, reject) {
      $(`#onboardee-block-header-${onboardee_id} .header-top .fas`).toggleClass('d-none')
      $(window).off("scroll");
      $('#close-onboardee-block').remove();
      $(`#actions-list-${onboardee_id}`).html('')
      $('#header').slideDown();
      $(`#onboardee-block-header-${onboardee_id} .header-bottom`).toggleClass('d-none');
      $(`#onboardee-block-header-${onboardee_id} .header-bottom`).toggleClass('d-flex');
      $('.onboardee-block-header').not(`#onboardee-block-header-${onboardee_id}`).toggleClass('d-none');
      $('#back_navbar').remove();
      $(`#next-actions-buttons-${onboardee_id}`).remove();
      resolve();
    });

    displayAll.then(function(value) {
      window.scrollTo(0, $(`#actions-block-${onboardee_id}`)[0].offsetTop - 75);
    });
  })
}
export { initCloseOnboardeeBlock }

function updateCount(onboardee_id, status, delta) {
  if (document.querySelector(`#actions-cards #${status}_count`)) {
    var count = parseInt(document.querySelector(`#actions-cards #${status}_count`).innerText, 10);
    document.querySelector(`#actions-cards #${status}_count`).innerText = count + delta;
  }
  if (document.querySelector(`#actions-block-${onboardee_id} .${status}_count`)) {
    var second_count = parseInt(document.querySelector(`#actions-block-${onboardee_id} .${status}_count`).innerText, 10);
    document.querySelector(`#actions-block-${onboardee_id} .${status}_count`).innerText = second_count + delta;
  }
}
export { updateCount }

function initNextActionsButton(action_path, onboardee_id) {
  $('#next-actions-buttons-' + onboardee_id).off('click') // To reset the button if already init
  $('#next-actions-buttons-' + onboardee_id).on('click', function(event){
    $('#next-actions-buttons-' + onboardee_id).off('click') // Kill the event trigger
    stopBottomLoading();
    addNextActions(event, action_path, onboardee_id);
  })
}
export { initNextActionsButton }

// called when a manager clics on the handover action to download it -> it removes the action and update the counter
function handoverDownloaded(el) {
  const employeeId = el.dataset.employeeId

  el.classList.add('bg-green');
  $(el).fadeOut(800, function() {
    $(this).remove();
  });
  updateActionCountFor(employeeId, -1);
  updateManagerDashboardCount();

  // Remove employee block if no more action, after it faded out
  setTimeout(() => {
    if (document.querySelectorAll(`#actions-list-${employeeId} .line-action`).length == 0) {
      document.getElementById(`employee-block-${employeeId}`).remove();
    }
  }, 900);
}
export { handoverDownloaded }
