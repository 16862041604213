import { csrfToken } from "utils/csrf_token";
import { loadTabulator } from 'components/tabulator';

export function adaptFiltersForTabulator(column_filters) {
  return [
    { field: 'mobility', type: 'in', value: column_filters.mobility },
    { field: 'date', type: 'in', value: column_filters.date },
    { field: 'status', type: 'in', value: column_filters.status },
    { field: 'organization_ids', type: 'in', value: column_filters.organization_ids }
  ]
}

export function resetColumnFilters() {
  const filters = ["status", "date"]
  if (hasSeveralMobilities()) { filters.unshift('mobility') }
  if (hasSeveralOrga()) { filters.unshift('organization_ids') }
  filters.forEach(column_filter => {
    const elements = JSON.parse(document.getElementById('filters').dataset.filters)['column_filters'][column_filter];
    if (column_filter == 'date'){
      [...document.querySelectorAll('[name="dateChoice"]')].forEach(element => element.checked = false)
    } else {
      elements.forEach(element => document.getElementById(element + '-check').classList.remove('d-none'));
    }
  });
  [...document.querySelectorAll('.select-opened')].forEach(element => {
    element.classList.toggle('select-opened');
    element.classList.toggle('select-closed');
  });
  [...document.querySelectorAll('.select.active')].forEach(element => {
    element.classList.remove('active');
    element.querySelector('.fal').classList.remove('fa-times');
    element.querySelector('.fal').classList.add('fa-chevron-down');
  });
  [...document.querySelectorAll('.menu-opened')].forEach(element => {
    element.classList.toggle('menu-closed');
    element.classList.toggle('menu-opened');
  });
}

export function cleanFiltersColors() {
  [...document.querySelectorAll('[data-desktop--hr--filters--smart-filters-target]')].forEach(filter => filter.classList.remove('active'));
}

export function displayCategoryFilters(target) {
  document.querySelectorAll('.subfilter-category').forEach(category => hide(category));
  display(document.getElementById(target));
}

export function hideCategoryFilters() {
  // hide the whole div contains the subfiters
  document.querySelectorAll('.subfilter-category').forEach(category => hide(category));
  // remove the active class of the subfiters
  [...document.querySelectorAll('.subfilter')].forEach(subfilter => subfilter.classList.remove('active'));
}

export function hide(element) {
  element.classList.remove('d-flex');
  element.classList.add('d-none');
}

function display(element) {
  element.classList.remove('d-none');
  element.classList.add('d-flex');
}

export function displayPlaceholder() {
  document.getElementById('tracks').outerHTML = `<div id="tracks"></div>`;
  document.getElementById('tracks').innerHTML = document.getElementById('placeholder').innerHTML;
}

function hasSeveralMobilities() {
  return document.querySelector('[data-controller="desktop--hr--filters--mobility-filter"]')
}

function hasSeveralOrga() {
  return document.querySelector('[data-controller="desktop--hr--filters--organization-filter"]')
}

// this function updates the result number at the bottom right
export function setPagesNumber() {
  if (document.querySelector('.tabulator-page.active')) {
    document.getElementById('tracks-pagination-counter').classList.add('d-flex');
    document.getElementById('tracks-pagination-counter').classList.remove('d-none');
    if (document.querySelector('.tabulator-row')) {
      const activePage = +document.querySelector('.tabulator-page.active').dataset.page;
      const lineCountFirst = ((activePage - 1) * 20) + 1; // 20 lines per page
      const rowCount = document.querySelectorAll('.tabulator-row').length;
      const lineCountLast = lineCountFirst + rowCount - 1;
      document.getElementById('current-first').innerText = lineCountFirst;
      document.getElementById('current-second').innerText = lineCountLast;
    } else {
      document.getElementById('tracks-pagination-counter').classList.remove('d-flex');
      document.getElementById('tracks-pagination-counter').classList.add('d-none');
    }
  }
}

// the 6 functions below are only usable in the stimulus column controller
export function toggleColumnFilter(select, menu) {
  toggleSelect(select);
  toggleMenu(menu);
  toggleIcon(select);
}

function toggleSelect(element) {
  element.classList.toggle('select-opened');
  element.classList.toggle('select-closed');
}

function toggleMenu(element) {
  element.classList.toggle('menu-closed');
  element.classList.toggle('menu-opened');
}

function toggleIcon(element) {
  const icon = element.querySelector('.fa-chevron-up');
  if (icon) {
    icon.classList.remove('fa-chevron-up');
    icon.classList.add('fa-chevron-down');
  } else {
    const otherIcon = element.querySelector('.fa-chevron-down');
    if (otherIcon) {
      otherIcon.classList.remove('fa-chevron-down');
      otherIcon.classList.add('fa-chevron-up');
    }
  }
}

export function getFilters() {
  return JSON.parse(document.getElementById('filters')?.dataset.filters || '{}');
}

export function activeElements() {
  const filters = getFilters();
  const smartFilterElements = [...document.querySelectorAll('[data-desktop--hr--filters--smart-filters-target].smart-filter')];
  const columnFilterElements = [...document.querySelectorAll('#selects .select')];
  const isOneSmartFilterActive = smartFilterElements.some(el => el.classList.contains('active'))
  const isOneColumnFilterActive = columnFilterElements.some(el => el.classList.contains('active'));

  return {
    scope: document.querySelector('.switch-label') && filters.implication_scope,
    search: filters.query.trim() !== '',
    smartFilters: isOneSmartFilterActive,
    columnFilters: isOneColumnFilterActive
  }
}

export function updateTracksCount() {
  const tabulator = Tabulator.findTable('#tracks')[0];
  const count = tabulator.getData("active").length;
  const countElement = document.getElementById('tracks-count');
  const labelElement = document.getElementById('tracks-label');
  const pageCountElement = document.getElementById('tracks-page-count');
  const pageLabelElement = document.getElementById('tracks-page-label');

  let label = labelElement.dataset.plural
  if(count <= 1){
    label = labelElement.dataset.singular
  }
  countElement.innerText = count;
  labelElement.innerText = label;
  pageCountElement.innerText = count;
  pageLabelElement.innerText = label;
}

// delay is an Integer in millisecond
// cf the delayer_controler.js
export function delayRequestFor(attribute, delay_size = 'medium') {
  switch (delay_size) {
    case 'big':
      document.getElementById('collaborators-container').dataset[attribute] = 1000
      break;
    case 'medium':
      document.getElementById('collaborators-container').dataset[attribute] = 500
      break;
    case 'small':
      document.getElementById('collaborators-container').dataset[attribute] = 200
      break;
  }
}

export function filterRequest() {
  const url = document.getElementById('collaborators-container').dataset.url

  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }, // send as JSON
    body: JSON.stringify({
      ...getFilters(),
      locale: document.getElementById('locale').dataset.locale
    })
  })
  .then(response => response.json())
  .then(responseJSON => {
    const tracks = responseJSON.data;
    const filters = getFilters();
    const scrollY = window.scrollY;

    // Reset div before new Tabulator instantiation
    document.getElementById('tracks').outerHTML = `<div id="tracks"></div>`;
    loadTabulator(tracks, filters);
    window.scrollTo({top: scrollY});
    cleanPagination();
    createPagesEventCounter();
  });
}

function createPagesEventCounter() {
  setTimeout(() => {
    setPagesNumber();
    [...document.querySelectorAll('.tabulator-page')].forEach(page => {
      page.addEventListener('click', e => {
        setPagesNumber()
        createPagesEventCounter(); // give event to new buttons
      });
    });
  }, 200);
}

export function cleanPagination() {
  document.getElementById('tracks-pagination').innerHTML = '';
}

// This method is necessary because when a column filter is empty
// after re-adding one value to the filter, the table doesn't display the rows
// so we refresh the table this way
export function refreshTabulator() {
  const tabulator = Tabulator.findTable('#tracks')[0];
  tabulator.setData(tabulator.getData());
}
