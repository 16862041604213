import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['select']

  connect() {
    $(this.selectTarget).selectize({
      render: {
        option: (item, _escape) => {
          return `<div style="padding: 6px 15px;">${item.text}</div>`;
        }
      }
    });
  }
}

