import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['projects', 'routines', 'contacts']

  // In the edit & show, the action of the offboardee -> when he switches the editors
  toggleEditor(e) {
    // the class and id below also work for the show page (which has no editor)
    const editors = document.querySelectorAll('.handover-editor');
    const editorToShow = document.getElementById(`handover-${e.target.dataset.handoversTarget}`);

    this.updateMenuBgs(e.target);

    [...editors].forEach(editor => editor == editorToShow ? display(editor) : hide(editor));

    if (editView()) {
      e.target == this.contactsTarget ? displayFinalizeButton() : displayNextButton();
    }

    updateHandover();
  }

  nextSection() {
    if (active(document.getElementById('handover-projects'))) {
      showRoutines();
      this.updateMenuBgs(this.routinesTarget);
    } else if (active(document.getElementById('handover-routines'))) {
      showContacts();
      displayFinalizeButton();
      this.updateMenuBgs(this.contactsTarget);
    }
    updateHandover();
  }

  updateMenuBgs(target) {
    this.removeBoldToLinks();
    addBoldToCurrentLink(target);
  }

  removeBoldToLinks() {
    [this.projectsTarget, this.routinesTarget, this.contactsTarget].forEach(el => { el.classList.remove('text-bold')});
  }

  // This function is used to include a handover at 2 places :
  // In the template edition of the settings -> to include or not a handover in a template
  // In the track edition -> to include or not a handover in a track
  switchHandoverPresence(e) {
    const trackId = e.target.dataset.trackId;
    const handoverId = e.target.dataset.handoverId;
    const origin = e.target.dataset.origin;
    const checked = e.target.checked;
    const request = {
      url: checked ? definePostUrl(origin, trackId) : defineDeleteUrl(origin, handoverId),
      method : checked ? 'POST' : 'DELETE'
    }

    $.ajax({
      url: request.url,
      type: request.method,
      dataType: 'json',
      success: (response) => {
        if (request.method == 'POST') {
          document.getElementById('handover').dataset.handoverId = response.data.id;
        };
      }
    });
  }
}

function display(editor) {
  if (editor.classList.contains('d-none')) {
    editor.classList.remove('d-none');
  }
}

function hide(editor) {
  editor.classList.add('d-none');
}

function addBoldToCurrentLink(link) {
  link.classList.add('text-bold');
}

function active(editor) {
  return !editor.classList.contains('d-none')
}

function editView() {
  return document.getElementById('finalize-handover') != null
}

function showRoutines() {
  hideEditors();
  display(document.getElementById('handover-routines'))
}

function showContacts() {
  hideEditors();
  display(document.getElementById('handover-contacts'))
}
function displayFinalizeButton() {
  hide(document.getElementById('next-section'))
  display(document.getElementById('finalize-handover'))
}

function displayNextButton() {
  display(document.getElementById('next-section'))
  hide(document.getElementById('finalize-handover'))
}

function hideEditors() {
  document.querySelectorAll('.handover-editor').forEach(editor => hide(editor));
}

function definePostUrl(origin, trackId) {
  return origin == 'track' ? `/offboarding/tracks/${trackId}/handovers` : `/settings/tracks/${trackId}/handovers`
}

function defineDeleteUrl(origin, handoverId) {
  return origin == 'track' ? `/offboarding/handovers/${handoverId}` : `/settings/handovers/${handoverId}`
}

function updateHandover() {
  const url = document.getElementById('handover-editors').dataset.url;

  if (url) {
    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'script',
      data: {
        handover: {
          projects: document.querySelector('trix-editor#handover_projects').value,
          routines: document.querySelector('trix-editor#handover_routines').value,
          contacts: document.querySelector('trix-editor#handover_contacts').value
        }
      }
    });
  }
}
