// This controller is called when we click on the 'actions' and 'collaborators' buttons
// it takes care to load the main part of the page (actions or collaborator blocks)
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['label', 'input'];

  load(_event) {
    const input = this.inputTarget;
    const placeholder = document.getElementById(`${input.dataset.type}-placeholder`).innerHTML;
    const url = input.checked ? input.dataset.uncheckedUrl : input.dataset.checkedUrl;
    this.fill(placeholder);
    this.updateNavigatorButtons();
    this.updateGlobalCounter();
    this.updateNewTodoButton();
    this.call(url);
    this.updateUrl(url);
    this.resetBlankState();
  }

  call(url) {
    fetch(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.fill(data.content);
    }).catch(error => {
      console.log(error);
    })
  }

  // the main element is and has to remain on both, actions & collaborators pages
  fill(content) {
    document.getElementById('main').innerHTML = content;
  }

  // we have to remove the id of the actions as it is a show and we want to simulate the index
  updateUrl(newUrl) {
    window.history.pushState({}, '', newUrl.replace(/\/\d+/, ''));
  }

  updateNavigatorButtons() {
    let [newListType, oldListType] = this.inputTarget.checked ? ['done', 'ongoing'] : ['ongoing', 'done']
    let selector = "[data-desktop--actor--navigation-buttons-component--navigation-buttons-component-target=button]"
    document.querySelectorAll(selector).forEach(button => {
      button.dataset.url = button.dataset.url.replace(oldListType, newListType);
    })
  }

  updateGlobalCounter() {
    let newListType = this.inputTarget.checked ? 'done' : 'ongoing';
    let counterWrapper = document.querySelector("div[counter-wrapper]")
    counterWrapper.dataset.listType = newListType;
    [...counterWrapper.children].forEach((el) => { el.dataset['utils-CountersCountValue'] = 0 })
  }

  updateNewTodoButton() {
    let newTodoButton = document.getElementById('add-action-button');
    let newListType = this.inputTarget.checked ? 'done' : 'ongoing';
    if (newTodoButton && (newListType == 'done')) {
      newTodoButton.classList.add('invisible');
    } else if (newTodoButton) {
      newTodoButton.classList.remove('invisible');
    }
  }

  resetBlankState () {
    const element = document.getElementById('main')
    // Retrieving the controller you wish to restart
    const controller = this.application.getControllerForElementAndIdentifier(element, 'utils--blank-states');
  
    // Check that the controller exists
    if (controller) {
      // reset the controller
      controller.disconnect();
      controller.connect();
    }
  }
}
