import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { conditionRelation: String }
  static targets = ['condition', 'action']

  connect() {
    this.initConditionObservers();
    setTimeout(this.check(), 50);
  }

  initConditionObservers() {
    let observer = new MutationObserver(this.updateMet.bind(this));
    this.conditionTargets.forEach((condition) => {
      observer.observe(condition, { attributes: true });
    })
  }

  updateMet(mutationsList) {
    for(let mutation of mutationsList) {
      let attributeName = mutation.attributeName;
      if (attributeName == "data-met") {
        this.check();
      }
    }
  }

  // HERE, we have the relation between conditions !!
  check() {
    if (this.conditionRelationValue == 'or_relation') {
      this.element.dataset.met = this.conditionTargets.some(condition => condition.dataset.met == 'true');
    } else {
      this.element.dataset.met = this.conditionTargets.every(condition => condition.dataset.met == 'true');
    }
  }
}

