// This controller is used to preview an element in the modal, when editing a template, in the settings
// To use it the trigger element must contains the following attributes:
// data-controller="desktop--settings--templates--modal"
// data-action="click->desktop--settings--templates--modal#open"
// data-url -> the url towards the show of the desired resource

// The show action has to render a json with the html to be displayed in the modal
// as the promise will replace the new content

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open() {
    allowCloseModalWithBackButton('modal-view');
    $('#modal-view').modal('show');
    this._addSpinner();
    let locale = document.querySelector('[data-language]').dataset.language
    fetch(`${this.element.dataset.url}?locale=${locale}&class_name=${this.element.dataset.className}`)
    .then(response => response.json())
    .then(data => {
      const preview_modal = document.getElementById('modal-view-content');
      if (data.small_content) {
        preview_modal.classList.add('modal-small-content')
      } else {
        preview_modal.classList.remove('modal-small-content')
      };
      preview_modal.innerHTML = data.content;

      const scormIframe = preview_modal.querySelector('iframe[title="scorm"]');
      if (scormIframe) { connectScorm(preview_modal); };
    })
  }

  cleanModal() {
    cleanPreviewModal();
  }

  _addSpinner() {
    const modalContent = document.getElementById('modal-view-content');
    modalContent.innerHTML = '<div class="text-center h-100 d-flex align-items-center justify-content-center"><i class="fad fa-spinner text-gray text-40px rotating"></i><div>';
  }
}
export function cleanPreviewModal() {
  const preview_modal = document.getElementById('modal-view-content');
  preview_modal.innerHTML = '';
  if (preview_modal.classList.contains('modal-small-content')) { preview_modal.classList.remove('modal-small-content') };
}

export function connectScorm(modal) {
  const iframe = modal.querySelector('iframe[title="scorm"]');
  const modalContent = modal.querySelector('.modal-content-segment');
  iframe.classList.add('d-none');

  const placeholderDiv = document.createElement('div');
  placeholderDiv.classList.add('rotating', 'fad', 'fa-spinner', 'text-80px');
  iframe.parentNode.insertBefore(placeholderDiv, iframe);

  setTimeout(() => {
    placeholderDiv.remove();
    iframe.classList.remove('d-none');
  }, 2000);
  iframe.addEventListener('load', () => {
    let iframeWindow = iframe.contentWindow;
    iframeWindow.postMessage(
      {
        'username': iframe.dataset.username,
        'password': iframe.dataset.password
      },
      iframe.dataset.baseUrl
    );
  });
}
