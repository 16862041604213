// This function gives the radio button behavior to div elements.
// To initialize it you need :
//  - an input wich recover the selected value
//  - a list of option values, in div tag
// Each div needs :
//  - data-list : selector for the list of option (example : #company-list)
//  - data-selected-classes : the classes list to add if element is selected (exemple: button-blue, text-white)
//  - data-unselected-classes : the classes list to add on other unselected elements (exemple: button-blue, text-white)
//  - data-target : selector for the input (example : #feedback_company_rating)
function selectOption(element) {
  let inputTarget = document.querySelector(element.dataset.target);
  let optionList = document.querySelector(element.dataset.list);

  inputTarget.value = element.dataset.value;
  optionList.querySelectorAll('div[data-list]').forEach(function(el) { removeSelectedClasses(el) });
  addSelectedClasses(element);
}

function addSelectedClasses(element) {
  element.dataset.selectedClasses.split(',').forEach(function(selectedClass) {
    element.classList.add(selectedClass.trim())
  });
  element.dataset.unselectedClasses.split(',').forEach(function(unselectedClass) {
    element.classList.remove(unselectedClass.trim())
  });
}

function removeSelectedClasses(element) {
  element.dataset.selectedClasses.split(',').forEach(function(selectedClass) {
    element.classList.remove(selectedClass.trim())
  });
  element.dataset.unselectedClasses.split(',').forEach(function(unselectedClass) {
    element.classList.add(unselectedClass.trim())
  });
}

export { selectOption };
