function changeLocale(locales, value) {
	var path = location.pathname
	var locale = path.substring(0, 3).slice(1);
  if (locales.includes(locale)) {
		path = path.slice(3);
	}
	location.pathname = value + path;
}

export { changeLocale };
