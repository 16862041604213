import { Controller } from "@hotwired/stimulus"

// Example :
//   <div data-controller='utils--tabs'>
//     <div data-utils--tabs-target="tabWrapper" class="tabs">
//       <div data-utils--tabs-target="tab" data-action="click->utils--tabs#select" data-tab="A" class="tab" active>A</div>
//       <div data-utils--tabs-target="tab" data-action="click->utils--tabs#select" data-tab="B" class="tab">B</div>
//       <div data-utils--tabs-target="tab" data-action="click->utils--tabs#select" data-tab="C" class="tab">C</div>
//       <div data-utils--tabs-target="afterLine"></div>
//     </div>
//
//     <div class="d-flex flex-row">
//       <div data-utils--tabs-target="content" data-tab="A" class="tab">A</div>
//       <div data-utils--tabs-target="content" data-tab="B" class="tab hidden">B</div>
//       <div data-utils--tabs-target="content" data-tab="C" class="tab hidden">C</div>
//     </div>
//   </div>
export default class extends Controller {
  static targets = ['tabWrapper', 'tab', 'content', 'afterLine']

  connect() {
    this._moveAfterLine();
  }

  select(event) {
    const newTab = event.currentTarget;
    this._changeSelectedAttributes(newTab);
    this._displayCurrentContent(newTab)
    this._moveAfterLine();
    this._updateHistoryUrl();
  }

  _changeSelectedAttributes(newTab) {
    this.tabTargets.forEach((tab) => { tab.removeAttribute('active')});
    newTab.setAttribute('active', '');
  }

  _displayCurrentContent(newTab) {
    this.contentTargets.forEach((content) => { content.classList.add('hidden') })
    this.contentTargets.find((content) => content.dataset.tab == newTab.dataset.tab)
                       ?.classList?.remove('hidden')
  }

  _activeTab() {
    return this.tabTargets.filter((tab) => { return tab.hasAttribute('active') })[0];
  }

  _moveAfterLine() {
    if (this.hasAfterLineTarget) {
      const tabRect = this._activeTab().getBoundingClientRect();
      const wrapperRect = this.tabWrapperTarget.getBoundingClientRect();
      const left = tabRect.left - wrapperRect.left;
      const width = tabRect.width;
      this.afterLineTarget.style.transform = `translateX(${left}px)`;
      this.afterLineTarget.style.width = `${width}px`;
    }
  }

  _updateHistoryUrl() {
    const url = new URL(window.location.href);
    const currentTab = this._activeTab().dataset.tab
    url.searchParams.set('tab', currentTab);
    history.replaceState(null, null, url.toString());
  }
}
