import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = [
    'select',
    'modalWithTracks',
    'modalWithoutTracks',
    'value',
    'tracks',
    'loading',
    'difference'
  ]

  static values = { url: String, new: String, previous: String }

  connect() {
    this.newValue = this.selectTarget.value
    this.previousValue = this.selectTarget.value

    $(this.modalWithTracksTarget).on('hide.bs.modal', () => {
      this.selectTarget.value = this.previousValue
    })

    $(this.modalWithoutTracksTarget).on('hide.bs.modal', () => {
      this.selectTarget.value = this.previousValue
    })
  }

  async change() {
    this.newValue = this.selectTarget.value
    this.valueTargets.forEach((target) => target.innerText = this.newValue)
    const difference = parseInt(this.newValue, 10) - parseInt(this.previousValue, 10)
    this.differenceTarget.innerText = difference

    if (difference > 0) {
      $(this.modalWithoutTracksTarget).modal('show')
    } else {
      this.loadingTarget.hidden = false
      this.tracksTarget.innerText = await this._fetchTracks()
      this.loadingTarget.hidden = true
      $(this.modalWithTracksTarget).modal('show')
    }
  }

  confirm(event) {
    event.preventDefault()

    this.previousValue = this.newValue

    $(this.modalWithTracksTarget).modal('hide')
    $(this.modalWithoutTracksTarget).modal('hide')

    this.element.requestSubmit()
  }

  cancel(event) {
    event.preventDefault()

    $(this.modalWithTracksTarget).modal('hide')
    $(this.modalWithoutTracksTarget).modal('hide')
  }

  async _fetchTracks() {
    try {
      const response = await fetch(
        this.urlValue,
        {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            previous_purging_after_months: this.previousValue,
            new_purging_after_months: this.newValue
          })
        }
      )
      const json = await response.json()
      return json.tracks_count
    } catch {
      return "?"
    }
  }
}
