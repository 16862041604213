import { Controller } from "@hotwired/stimulus"
// This controller initialise popovers
// it's a jQuery function so you have to query an element with $
// Just place this : 
// data-controller='utils--popovers'>


export default class extends Controller {
  connect() {
    const hasTextOverflowClass = this.element.classList.contains('text-overflow');
    const isContentOverflowing = this.element.scrollWidth > this.element.clientWidth;
    if (!hasTextOverflowClass || isContentOverflowing) {
      $(this.element).popover({
        container: 'body',
        delay: 300,
        animation: false
      });
    }
  }
}
