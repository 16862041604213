import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import { formId,
         getStorage,
         setStorage,
         getStoredSectionFrom,
         getStoredPaperworkFrom,
         buildPaperworkHtml,
         buildConditionalLogicHtml,
         buildPaperworkModal,
         newId,
         highlightConditionalLogic } from 'packs/controllers/form_resources_controller';

export default class extends Controller {
  connect() {
    useClickOutside(this);
    // Update the field options of the conditional_logic
    addFieldOptions(this.element.dataset.newData, this.element.id, this.element.innerText);
  }

  disconnect() {
    if (isNotDragged(this.element)) {
      // Update the field options of the conditional_logic
      const fieldId = this.element.id;
      removeAsFieldLogicConditionAndAction(fieldId);
      removeFieldOptions(fieldId);
    }
  }

  add(event) {
    event.preventDefault();
    const paperworkResource = event.currentTarget;
    const dataset = paperworkResource.dataset
    const section = this.element.closest('.section');
    const paperworkAttributes = {
      id: newId(),
      paperwork_resource_id: dataset.paperworkResourceId,
      title: dataset.name,
      new_data: dataset.newData,
      icon: paperworkResource.querySelector('span.icon').innerHTML,
      position: (parseInt(this.element.dataset.position) || 0) + 1,
      mandatory: false,
      hidden: false,
      unit_organization_ids: dataset.unitOrganizationIds.trim().split(' '),
    }
    let paperworkHtml = buildPaperworkHtml(paperworkAttributes);
    markPaperworkAsAlreadyAdded(event);

    if (this.element.dataset.type == 'empty') {
      // Close modal
      $('#paperworkModal').modal('hide');
      // Add paperwork to end of list
      this.element.parentNode.querySelector("[data-sortable-type='paperwork']").insertAdjacentHTML('beforeend', paperworkHtml);
    } else {
      // Add paperwork below the current
      this.element.insertAdjacentHTML('afterend', paperworkHtml);
    }

    // Positioning
    let secondElementSibling;
    const nextElementSibling = this.element.nextElementSibling
    // Securing : if you add the first paperwork, nextElementSibling is null
    if (nextElementSibling) { secondElementSibling = nextElementSibling.nextElementSibling };
    if (secondElementSibling) { this.propagatePosition(secondElementSibling, 1) };

    // Storing
    let storage = getStorage(formId(this.element));
    let storedSection = getStoredSectionFrom(storage, section.id);
    storedSection.paperworks.push(paperworkAttributes);
    setStorage(formId(this.element), storage);
  }

  addCondition(event) {
    const id = newId();
    const paperworkResource = event.target.closest('[data-type=paperwork]');
    let conditionalLogicHtml = buildConditionalLogicHtml({
      id: id,
      condition_relation: 'and_relation',
      logic_conditions: [{ id: (newId()), field_id: paperworkResource.id, operator_type: 'is', value: '', collection: '[]' }],
      logic_actions: [{ id: (newId()), field_id: '', operator_type: 'show' }] }
    )

    document.getElementById('conditional-logic-list').insertAdjacentHTML('beforeend', conditionalLogicHtml);
    highlightConditionalLogic(id);
  }

  delete() {
    const id = this.element.id;
    const fId = formId(this.element);
    const section = this.element.closest('.section');

    this.propagatePosition(this.element.nextElementSibling, -1);
    this.element.remove();

    let storage = getStorage(fId);
    let storedSection = getStoredSectionFrom(storage, section.id);
    storedSection.paperworks.forEach((storedPaperwork, index) => {
      if (storedPaperwork.id == id) { storedSection.paperworks.splice(index, 1); }
    });
    setStorage(fId, storage);
  }

  propagatePosition(element, delta = 1) {
    let storage = getStorage(formId(this.element));
    const section = this.element.closest('.section');
    const storedSection = getStoredSectionFrom(storage, section.id);

    while (element) {
      let id = element.id;
      let position = element.dataset.position;
      let newPosition = parseInt(position) + delta;
      let storedPaperwork = getStoredPaperworkFrom(storedSection, id);

      element.dataset.position = newPosition;
      storedPaperwork.position = newPosition;

      element = element.nextElementSibling;
      position++;
    }
    setStorage(formId(this.element), storage);
  }

  toggleAttribute(event) {
    let input = event.target;
    let checked = input.checked;
    let attributeName = input.dataset.attributeTarget;
    this.updateAttribute(attributeName, checked);
  }

  toggleMandatory() {
    let checked = !(this.element.dataset['mandatory'] == 'true')
    this.element.querySelector('input[data-attribute-target=mandatory]').checked = checked;
    this.updateAttribute('mandatory', checked);
  }

  updateAttribute(attributeName, value) {
    this.element.dataset[attributeName] = value;

    let paperworkId = this.element.closest('[data-type="paperwork"]').id;
    let sectionId = this.element.closest('[data-type="section"]').id;
    let storage = getStorage(formId(this.element));
    const storedSection = getStoredSectionFrom(storage, sectionId);
    let storedPaperwork = getStoredPaperworkFrom(storedSection, paperworkId);
    storedPaperwork[attributeName] = value;
    setStorage(formId(this.element), storage);
  }

  openModal() {
    let modal = buildPaperworkModal(formId(this.element));
    this.element.insertAdjacentHTML('beforeend', modal);
    $('#paperworkModal').modal('show');
    $('#paperworkModal').on('hidden.bs.modal', () => {
      $('#paperworkModal').remove();
    })
  }

  stickDropdown() {
    this.element.setAttribute('dropdown-focus', '');
  }

  clickOutside() {
    this.hideDropdown();
    this.element.removeAttribute('dropdown-focus');
  }

  hideDropdown() {
    $(this.element.querySelector('[data-toggle=dropdown]')).dropdown('hide');
  }

  highlightConditionField() {
    let sidebar = document.getElementById('conditional-logic-sidebar');
    sidebar.dataset.highlightType = 'conditionField';
    sidebar.dataset.highlightId = this.element.id;
  }
}

const addFieldOptions = (newData, fieldId, name) => {
  // Add as field option of logic_action
  document.querySelectorAll('[data-logic-actions-target="field"]').forEach(el => {
    el.selectize.addOption([{
      "new_data": newData,
      "value": fieldId,
      "name": name
    }])
  });
  // Add as field option of logic_conditions ONLY if is text or selection
  if (['text', 'selection'].includes(newData)) {
    document.querySelectorAll('[data-logic-conditions-target="field"]').forEach(el => {
      el.selectize.addOption([{
        "new_data": newData,
        "value": fieldId,
        "name": name
      }])
    });
  }
}

const removeFieldOptions = (fieldId) => {
  document.querySelectorAll('[data-logic-conditions-target="field"], [data-logic-actions-target="field"]').forEach(el => {
    el.selectize.removeOption(fieldId)
  });
}

const removeAsFieldLogicConditionAndAction = (fieldId) => {
  document.querySelectorAll('[data-logic-conditions-target="field"], [data-logic-actions-target="field"]').forEach(el => {
    if (el.selectize.getValue() == fieldId) { el.closest('[logic-condition], [logic-action]').remove() }
  })
}

const markPaperworkAsAlreadyAdded = (event) => {
  event.target.closest('label').setAttribute('presence', 'true');
}


const isNotDragged = (element) => {
  let section = element.closest('.section');
  return !element.classList.contains('sortable-chosen') && !section?.classList?.contains('sortable-chosen');
}
