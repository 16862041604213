import { Controller } from "@hotwired/stimulus";
import { ajax } from "jquery";

export default class extends Controller {
  static values = { url: String, translations: Object, account: Number }
  static targets = [ "confirmButton"]
  recipientInput = document.getElementById('actions_recipient');

  openModal() {    let actionsCount = document.querySelectorAll('.bulk-select.checked').length;
    let actionsRecipientValue = this.recipientInput.selectize.getValue();
    if (actionsRecipientValue) {
      let employeeName = this.recipientInput.selectize.getItem(actionsRecipientValue)[0]?.innerText;
      document.getElementById('modal-sidebar-content').innerHTML = modalContent(actionsCount, employeeName);
      $('#modal-sidebar').modal('show')
    } else {
      flash(this.translationsValue['missing_employee_message'], 'alert')
    }
  }

  confirm() {
    let checkedBulks = document.querySelectorAll('.bulk-select.checked input');
    let employeeId = this.recipientInput.selectize.getValue();
    let actions = [...checkedBulks].map(el => convertToAction(el));

    const controllerToReloadData = this.application.getControllerForElementAndIdentifier(document.getElementById('actions-wrapper'), 'desktop--actor--action--reallocations--data')
    const followupController = this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller*='tabulator-bulk-selection']"), 'tabulator-bulk-selection')

    this.confirmButtonTarget.setAttribute('disabled', 'disabled');
    $(this.confirmButtonTarget).html('<div class="spinner"><i class="fad fa-spinner text-white rotating" aria-hidden="true"></i></div>')

    if (employeeId) {
      App.cable.subscriptions.create(
        { channel: 'ReallocateActionsChannel', account_id: this.accountValue, target_actor_id: employeeId },
        {
          connected: () => {
          },
          received: response => {
            $('#modal-sidebar').modal('hide');
            flash(this.translationsValue['success'], 'notice');
            if(response['errors'].length > 0) {
              flash(response['errors'][0].message, 'alert');
            } else {
              flash(this.translationsValue['success'], 'notice');
            }
            const tabulator = Tabulator.findTable('#actions')[0];

            if (controllerToReloadData) {
              // reset the controller to reload data
              controllerToReloadData.disconnect();
              controllerToReloadData.connect();
            }
            if (followupController) {
              followupController.uncheckAll();
            }
          }
        }
      )
      $.ajax({
        url: this.urlValue,
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify({
          employee_id: employeeId,
          actions: actions
        }),
        error: (error) => {
          console.log('ERROR', error);
        },
        success: (response) => {
          console.log(response);
        }
      })
    } else {
      flash(this.translationsValue['missing_employee_message'], 'alert')
    }
  }
}

const modalContent = (actionsCount, employeeName) => {
  let html = document.getElementById('reallocation-modal').innerHTML;
  html = html.replaceAll("{{actionsCount}}", actionsCount)
  html = html.replaceAll("{{employeeName}}", employeeName)
  return html
}

const convertToAction = (checkedBulk) => {
  let actionType = checkedBulk.dataset.resourceType.replace('_resource', '');
  let klass = actionType.charAt(0).toUpperCase() + actionType.slice(1);

  return { id: checkedBulk.value, type: klass }
}
