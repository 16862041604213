import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, id: Number };

  connect() {
    let url = `${this.urlValue}/${this.idValue}/edit`;
    window.history.pushState({}, '', url);
  }
}
