import { Controller } from "@hotwired/stimulus"
import { drawLine } from '../../onboardee/linked_questions_controller'

const selectAnswerAction = 'click->desktop--settings--templates--quiz-linked-question#selectAnswer';
const unselectAnswerAction = 'click->desktop--settings--templates--quiz-linked-question#unselectAnswers';

export default class extends Controller {
  static targets = ['selectText', 'associateText']
  static values = { selected: String }

  selectAnswer(event) {
    this.selectedValue += this.selectedValue.length >= 1 ? ` ${event.currentTarget.id}` : `${event.currentTarget.id}`;
    addSelectedClass(document.getElementById(event.currentTarget.id));
    const answers = this.selectedValue.split(' ');

    // in case the second option clicked is on the same column as the first, we remove the first choice
    // we consider that the user wants to change his first choice
    if (isClickedOnSameColumn(answers)) {
      const removedAnswerId = answers.shift();
      if (answers[0] != removedAnswerId) { removeSelectedClass(document.getElementById(removedAnswerId)) };
      this.selectedValue = event.currentTarget.id;
    }

    // the first click we change texts instruction and gray other options
    // and once the user has selected the second options in the other column
    // we update the answer_twin to make the association and revert the texts instruction
    if (answers.length == 1) {
      addGrayToOthers(event.currentTarget);
      hide(this.selectTextTarget);
      show(this.associateTextTarget);
    } else {
      const answerId = answers.filter(el => !el.includes('answer_resource_twin'))[0];
      const answerTwinId = answers.filter(el => el.includes('answer_resource_twin'))[0];
      this.selectedValue = '';
      const answerElement = document.getElementById(answerId)
      const answerTwinElement = document.getElementById(answerTwinId);
      answerElement.setAttribute('data-answer-resource-twin-id', answerTwinId.split('_')[3])
      answerTwinElement.setAttribute('data-answer-resource-id', answerId.split('_')[2])
      linkAnswers(answerElement, answerTwinElement);
      hide(this.associateTextTarget);
      show(this.selectTextTarget);
      this.updateAnswerTwinState(+event.currentTarget.dataset.questionId, answerElement.id.split('_')[2], +answerTwinElement.id.split('_')[3])
    }
  }

  unselectAnswers(event) {
    const isTwin = event.currentTarget.id.includes('twin');
    const answerId = isTwin ? event.currentTarget.dataset.answerResourceId : event.currentTarget.id.split('_')[2];
    const answerTwinId = isTwin ? event.currentTarget.id.split('_')[3] : event.currentTarget.dataset.answerResourceTwinId;
    const answerElement = document.getElementById(`answer_resource_${answerId}`);
    const answerTwinElement = document.getElementById(`answer_resource_twin_${answerTwinId}`)
    unlinkAnswers(answerElement, answerTwinElement);
    this.updateAnswerTwinState(+event.currentTarget.dataset.questionId, answerId, null)
  }

  updateAnswerTwinState(questionId, answerId, answerTwinId) {
    this.state = currentState();
    this.state[questionId].answers[answerId].answer_twin_id = answerTwinId;
    updateState(JSON.stringify(this.state));
  }
}

// the 2 functions belows only concern the front
const linkAnswers = (answerElement, answerTwinElement) => {
  [answerElement, answerTwinElement].forEach(answer => {
    removeSelectedClass(answer);
    bullet(answer).classList.remove('invisible');
    addSelectedClass(bullet(answer));
    answer.setAttribute('data-action', unselectAnswerAction);
    removeGrayTexts(answer);
  });
  drawLine(answerElement, answerTwinElement);
};

const unlinkAnswers = (answerElement, answerTwinElement) => {
  [answerElement, answerTwinElement].forEach(answer => {
    removeSelectedClass(answer);
    removeSelectedClass(bullet(answer));
    bullet(answer).classList.add('invisible')
    answer.setAttribute('data-action', selectAnswerAction)
  });
  const svg = bullet(answerElement).querySelector('svg');
  if (svg) { svg.remove() };
};

const addGrayToOthers = element => {
  const answers = [...element.parentElement.parentElement.querySelectorAll('.answer-card')];
  const answerToGray = answers.filter(answer => !answer.classList.contains('selected'));
  answerToGray.forEach(el => el.classList.add('text-gray'));
  element.classList.remove('text-gray');
};

const removeGrayTexts = element => {
  const answers = [...element.parentElement.parentElement.querySelectorAll('.answer-card')];
  answers.forEach(el => el.classList.remove('text-gray'));
};

const isClickedOnSameColumn = answers => {
  if (answers.length < 2) { return false };

  const answer1 = answers[0];
  const answer2 = answers[1];
  return (answer1.includes('answer_resource_twin') && answer2.includes('answer_resource_twin')) ||
    (!answer1.includes('answer_resource_twin') && !answer2.includes('answer_resource_twin'))
};


const bullet = element => {
  return element.id.includes('answer_resource_twin') ? element.previousElementSibling : element.nextElementSibling
};

const currentState = () => {
  return JSON.parse(document.getElementById('state').dataset.state);
}

const updateState = (newState) => {
  document.getElementById('state').dataset.state = newState;
}

const hide = element => {
  element.classList.remove('d-flex')
  element.classList.add('d-none')
}

const show = element => {
  element.classList.remove('d-none')
  element.classList.add('d-flex')
}

const addSelectedClass = element => element.classList.add('selected');
const removeSelectedClass = element => element.classList.remove('selected');
