// https://sortablejs.github.io/Sortable/
// https://github.com/SortableJS/Sortable
import Sortable from 'sortablejs';
import {
	currentWeeks,
	checkIfPrevWeekExists,
	checkIfNextWeekExists,
	createNextWeek,
	createPrevWeek,
	giveIdToPlusButtons,
	defineWeekValue,
	verifyExistingWeek,
	weekPlus,
	weekMinus,
	appendNewWeek,
	defineNewDay,
	containsUndraggableElements,
	setObjectToSendFor,
	setNewPositionsFor } from './utils';
import { removeDay } from './update_dom_template';

function draggableTemplate() {
	initiateElementsDraggable();
	const dataset = document.getElementById('setting-content').dataset;
  createInvisibleAddWeekButton();
	if (!window.currentWeeks) { attachFunctions() }
}

function attachFunctions() {
	window.currentWeeks = currentWeeks;
	window.createInvisibleAddWeekButton = createInvisibleAddWeekButton;
	window.defineWeekValue = defineWeekValue;
	window.verifyExistingWeek = verifyExistingWeek;
	window.weekPlus = weekPlus;
	window.weekMinus = weekMinus;
	window.appendNewWeek = appendNewWeek;
	window.containsUndraggableElements = containsUndraggableElements;
	window.setObjectToSendFor = setObjectToSendFor;
	window.setNewPositionsFor = setNewPositionsFor;
  window.removeDay = removeDay;
  window.cleanAlgoliaSearch = cleanAlgoliaSearch;
}

export { draggableTemplate };

function initiateElementsDraggable() {
	const elements = document.querySelectorAll('.draggable-day');
	[...elements].forEach(element => {
		new Sortable(element, {
			group: 'shared',
			animation: 150,
			fallbackOnBody: true,
			swapThreshold: 0.65,
			chosenClass: "opacity-40",
			filter: '.undraggable',
			onEnd: function (event) {
				removeDay();
				updateElements(event);
				createInvisibleAddWeekButton();
			},
			onMove: function (event) {},
			onChange: function (event) {}
		})
	});
}

export function initiateResourcesDraggable() {
  // settimeout in order to let algolia load the elements
  setTimeout(() => {
    if (document.querySelector('.clone') === null) { return };

    new Sortable(document.querySelector('.clone'), {
      group: {
        name: 'shared',
        pull: 'clone',
        put: false
      },
      sort: false,
      onChoose: function (event) {
        event.item.style.listStyle = 'none';
      },
      onEnd: function (event) {
        // prevent to drop and create in the resources list
        if (event.to.classList.contains('clone')) { return };

        const templateId = document.querySelector('[data-template-id]').dataset.templateId;
        const element = event.item.querySelector('span') || event.item;
        element.classList.add('to-be-replaced');
        const day = event.to.id.split('_')[1];
        const data = {
          id: element.id,
          model: element.dataset.classname,
          day: day == 'first-connection' ? null : day
        }
        const promise = new Promise((resolve, reject) => {
					// for the handover, we give twice the template as the first is considered like a track id
					const url = element.dataset.classname === 'handover' ? `/settings/tracks/${templateId}/handovers?template_id=${templateId}` : `/settings/templates/${templateId}/sidebar_resources`;
          resolve(
						$.ajax({
							url: url,
							type: 'POST',
							data: { draggable: data,
											scope: document.getElementById('setting-content').dataset.scopeView,
											locale: document.getElementById('setting-content').dataset.language },
							dataType: 'script'
						})
          );
          reject(Error("error"));
        });
        promise.then(
          setTimeout(() => {
            if (element.dataset.classname !== 'handover') { updateInDay(event.to) }
          }, 500)
        );
				// add temporary class to know which element to clean on algolia search
				event.clone.classList.add('cloned');
      },
      onMove: function (event) {},
      onChange: function (event) {}
    });
  }, 400);
}

export function createInvisibleAddWeekButton() {
	[...document.querySelectorAll('.add-class')].forEach(el => el.remove());
	currentWeeks().forEach(id => {
		const nextWeek = checkIfNextWeekExists(id); // for week 2, check if there is week 3
		const prevweek = checkIfPrevWeekExists(id); // for week 2, check if there is week 1

		if (!nextWeek) {
			createNextWeek(id);
		}
		if (!prevweek) {
			createPrevWeek(id);
		}
	});
	giveIdToPlusButtons();
}

function updateElements(event) {
	if (differentDays(event)) {
		updateBetweenDays(event);
	} else {
		updateInDay(event.target);
	}
}

function differentDays(event) {
	return event.to != event.from;
}

function updateInDay(draggableDiv) {
	const data = [];
	// set new positions
	[...draggableDiv.children].forEach((el, index) => { el.dataset.position = index + 1 });

	// make object to send to the back
	[...draggableDiv.children].forEach(el => {
		if (el.dataset.classname) {
			const object = {
				id: el.dataset.id,
				model: el.dataset.classname,
				position: el.dataset.position,
			}
			data.push(object);
		};
	});

	$.ajax({
		url: draggableDiv.dataset.url,
		type: 'PATCH',
		data: { elements: data },
		dataType: 'script'
	});
}

function updateBetweenDays(event) {
	const newDay = defineNewDay(event.to.id.split('_')[1]);

	const elementToUpdate = {
		id: event.item.dataset.id,
		model: event.item.dataset.classname,
		new_day: newDay
	};

	const fromElements = [...event.from.children];
	const toElements = [...event.to.children];
	let oldDivData = [];
	let newDivData = [];

	if (fromElements.length > 0) {
		setNewPositionsFor(fromElements);
		oldDivData = setObjectToSendFor(fromElements)
	}

	if (toElements.length > 0) {
		setNewPositionsFor(toElements);
		newDivData = setObjectToSendFor(toElements)
	}

	$.ajax({
		url: event.target.dataset.url,
		type: 'PATCH',
		data: { element_to_update: elementToUpdate,
						old_div_order: oldDivData,
						new_div_order: newDivData,
						scope: document.getElementById('setting-content').dataset.scopeView },
		dataType: 'script'
	});
}

// when we drag and drop an element, this one appear in double, so we clean the previous once added
// called in the algolia initializers
export function cleanAlgoliaSearch() {
	const elementsToClean = [...document.querySelectorAll('.cloned')];
	elementsToClean.forEach(element => element.remove());
}
