import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['resource']

  toggleAssignment(e) {
    const id = this.resourceTarget.dataset.resourceId;
    const name = this.resourceTarget.dataset.resourceName;
    const mobility = e.target.id;
    const checked = e.target.checked;
    const request = {
      url: checked ? "/settings/shared_resources" : `/settings/shared_resources/${id}`,
      method: checked ? "POST" : "DELETE"
    }

    $.ajax({
      url: request.url,
      type: request.method,
      dataType: 'script',
      data: {
        id: id,
        name: name,
        mobility: mobility
      }
    });
  }
}
