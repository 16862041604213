import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // the connectValue prevent to try loading the content when opening the first sidebar
  // as the controller is connecting but we don't want to request yet
  static targets = ['tab', 'content']
  static values = { connect: Boolean }

  connect() {
    if (this.connectValue) { this.load() };
  }

  changeTab(event) {
    event.preventDefault();
    this.currentTab().setAttribute('active', 'false');
    event.currentTarget.setAttribute('active', 'true');
    this.load();
  }

  async load() {
    const url = this.currentTab().dataset.url;
    const response = await fetch(url);
    const data = await response.text();
    this.contentTarget.innerHTML = data;
  }

  currentTab() {
    let currentTab;
    this.tabTargets.forEach((tab) => { if (tab.getAttribute('active') == 'true') { currentTab = tab } });
    return currentTab
  }

  // load only the header
  // the flow content of the track is load by the 'connect' method
  // this same controller will be connected again in openning the large sidebar and
  // will request the flow controller (define by the url of actor_progress or _progress_bar depending on which side we want to see)
  async openLargeSidebar(event) {
    const sidebar = document.getElementById('large-sidebar');
    sidebar.setAttribute('activated', 'true');
    addSidebarBackdrop();
    const url = event.currentTarget.dataset.url;
    const response = await fetch(url);
    const data = await response.text();;
    sidebar.innerHTML = data;
    document.body.classList.add('overflow-hidden');
  }
}

const addSidebarBackdrop = () => {
  const backdropEl = document.createElement('div');
  backdropEl.classList.add('large-sidebar-backdrop');
  backdropEl.setAttribute('data-action', 'click->desktop--hr--sidebars#hideSidebars');
  document.querySelector('div[data-controller*="desktop--hr--sidebars"]').appendChild(backdropEl);
};

