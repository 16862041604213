import { Controller } from "@hotwired/stimulus"
import { formId, getStorage, setStorage } from 'packs/controllers/form_resources_controller';

export default class extends Controller {
  static targets = ['current']

  moveUp() {
    const current = this.currentTarget;
    const previous = current.previousElementSibling;

    if (isOrderable(previous)) { this.swap(current, previous); }
  }

  moveDown() {
    const current = this.currentTarget;
    const next = current.nextElementSibling;

    if (isOrderable(next)) { this.swap(next, current); }
  }

  swap(a, b) {
    a.parentNode.insertBefore(a, b);
    let storage = getStorage(formId(this.element));

    let aId = a.getAttribute('data-conditional-logics-id-value');
    let aStorage = storage.conditional_logics.find((el) => { return el.id == aId });
    aStorage.position -= 1;

    let bId = b.getAttribute('data-conditional-logics-id-value');
    let bStorage = storage.conditional_logics.find((el) => { return el.id == bId });
    bStorage.position += 1;

    setStorage(formId(this.element), storage);
  }
}

function isOrderable(element) {
  if (!element) { return false; }
  return element.getAttribute('data-utils--orderables-target') === 'current';
}
