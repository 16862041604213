import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['block']

  connect() {
    this.loadBlock();
  }

  loadBlock() {
    const url = this.blockTarget.dataset.url
    const id = this.blockTarget.dataset.id

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.displayTrack(data, id)
      }
    });
  }

  displayTrack(data, id) {
    $('#api_connection-' + id).replaceWith(data['content']);
  }
}
