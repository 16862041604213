import { Controller } from "@hotwired/stimulus";

// This answer method is to show the hr how the feedback from the collaborator view would look like
// It doesn't save anything
export default class extends Controller {
  values = { regex: String }

  answer(event) {
    const element = event.currentTarget;
    const question = document.getElementById(`${element.dataset.questionName}`);

    removeSelectedClassTo(question);
    element.classList.add('selected');
  }

  checkEmail(event) {
    const errorMessage = document.getElementById('feedback-email-error');
    let emailValue = event.currentTarget.value;
    let regex = new RegExp(event.currentTarget.dataset.regex);

    if (!isEmailValid(emailValue, regex)) {
      // show error message after 2sec if input value doesn't match regex of an email
      errorMessage.classList.remove('invisible');
    } else if (isEmailValid(emailValue) && !errorMessage.classList.contains('invisible')) {
      // when input value does match regex of an email, error message disappear
      errorMessage.classList.add('invisible');
    }
  }
}

const isEmailValid = (emailAdress, regex) => {
  return (emailAdress.match(regex)) ? true : false;
}

const removeSelectedClassTo = (question) => {
  question.querySelectorAll('.scale_choice').forEach(el => el.classList.remove('selected'));
};
