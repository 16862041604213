import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actionType", "helpText"];

  connect() {
    this.toggleDisplayHelpText();
  }

  setExtensions() {
    this.toggleDisplayHelpText();
  }

  toggleDisplayHelpText() {
    if (this.actionTypeTarget.value === 'sign') {
      this.helpTextTarget.classList.remove('d-none');
    } else {
      this.helpTextTarget.classList.add('d-none');
    }
  }
}
