import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

// This controller stimulus replace the getActors() method
export default class extends Controller {
  static targets = [ 'start', 'stop', 'modify' ]

  connect () {
    this.isMouseDown = false;
  }

  toggle() {
    let banner = document.querySelector('#cards-list');
    if (banner.dataset.reposition === 'false') {
      this.start();
    } else {
      this.stop();
    }
  }

  start() {
    this.toggleButtons();
    let banner = document.querySelector('#cards-list');
    banner.dataset.reposition = 'true';
    document.body.style.cursor = 'move';
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseup);
    document.addEventListener('mousemove', this.handleDrag);
  }

  stop() {
    this.toggleButtons();
    let banner = document.querySelector('#cards-list');
    banner.dataset.reposition = 'false';
    document.body.style.cursor = 'auto';
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseup);
    document.removeEventListener('mousemove', this.handleDrag);
  }

  handleMouseDown(event) {
    let banner = document.querySelector('#cards-list');
    this.isMouseDown = true;
    this.startY = event.clientY;
    this.startBackgroundPos = parseFloat(getComputedStyle(banner).getPropertyValue('background-position-y'));
  }

  handleMouseup(event) {
    this.isMouseDown = false;
    let banner = document.querySelector('#cards-list');
    let BackgroundPos = parseFloat(getComputedStyle(banner).getPropertyValue('background-position-y'));
    let bannerType = banner.dataset.bannerType;
    fetch('/actor/account/banners/' + bannerType, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        position: BackgroundPos
      })
    });
  }

  handleDrag(event) {
    let banner = document.querySelector('#cards-list');
    if (this.isMouseDown) { // Only change the background position if the mouse is down
      let deltaY = (this.startY - event.clientY) / banner.offsetHeight * 100;
      // Ensure newBackgroundPos stays between 0% and 100%
      let newBackgroundPos = Math.max(0, Math.min(100, this.startBackgroundPos + deltaY));
      banner.style.backgroundPosition = 'center ' + newBackgroundPos + '%';
    }
  }

  toggleButtons() {
    this.modifyTarget.classList.toggle('d-none');
    this.startTarget.classList.toggle('d-none');
    this.stopTarget.classList.toggle('d-none');
  }
}
