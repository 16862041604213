import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { cable: String }

  connect() {
    subscribe(this.cableValue);
  }

  disconnect() {
    unsubscribe(this.cableValue);
  }
}

const subscribe = (cable) => {
  App.cable.subscriptions.create(
    subscriptionIdentifier(cable),
    {
      received: (response) => { callbackInternalNotes(response); }
    }
  );
}

const unsubscribe = (cable) => {
  App.cable.subscriptions['subscriptions'].forEach((subscription) => {
    if (subscription.identifier == JSON.stringify(subscriptionIdentifier(cable))) {
      App.cable.subscriptions.remove(subscription);
    }
  });
}

const subscriptionIdentifier = (cable) => {
  let parts = cable.split(/_(\d+)/);
  let topicType = parts[0];
  let topicId = parts[1];
  return { channel: 'TopicsChannel', topic_type: topicType, topic_id: topicId };
}

const callbackInternalNotes = (response) => {
  let current_user_id = document.body.dataset.currentUserId;
  let user_id = response.user_id;
  let message_id = response.message_id
  let message_partial = $(response.message_partial);
  if (current_user_id != user_id) { $('#internal-notes #index').prepend(message_partial); }
  if (message_id) {
    $('#message_' + message_id).slideUp("normal", function () { $(this).remove(); });
  }
}
