import Dialog from '@stimulus-components/dialog/dist/stimulus-dialog.umd.js'

export default class extends Dialog {
  currentTarget = null

  open(target) {
    this.currentTarget = target
    super.open()
  }

  openWithContent(event) {
    fetch(event.currentTarget.dataset.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      document.querySelector('dialog[data-desktop--actor--dialog-target="dialog"]').innerHTML = data.content
      this.open();
    }).catch(error => {
      console.log(error);
    })
  }

  closeAndFollowLink() {
    super.close()
    if (this.currentTarget) {
      document.body.dataset['desktop-Actor-SavingsUnsavedChangesValue'] = false
      this.currentTarget.click()
      this.currentTarget = null
    }
  }
}
