// This controller handles the display of sidebar
import { Controller } from "@hotwired/stimulus"
import { replaceValues, activateScrolling } from './../../../../pages/sidebar_utils'
export default class extends Controller {
  static targets = ['body', 'sidebar', 'template']
  static values = { trackId: String, employeeId: String, mobility: String, tab: String }

  // Hide sidebar on outside sidebar clicks
  connect() {
    this.bodyTarget.addEventListener('click', event => {
      if (!this.clickOnRow(event) && this.sidebarIsActivated()) { this.hideSidebar() }
    })

    if (this.trackIdValue) this.openSidebar();
  }

  openSidebar() {
    // STEP 1 : Fetch sidebar template content
    let content = this.templateTarget.innerHTML;
    // STEP 2 : Replace values and set alert to true and others to false
    let matches = {
      '{{{trackId}}}': this.trackIdValue,
      '{{{employeeId}}}': this.employeeIdValue,
      '{{{mobility}}}': this.mobilityValue,
      '{{{alertActivated}}}': this.tabValue === 'alert',
      '{{{administrativeActivated}}}': this.tabValue === 'administrative',
      '{{{feedbacksActivated}}}': this.tabValue === 'feedbacks',
      '{{{actionsActivated}}}': this.tabValue === 'actions'
    }

    this.sidebarTarget.innerHTML = replaceValues(content, matches);
    // STEP 4 : Change boolean of sidebar
    this.sidebarTarget.setAttribute('activated', 'true');
  }

  hideSidebar() {
    this.deactivateLine();
    document.getElementById('hr-sidebar').setAttribute('activated', 'false');
  }

  hideLargeSidebar() {
    document.querySelector('.large-sidebar-backdrop[data-action="click->desktop--hr--sidebars#hideSidebars"]').remove();
    document.getElementById('large-sidebar').setAttribute('activated', 'false');
    activateScrolling();
  }

  hideSidebars() {
    this.hideSidebar();
    this.hideLargeSidebar();
  }

  deactivateLine() {
    const line = document.querySelector('.tabulator-row.active');
    if (line) {
      line.classList.remove('active');
      this.removeAvatarBorder(line);
    }
  }

  removeAvatarBorder(line) {
    const mobility = line.dataset.mobility;
    const avatar = line.querySelector(`.avatar-small.border-${mobility}`)
    if (avatar) avatar.classList.remove(`border-${mobility}`)
  }

  clickOnRow(event) {
    let clickOnRow;

    // set clickOnRow as true if the event target is or is contained in tabulator-row
    const path = event.path || (event.composedPath && event.composedPath())
    path.forEach(el => {
      if ((el.classList) && el.classList.contains('tabulator-row')) {
        return clickOnRow = true
      };
    });

    return clickOnRow;
  }

  sidebarIsActivated() {
    const sidebar = document.getElementById('hr-sidebar');
    return sidebar !== null && sidebar.getAttribute('activated') === 'true';
  }
}
