import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String,
    employeeId: String,
    url: String,
    codeName: String,
    name: String,
    columnDelimiter: String,
    language: String,
    job: Boolean,
    flash: String
  }

  connect() {
    if (this.jobValue) { this.load() }
  }

  load() {
    this.subcribe();
    this.call();
  }

  call() {
    $.ajax({
      url: this.urlValue,
      type: 'POST',
      data: { id: this.idValue },
      dataType: 'script'
    });
  }

  // Placebo for client
  refresh() {
    flash(this.flashValue);
  }

  subcribe() {
    const columnDelimiter = this.columnDelimiterValue || ';';
    let fixedColumnsLeft = 2;

    if (this.codeNameValue == 'socotec_package'){
      fixedColumnsLeft = 6;
    } else if (this.codeNameValue == 'socotec_package_full'){
      fixedColumnsLeft = 6;
    } else if (this.codeNameValue == 'socotec_medical'){
      fixedColumnsLeft = 4;
    };

    App[`report_${this.idValue}_employee_${this.employeeIdValue}`] = App.cable.subscriptions.create(
      { channel: 'ReportsChannel', report_id: this.idValue, employee_id: this.employeeIdValue },
      {
        received: (tableData) => {
          if ($("#export-file").hasClass('d-none')){
            var data = tableData['data'],
                colHeaders = tableData['colHeaders'],
                colWidths = tableData['colWidths'],
                columns = tableData['columns'],
                container = document.getElementById('report-table'),
                exportButton = document.getElementById('export-file'),
                reportTable,
                exportPlugin;

            Handsontable.renderers.registerRenderer('darkBackgroundRenderer', darkBackgroundRenderer);
            Handsontable.renderers.registerRenderer('yellowBackgroundRenderer', yellowBackgroundRenderer);
            Handsontable.renderers.registerRenderer('negativeValueRenderer', negativeValueRenderer);
            Handsontable.renderers.registerRenderer('linkRenderer', linkRenderer);
            Handsontable.renderers.registerRenderer('percentRenderer', percentRenderer);

            reportTable = new Handsontable(container, {
              data: data,
              language: this.language(),
              width: '100%',
              height: window.innerHeight - 150,
              rowHeights: 35,
              rowHeaders: false,
              fixedRowsTop: 0,
              fixedColumnsLeft: fixedColumnsLeft,
              columnSorting: true,
              dropdownMenu: true,
              filters: true,
              readOnly: true,
              manualColumnResize: true,
              licenseKey: 'non-commercial-and-evaluation',
              colHeaders: colHeaders,
              colWidths: colWidths,
              columns: columns
            });
            exportPlugin = reportTable.getPlugin('exportFile');
            exportButton.addEventListener('click', () => {
              exportPlugin.downloadFile('csv', {
                bom: true,
                columnDelimiter: columnDelimiter,
                columnHeaders: true,
                exportHiddenColumns: true,
                exportHiddenRows: true,
                fileExtension: 'csv',
                filename: `${this.nameValue} [DD]-[MM]-[YYYY]`,
                mimeType: 'text/csv',
                rowDelimiter: '\r\n',
                rowHeaders: false
              });
            });
            $('.loading').remove();
            $('#export-file').removeClass('d-none');
            $('#refresh-report').addClass('d-none');
          }
        }
      }
    )
  };

  language() {
    const languages = { 'en': 'en-US', 'fr': 'fr-FR' };

    return languages[this.languageValue];
  }
}
