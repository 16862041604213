import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dropdown', 'file'];

  setExtensions() {
    if (this.dropdownTarget.value == 'sign'){
      this.fileTarget.accept = '.docx';
    } else {
      this.fileTarget.accept = this.fileTarget.dataset.defaultAccept;
    }
  }

  setSigners() {
    let signers = document.querySelector('.workflow_esign_signers')
    let variables = document.querySelector('.workflow_variables')
    if(this.dropdownTarget.value == 'sign') {
      signers.classList.remove('d-none');
      variables.classList.remove('d-none');
    } else {
      signers.classList.add('d-none');
      variables.classList.add('d-none');
    }
  }
}


