export function stringToHTML(htmlString) {
  const temp = document.createElement('div');
  temp.innerHTML = htmlString;
  return temp.firstElementChild;
}

export function htmlToString(element) {
  const temp = document.createElement('div');
  temp.appendChild(element.cloneNode(true));
  return temp.innerHTML;
}
