// This controller is called when we click on the 'actions' and 'collaborators' buttons
// it takes care to load the main part of the page (actions or collaborator blocks)
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['button'];

  load(event) {
    const placeholder = document.getElementById(`${event.currentTarget.dataset.type}-placeholder`).innerHTML
    this.fill(placeholder);
    this.updateActiveButton(event)
    this.updateSwitch(event)
    this.call(event.currentTarget.dataset.url)
    this.updateUrl(event)
  }

  call(url) {
    fetch(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.fill(data.content);
      if (data.content === '') {
        Stimulus.controllers.find(el => el.identifier == 'utils--blank-states').showBlankState()
      } else {
        // reconnect blank states controller
        Stimulus.controllers.find(el => el.identifier == 'utils--blank-states').connect()
      }
    }).catch(error => {
      console.log(error);
    })
  }

  // the main element is and has to remain on both, actions & collaborators pages
  fill(content) {
    document.getElementById('main').innerHTML = content;
  }

  updateActiveButton(event) {
    this.buttonTargets.forEach(button => button.classList.remove('active'));
    event.currentTarget.classList.add('active');
  }

  // we have to remove the id of the actions as it is a show and we want to simulate the index
  updateUrl(event) {
    window.history.pushState({}, '', event.currentTarget.dataset.url.replace(/\/\d+/, ''));
  }

  // Update switch with the new history url
  updateSwitch(event) {
    const switchInput = document.querySelector("[data-desktop--actor--navigation-switchs-component--navigation-switchs-component-target='input']");
    let targetUrl = event.currentTarget.dataset.url;
    switchInput.dataset.checkedUrl = getPathWithParams(switchInput.dataset.checkedUrl, targetUrl);
    switchInput.dataset.uncheckedUrl = getPathWithParams(switchInput.dataset.uncheckedUrl, targetUrl);
  }
}

function getPathWithParams(path1, path2) {
  // Create a URL object for each URL path
  const urlObject1 = new URL(`http://localhost${path1}`);
  const urlObject2 = new URL(`http://localhost${path2}`);

  // Retrieve URL1 path's parameters
  const params1 = urlObject1.searchParams;

  // Add parameters from URL path 1 to URL path 2
  params1.forEach((value, key) => {
    urlObject2.searchParams.set(key, value);
  });

  // Return URL path 2 with the parameters of URL path 1
  return urlObject2.pathname + urlObject2.search;
}
