import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'subject', 'body', 'photo']

  openPreviewInNewTab() {
    const form = this.formTarget;
    const formData = new FormData(form);
    formData.set('mailing_resource[subject]', this.subjectTarget.value);
    formData.set('mailing_resource[rich_body]', this.bodyTarget.value);

    if (this.photoTarget.files[0]) {
      formData.set('mailing_resource[photo]', this.photoTarget.files[0]);
    } else {
        // In the #edit form, photo input is empty, even though a photo may have been uploaded
        const existingPhotoUrl = document.getElementById("mailing-resource-uploaded-photo").src;
        if (!existingPhotoUrl.includes('settings/mailing_resource')) { formData.set('mailing_resource[photo_url]', existingPhotoUrl); }
    }

    fetch((form.action), {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'text/html'
      }
    })
    .then(response => response.text())
    .then(html => {
      const newTab = window.open();
      newTab.document.open();
      newTab.document.write(html);
      newTab.document.close();
      // URL to be displayed instead of about:blank
      // const url = new URL(form.action, window.location.origin);
      // newTab.history.pushState({}, '', url.toString());
    })
    .catch(error => console.error('Error:', error));
  }
}
