import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  // addItem is the element allowing to add a new nested_asnwer
  // template is the nested_answer template
  // list is the list of responses : where to add the new nested_answer created from the template
  // item is a nested_answer
  static targets = ["addItem", "template", "list", "items"]

  connect() {
    new Sortable(this.listTarget, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: (_event) => {
        this.updatePositions();
      }
    });

    this.updatePositions();
  }

  addAssociation(event) {
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.listTarget.insertAdjacentHTML('beforeend', content);
    this.updatePositions();
  }

  removeAssociation(event) {
    event.preventDefault()
    let item = event.target.closest("[data-desktop--desktop--settings--question-resources--nested-answers=item]");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
    item.classList.remove('d-flex');
    this.updatePositions();
  }

  updatePositions() {
    let items = [...this.listTarget.children].filter((el) => { return (el.tagName == 'DIV') && (el.style.display != 'none') });
    items.forEach((el, index) => {
      let position = index + 1;
      el.querySelector('[data-position]').innerText = `${position}.`;
      el.querySelector('[name*=position]').value = position;
    })
  }
}
