import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { flash } from '../../../../../components/flashes';

export default class extends Controller {
  static values = {
    resourceClass: String,
    resourceId: String
  }

  duplicate() {
    const notification = document.querySelector('div[data-controller*="desktop--settings--resources--creation-sidebar"]').dataset.duplicationNotification;
    flash(notification);
    fetch('/settings/duplications', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        resource_class: this.resourceClassValue,
        resource_id: this.resourceIdValue
      })
    })
      .then(response => response.json())
      .then(responseJSON => {
        window.location.href = responseJSON['url'];
      });
  }
}
