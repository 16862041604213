// this function is called when we change the actor of the select in the actor view
// it displays the elements for the selected actor
export function updateActorScope(actor) {
  const elements = document.querySelectorAll('.line-action');
  [...elements].forEach(element => {
    if (element.dataset.scope?.split(' ')?.includes(actor)) {
      element.classList.remove('opacity-55');
      element.classList.remove('box-shadow-none');
    } else if (!element.dataset.scope?.split(' ')?.includes(actor)) {
      element.classList.add('opacity-55');
      element.classList.add('box-shadow-none');
    }
    
    if (actor === 'all') {
      element.classList.remove('opacity-55');
      element.classList.remove('box-shadow-none');
    }
  });
}

// this function allows to update dynamically the select of the 'updateActorScope' function
// it is called in severals places as soon as we touch to an implication/actor
export function getActors() {
  const select = document.getElementById('actor');
  if (select) {
    // as we remake the select, we remove all filters
    [...document.querySelectorAll('.line-action')].forEach(el => {
      el.classList.remove('opacity-55');
      el.classList.remove('box-shadow-none');
    });
  
    // we have to make successive split and manipulation for the meeting which have several implication
    const allImplications = [...document.querySelectorAll('[data-scope]')].map(el => el.dataset.scope
                                                                              .split(' '))
                                                                              .join(' ')
                                                                              .replaceAll(',', ' ')
                                                                              .split(' ')
                                                                              .filter(el => el != '');
    const filteredImplications = [...new Set(allImplications)];
    // we set all at the top of the list
    while (filteredImplications.includes('all')) { filteredImplications.splice(filteredImplications.indexOf('all'), 1) };
    filteredImplications.unshift('all'); 
  
    const labels = JSON.parse(document.getElementById('setting-content').dataset.implications);
    const htmlOptions = filteredImplications.map(implication => `<option value="${implication}">${labels[implication]}</option>`)
    select.innerHTML = htmlOptions.join('');
  }
}

// this function is used to set the right owner or role to a todo (in the sidebar creation or edit modal) 
// depending on if we add someone with algolia, with the implications list or by writing the mail directly
export function updateValues(el) {
  const owner = document.getElementById('todo_owner_attributes_email') || document.getElementById('todo_owner');
  owner.value = el.value;
  let algoliaDiv = document.querySelector('.aa-dropdown-menu');
  if (algoliaDiv) { 
    algoliaDiv.addEventListener('click', e => {
      let newValue;
      if (!e.target.innerText.includes('@')) {
        newValue = e.target.previousElementSibling.innerText;
      } else if (e.target.nodeName == 'DIV') {
        newValue = e.target.firstElementChild.innerText
      } else {
        newValue = e.target.innerText;
      }
      owner.value = newValue;
    });
    document.getElementById('todo_for_role').value = '';
  }; 
}

// this function is used to set the right owner or role to a mailing (in the sidebar creation or edit modal) 
// depending on if we add someone with algolia, with the implications list or by writing the mail directly
export function updateMailingValues(el) {
  const owner = document.getElementById('mailing_owner_attributes_email') || document.getElementById('mailing_owner');
  owner.value = el.value;
  let algoliaDiv = document.querySelector('.aa-dropdown-menu');
  if (algoliaDiv) { 
    algoliaDiv.addEventListener('click', e => {
      let newValue;
      if (!e.target.innerText.includes('@')) {
        newValue = e.target.previousElementSibling.innerText;
      } else if (e.target.nodeName == 'DIV') {
        newValue = e.target.firstElementChild.innerText
      } else {
        newValue = e.target.innerText;
      }
      owner.value = newValue;
    });
    document.getElementById('mailing_for_role').value = '';
  }
}
