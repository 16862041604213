const initializeSelectize = (selector) => {
  const seletizeInput = document.querySelector(selector);
  const url = seletizeInput.dataset.url;
  const resourceType = seletizeInput.dataset.resourceType;
  const resourceId = seletizeInput.dataset.resourceId;

	let basicOptions = {
		preload: true,
		create: false,
		load: function(query, callback) {
			$.ajax({
				url: url,
				type: 'GET',
				dataType: 'json',
				data: {
					query: query,
					resource_type: resourceType,
					resource_id: resourceId
				},
				error: function() {
					callback();
				},
				success: function(response) {
					callback(response);
				}
			});
		}
	}
  $(selector).selectize(fullOptions(resourceType, basicOptions));
};

export { initializeSelectize };

const fullOptions = (resourceType, options) => {
	if (resourceType == 'SurveyQuestion') {
		return {
			...options,
			valueField: 'id',
			labelField: 'content',
			searchField: 'content',
			render: {
				option: function(item, escape) {
					return '<div style="padding: 6px 15px;">' + escape(item.content) + '</div>';
				}
			},
		}
  } else if (resourceType == 'UnitOrganization') {
    return {
      ...options,
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      render: {
        option: function (item, escape) {
          return '<div style="padding: 6px 15px;">' + escape(item.name) + '</div>';
        }
      },
    }
  } else {
		return {
			...options,
			valueField: 'value',
			labelField: 'value',
			searchField: 'value',
			render: {
				option: function(item, escape) {
					return '<div style="padding: 6px 15px;">' + escape(item.value) + '</div>';
				}
			},
		}
	}
}
