import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'
import {
  adaptFiltersForTabulator,
  setPagesNumber,
  toggleColumnFilter,
  getFilters,
  updateTracksCount,
  refreshTabulator
} from "./utils";

export class BaseFilterController extends Controller {
  // this.controller & this.column are defined in the children
  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    if (this.hasMenuTarget && this.menuTarget.classList.contains('menu-opened')) {
      this.toggleMenu();
    }
  }

  // overwrite in date_filter children
  filter(event) {
    let filter = event.currentTarget.dataset[`desktop-Hr-Filters-${this.controller()}FilterTarget`]
    this.filters = getFilters();
    if (this.controller() === 'Organization') { filter = +filter }

    if (this.isFiltered(filter)) {
      this.remove(filter);
    } else {
      this.add(filter);
    }
    this.applyFilters();
    refreshTabulator();
    this.toggleCheck(event.currentTarget);
    this.toggleButtonActive();
    setPagesNumber();
    updateTracksCount();
  }

  selectAll() {
    const valueElements = [...this.menuTarget.querySelectorAll([`[data-desktop--hr--filters--${this.htmlTarget()}-filter-target]`])];
    let values = valueElements.map(element => element.dataset[`desktop-Hr-Filters-${this.controller()}FilterTarget`]);
    // we remove the search bar element for the organizations and we convert num string in integers
    if (this.controller() === 'Organization') {
      values.shift();
      values = values.map(value => value = +value);
      valueElements.shift();
    };
    this.setFilters(this.column(), values);
    valueElements.forEach(element => element.lastElementChild.classList.remove('d-none'));
    this.applyFilters();
    refreshTabulator();
    this.toggleButtonActive();
    setPagesNumber();
    updateTracksCount();
  }

  removeAll() {
    const valueElements = [...this.menuTarget.querySelectorAll([`[data-desktop--hr--filters--${this.htmlTarget()}-filter-target]`])];
    // we remove the search bar element for the organizations
    if (this.controller() === 'Organization') { valueElements.shift() };
    this.setFilters(this.column(), ['']);
    valueElements.forEach(element => element.lastElementChild.classList.add('d-none'));
    this.applyFilters();
    this.toggleButtonActive();
    setPagesNumber();
    updateTracksCount();
  }

  // this function persist the filter in the html
  setFilters(column, filters) {
    const objectFilters = getFilters();
    objectFilters['column_filters'][column] = filters;
    document.getElementById('filters').dataset.filters = JSON.stringify(objectFilters);
  }

  // this function get the filter in the html to apply it to the tabulator
  applyFilters() {
    const tabulator = Tabulator.findTable('#tracks')[0];
    const column_filters = getFilters().column_filters;
    const scrollY = window.scrollY;
    tabulator.setFilter(adaptFiltersForTabulator(column_filters));
    window.scrollTo({top: scrollY});
  }

  isFiltered(filter) {
    return this.filters['column_filters'][this.column()].includes(filter);
  }

  add(filter) {
    const columnFilter = this.filters['column_filters'][this.column()];
    columnFilter.push(filter);
    // we remove the empty string if we had nothing selected
    if (columnFilter.includes('')) { columnFilter.splice(0, 1) };
    this.setFilters(this.column(), columnFilter);
  }

  remove(filter) {
    const columnFilter = this.filters['column_filters'][this.column()];
    const index = columnFilter.indexOf(filter);
    columnFilter.splice(index, 1);
    // we push an empty string if all filters are removed in order to display nothing (as nothing is selected)
    if (columnFilter.length === 0) { columnFilter.push('') };
    this.setFilters(this.column(), columnFilter);
  }

  toggleButtonActive() {
    let filterType = this.column().replace('_ids', '');
    const filterButton = document.querySelector(`.${filterType}-column-filter`);
    const icon = document.querySelector(`.${filterType}-filter-icon`);

    // returns the tabulator filter object
    const tabulatorFilter = Tabulator.findTable('#tracks')[0].getFilters().find(filter => filter.field === this.column());
    if (this.isColumnFiltered(tabulatorFilter)) {
      filterButton.classList.add('active');
      icon.classList.add('fa-times');
      icon.classList.remove('fa-chevron-up');
      const action = filterType === 'date' ? 'resetFilter' : 'selectAll';
      icon.parentElement.setAttribute("data-action", `click->desktop--hr--filters--${filterType}-filter#${action}`);
    } else {
      filterButton.classList.remove('active');
      icon.classList.add('fa-chevron-down');
      icon.classList.remove('fa-times');
      icon.parentElement.setAttribute("data-action", `click->desktop--hr--filters--${filterType}-filter#toggleMenu`);
    };
  }

  isColumnFiltered(tabulatorFilter) {
    const defaultValues = document.getElementById('default-values');
    const [mobilitiesNum, statusesNum, organizationsNum] = [defaultValues.dataset.mobilities.split(' ').length,
                                                            defaultValues.dataset.statuses.split(' ').length,
                                                            defaultValues.dataset.organizationIds.split(' ').length];
    const isMobilityFiltered = (tabulatorFilter.field === 'mobility') && (tabulatorFilter.value.length < mobilitiesNum);
    const isStatusFiltered = (tabulatorFilter.field === 'status') && (tabulatorFilter.value.length < statusesNum);
    const isDateFiltered = (tabulatorFilter.field === 'date') && (tabulatorFilter.value.length > 0);
    const isOrganizationFiltered = (tabulatorFilter.field === 'organization_ids') && (tabulatorFilter.value.length < organizationsNum);

    return isMobilityFiltered || isStatusFiltered || isDateFiltered || isOrganizationFiltered;
  }

  // overwrite in date_filter children
  toggleCheck(element) {
    element.lastElementChild.classList.toggle('d-none');
  }

  toggleMenu() {
    document.querySelectorAll('.select').forEach(select => {
      if (select.classList.contains('select-opened') && this.selectTarget != select) {
        toggleColumnFilter(select, select.parentElement.nextElementSibling);
      }
    });
    toggleColumnFilter(this.selectTarget, this.menuTarget);
  }
}
