import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updateButton()
  }

  updateButton() {
    const submitButton = document.getElementById('submit-paperwork-resource');
    const value = this.element.value;
    if (["date", "text"].includes(value)) {
      submitButton.dataset.action = ""
    } else {
      submitButton.dataset.action = "desktop--actor--navigations#submit"
    }
  }
}
