import { Controller } from "@hotwired/stimulus"
import { handleDisplay } from "../chart_filters_controller";

export default class extends Controller {
  static targets = ['input']
  static values = { url: String }

  connect() {
    this.clickHandler = event => {
      const filterDiv = document.querySelector('div[data-controller="desktop--hr--charts--email-feedbacks"]');
      if (!filterDiv.contains(event.target)) {
        const menu = document.getElementById('email-feedback-menu');
        menu.classList.remove('menu-opened');
        menu.classList.add('menu-closed');
       };
    };

    document.addEventListener('click', this.clickHandler);
  }

  disconnect() {
    document.removeEventListener('click', this.clickHandler);
  }

  select(event) {
    const category = event.currentTarget.value;
    document.getElementById('email-feedback-name').innerText = event.currentTarget.previousElementSibling.innerText;
    const controller = Stimulus.controllers
      .filter(controller => controller.chart)
      .find(el => el.chart.canvas.parentNode.attributes['data-desktop--hr--chart-url-value'].value.match('feedback_vertical_bars'));

    controller.element.setAttribute('loading', true);

    $.ajax({
      url: this.urlValue,
      type: 'POST',
      data: {
        filters: JSON.parse(document.querySelector('div[data-desktop--hr--chart-filters-filters-value]').dataset['desktop-Hr-ChartFiltersFiltersValue']),
        email_feedback: category
      },
      dataType: 'json'
    }).then(data => {
      this.toggleMenu();
      handleDisplay(data, controller);
    });
  }

  toggleMenu() {
    const menu = document.getElementById('email-feedback-menu');
    if (menu.classList.contains('menu-opened')) {
      menu.classList.remove('menu-opened');
      menu.classList.add('menu-closed');
    } else {
      menu.classList.remove('menu-closed');
      menu.classList.add('menu-opened');
    }
  }
}
