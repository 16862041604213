import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['main', 'footer' ]
  connect() {
    this.checkMainHeight();
    document.addEventListener('click', this.updateNavbar.bind(this));
    this.observeMainHeight();
  }

  checkMainHeight() {
    const hasScrollbar = this.mainTarget.scrollHeight > this.mainTarget.clientHeight

    if (hasScrollbar) {
      this.footerTarget.classList.add("with-box-shadow")
    } else {
      this.footerTarget.classList.remove("with-box-shadow")
    }
  }

  observeMainHeight() {
    const resizeObserver = new ResizeObserver(entries => {
      setTimeout(() => {
        for (let entry of entries) {
          this.checkMainHeight()
        }
      }, 0)
    })
    resizeObserver.observe(this.mainTarget)
  }

  updateNavbar(event) {
    let target = event.target;
    let parent = target.parentElement;

    while (parent) {
      if (parent.hasAttribute('data-link-target')) {
        let link = document.querySelector(`[link=${parent.getAttribute('data-link-target')}]`)
        markLinkAsActive(link);
        break;
      }
      parent = parent.parentElement;
    }
    this.checkMainHeight();
  }
}

export const markLinkAsActive = (element) => {
  let unsavedChanges = document.body.dataset['desktop-Actor-SavingsUnsavedChangesValue'] == 'true'
  if (unsavedChanges) { return }

  let section = element.closest('section');
  moveActiveClass(section, 'navbar section');
  let link = element.closest('[link]') || element.querySelector('[link]');
  moveActiveClass(link, 'navbar [link]')
}

const markAsActive = (element) => {
  if (element) { element.classList.add('active') }
}

const markAsInactive = (element) => {
  if (element) { element.classList.remove('active') }
}

const moveActiveClass = (link, disactive_selector) => {
  document.querySelectorAll(disactive_selector).forEach((link) => markAsInactive(link));
  markAsActive(link);
}
