import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  openSidebar() {
    const controllerDiv = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
    const locale = document.querySelector('html').lang

    controllerDiv.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
      {
        "email_category": "all",
        "url": "/${locale}/actor/analyse/email_feedbacks"
      }
    `);
    document.getElementById('trigger-sidebar').click()
  }
}
