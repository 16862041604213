import { Controller } from "@hotwired/stimulus"


export default class extends Controller  {
  static targets = ['displayActionsText', 'hideActionsText', 'list']

  toggleActions(event) {
    this.updateText()
    this.listTarget.classList.toggle('d-none')
    updateChevron(event)
  }

  updateText() {
    this.displayActionsTextTarget.classList.toggle('d-none')
    this.hideActionsTextTarget.classList.toggle('d-none')
  }
}

const updateChevron = (event) => {
  const icon = event.currentTarget.querySelector('i');
  const newIcon = icon.className.includes('down') ? 'up' : 'down';
  icon.className = icon.className.replace(/down|up/, newIcon);
};
