import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    enterKey: {
      type: Boolean,
      default: false,
    },
    allowFormat: {
      type: Boolean,
      default: true,
    }
  }

  connect() {
    this.element.addEventListener('paste', (event) => {
      event.preventDefault();
      let text = (event.originalEvent || event).clipboardData.getData('text/plain');
      document.execCommand('insertText', false, clean(text));
    });
    this.element.addEventListener('focusout', (_event) => {
      if (!this.allowFormatValue){
        this.element.innerText = clean(this.element.innerText)
      }
    });
    this.element.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && !this.enterKeyValue) {
        // Simulate an input submit
        event.preventDefault();
        this.element.blur();
      } else if (event.key === 'Tab') {
        event.preventDefault();
      }
    });
  }
}

const clean = (string) => {
  return string.trim()
               .replaceAll("\r\n", ' ')
               .replaceAll("\r", ' ')
               .replaceAll("\n", ' ')
               .replaceAll("\t", ' ')
}
