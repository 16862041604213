import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    const answerLists = [...document.querySelectorAll('div[data-desktop--onboardee--quiz-target][data-question=ranking] div[data-list]')];

    answerLists.forEach(answerList => {
      new Sortable(answerList, {
        animation: 150,
        ghostClass: 'blue-background-class',
        onEnd: (_event) => {
          this.updatePositions(answerList);
        }
      });

      this.updatePositions(answerList);
    })
  }

  currentState() {
    return JSON.parse(document.getElementById('state').dataset.state);
  }

  updateState(newState) {
    document.getElementById('state').dataset.state = newState;
  }

  // This function update the state which stores the quiz answers
  toggle(event) {
    this.state = this.currentState();

    const questions = [...document.querySelectorAll('div[data-desktop--onboardee--quiz-target]')];
    const questionIndex = +document.querySelector('div[data-desktop--onboardee--quiz-index-value]').dataset['desktop-Onboardee-QuizIndexValue'] - 1;
    const questionId = +questions[+questionIndex].id.split('_')[2];
    const answerId = +event.currentTarget.id.split('_')[2];

    this.state[questionId].answers[answerId].selected = !this.state[questionId].answers[answerId].selected;
    this.updateState(JSON.stringify(this.state));
  }

  finalize() {
    const questions = Object.entries(this.currentState());
    let score = 0;
    questions.forEach(([question_id, question_attributes]) => {
      const questionIsCorrect = isQuestionCorrect(question_attributes);
      if (questionIsCorrect) { score += 1; }
      if (question_attributes.type == 'ranking') {
        displayRankingAnswersFor(question_attributes, questionIsCorrect)
      } else if (question_attributes.type == 'linked') {
        displayLinkedAnswersFor(question_attributes)
      } else {
        displaySelectedAnswerFor(question_attributes);
      }
      displayRightIconFor(question_id, questionIsCorrect);
    })
    displayScore(score, questions.length);
  }

  updatePositions = (answerList) => {
    let state = this.currentState();
    let answers = [...answerList.children].filter((el) => { return (el.tagName == 'DIV') && (el.style.display != 'none') });
    answers.forEach((answer, index) => {
      const position = index + 1;
      const questionId = answerList.dataset.questionId;
      const answerId = answer.dataset.answerId;
      const current_content = Object.values(state[questionId].answers).find((obj) => { return obj.correct_position == position }).content;

      answer.querySelector('[data-position]').innerText = position;
      state[questionId].answers[answerId].position = position;
      state[questionId].answers[answerId].current_content = current_content;
    });

    this.updateState(JSON.stringify(state));
  }
}

const isQuestionCorrect = question => {
  const answers = Object.values(question.answers).map(answer => isAnswerCorrect(answer));
  return answers.every(answer => answer);
}

const isAnswerCorrect = answer => {
  switch(answer.type) {
    case 'ranking':
      return answer.position == answer.correct_position;
    case 'linked':
      return answer.answer_twin_id == answer.correct_answer_twin_id;
    default:
      return answer.selected == answer.correct;
  }
}

const displayRightIconFor = (question_id, questionIsCorrect) => {
  const iconToRemove = questionIsCorrect ? 'times' : 'check';
  document.querySelector(`#question_resource_${question_id} .fa-${iconToRemove}-circle`).classList.add('d-none');
};

const displaySelectedAnswerFor = question_attributes => {
  Object.entries(question_attributes.answers).forEach(([answer_id, answer_attributes]) => {
    if (!answer_attributes.selected) {
      // hide 'selected answer' text which is displayed by default
      document.querySelector(`#answer_resource_${answer_id} .selected`).classList.add('d-none');
    }
  });
};

const displayRankingAnswersFor = (question, questionIsCorrect) => {
  if (!questionIsCorrect) {
    Object.entries(question.answers).forEach(([answer_id, answer_attributes]) => {
      // hide the incorrect icon
      const iconToRemove = answer_attributes.content == answer_attributes.current_content ? 'times' : 'check';
      document.querySelector(`#answer_resource_${answer_id}[data-result] .fa-${iconToRemove}-circle`).classList.add('d-none');
      // change the 'your answer' by the current answer in this position
      document.querySelector(`#answer_resource_${answer_id}[data-result] [data-current-content]`).innerText = answer_attributes.current_content;
    })
  } else {
    Object.entries(question.answers).forEach(([answer_id, _answer_attributes]) => {
      // hide 'your answer' when question is correct
      document.querySelector(`#answer_resource_${answer_id}[data-result] [data-your-answer]`).classList.add('d-none');
    })
  }
};

const displayLinkedAnswersFor = question_attributes => {
  const allTrue = isAllAnswerTrue(question_attributes);
  Object.entries(question_attributes.answers).forEach(([id, answer_attributes]) => {
    const answerIsCorrect = answer_attributes.correct_answer_twin_id == answer_attributes.answer_twin_id;
    const resultEl = document.getElementById(`result-${id}`);
    if (allTrue) {
      resultEl.remove();
    } else {
      if (answer_attributes.answer_twin_id) {
        const iconToHide = answerIsCorrect ? 'times-circle' : 'check-circle';
        resultEl.querySelector(`.fa-${iconToHide}`).classList.add('d-none');
        resultEl.querySelector('span.text-bold').innerText = document.getElementById(`answer_resource_twin_${answer_attributes.answer_twin_id}`).innerText.trim();
      } else {
        resultEl.querySelector(`.fa-check-circle`).classList.add('d-none');
      }
    }
  })
};

// By default the success and error page are displayed
// We update the text with the right score
// We hide the page (success or error) which is not necessary
const displayScore = (score, questions_count) => {
  const minimumScore = +document.getElementById('state').dataset.minimalScore;
  const finalScore = Math.round((score * 100) / questions_count);
  const isQuizSucceed = finalScore >= minimumScore;
  const divToHide = isQuizSucceed ? 'error' : 'success';
  document.getElementById(divToHide).classList.add('d-none');
  let text = document.querySelector(`#${isQuizSucceed ? 'success' : 'error'} .score`).innerHTML;
  text = text.replaceAll('{{{finalScore}}}', finalScore);
  text = text.replaceAll('{{{minimalScore}}}', minimumScore);
  document.querySelector(`#${isQuizSucceed ? 'success' : 'error'} .score`).innerHTML = text;
};

const isAllAnswerTrue = question_attributes => {
  return Object.entries(question_attributes.answers)
               .map(answer => answer[1].answer_twin_id == answer[1].correct_answer_twin_id)
               .every(val => val == true);
};
