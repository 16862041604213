import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

const selectAnswerAction = 'click->desktop--onboardee--linked-questions#selectAnswer';
const unselectAnswerAction = 'click->desktop--onboardee--linked-questions#unselectAnswers';
const strokeColor = 'var(--custom-color-primary)';
const strokeWidth = '4';

// the selected value is a string that contains the id of the answers we want to link to each other
// for the first click it will be something like 'answer_1' or 'answer_twin_1'
// for the second click, in case the click is in the same column as the first one, we replace the value by the new one 'answer_1' becomes 'answer_2'
// if we click on the opposite column in order the make the link we add the new id value,
// then we have a selected value like 'answer_1 answer_twin_2' that means link 'answer_1' with 'answer_twin_2'
export default class extends Controller {
  static targets = ['selectText', 'associateText']
  static values = { selected: String, url: String }

  selectAnswer(event) {
    this.selectedValue += this.selectedValue.length >= 1 ? ` ${event.currentTarget.id}` : `${event.currentTarget.id}`;
    addSelectedClass(document.getElementById(event.currentTarget.id));
    const answers = this.selectedValue.split(' ');

    // in case the second option clicked is on the same column as the first, we remove the first choice
    // we consider that the user wants to change his first choice
    if (isClickedOnSameColumn(answers)) {
      const removedAnswerId = answers.shift();
      if (answers[0] != removedAnswerId) { removeSelectedClass(document.getElementById(removedAnswerId)) };
      this.selectedValue = event.currentTarget.id;
    }

    // the first click we change texts instruction and gray other options
    // and once the user has selected the second options in the other column
    // we update the answer_twin to make the association and revert the texts instruction
    if (answers.length == 1) {
      addGrayToOthers(event.currentTarget);
      hide(this.selectTextTarget);
      show(this.associateTextTarget);
    } else {
      const answerId = answers.filter(el => !el.includes('answer_twin'))[0];
      const answerTwinId = answers.filter(el => el.includes('answer_twin'))[0];
      if (!answerId || !answerTwinId) { return };

      fetch(this.urlValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          question: {
            type: 'linked',
            answer_id: answerId.split('_')[1],
            answer_twin_id: answerTwinId.split('_')[2],
          }
        })
      })
      this.selectedValue = '';
      const answerElement = document.getElementById(`answer_${answerId.split('_')[1]}`)
      const answerTwinElement = document.getElementById(`answer_twin_${answerTwinId.split('_')[2]}`);
      linkAnswers(answerElement, answerTwinElement);
      hide(this.associateTextTarget);
      show(this.selectTextTarget);
    }
  }

  drawExistingAnswers(questionId) {
    [...document.querySelectorAll(`#${questionId} div[data-answer-id]`)].forEach(el => {
      const answerEl = document.getElementById(`answer_${el.dataset.answerId}`);
      const answerTwinEl = document.getElementById(`answer_twin_${el.dataset.answerTwinId}`);
      if (answerEl.nextElementSibling.children.length < 1) {
        bullet(answerEl).classList.remove('invisible')
        bullet(answerTwinEl).classList.remove('invisible')
        drawLine(answerEl, answerTwinEl);
      }
    })
  }

  unselectAnswers(event) {
    const isTwin = event.currentTarget.id.includes('twin');
    const answerId = isTwin ? event.currentTarget.id.split('_')[2] : event.currentTarget.id.split('_')[1];
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        question: {
          type: 'linked',
          unselect: true,
          [isTwin ? 'answer_twin_id' : 'answer_id']: answerId
        }
      })
    }).then(response => response.json())
      .then(({answer_id, answer_twin_id}) => {
        const answerElement = document.getElementById(`answer_${answer_id}`);
        const answerTwinElement = document.getElementById(`answer_twin_${answer_twin_id}`)
        unlinkAnswers(answerElement, answerTwinElement);
      })
  }
}

// the 2 functions belows only concern the front
const linkAnswers = (answerElement, answerTwinElement) => {
  [answerElement, answerTwinElement].forEach(answer => {
    removeSelectedClass(answer);
    bullet(answer).classList.remove('invisible');
    addSelectedClass(bullet(answer));
    answer.setAttribute('data-action', unselectAnswerAction);
    removeGrayTexts(answer);
  });
  drawLine(answerElement, answerTwinElement);
};


const unlinkAnswers = (answerElement, answerTwinElement) => {
  [answerElement, answerTwinElement].forEach(answer => {
    removeSelectedClass(answer);
    removeSelectedClass(bullet(answer));
    bullet(answer).classList.add('invisible')
    answer.setAttribute('data-action', selectAnswerAction)
  });
  const svg = bullet(answerElement).querySelector('svg');
  if (svg) { svg.remove() };
};

export const drawLine = (answerEl, answerTwinEl) => {
  const answerBullet = bullet(answerEl);
  const answerTwinBullet = bullet(answerTwinEl);
  const answerBulletAttributes = answerBullet.getBoundingClientRect();
  const answerTwinBulletAttributes = answerTwinBullet.getBoundingClientRect();
  addSelectedClass(answerBullet);
  addSelectedClass(answerTwinBullet);
  const bulletSize = answerBullet.offsetHeight;
  const attributes = {
    bulletSize: bulletSize,
    height: Math.abs(answerBulletAttributes.y - answerTwinBulletAttributes.y) + bulletSize,
    width: Math.abs(answerBulletAttributes.x - answerTwinBulletAttributes.x) + bulletSize,
    toRotate: answerBulletAttributes.y > answerTwinBulletAttributes.y
  }
  answerBullet.insertAdjacentHTML('beforeend', svg(attributes));
};

const svg = attributes => {
  return `
    <svg height="${attributes.height}"
         width="${attributes.width}"
         style="position: absolute; top: 0; left: 0; transform: rotateX(${attributes.toRotate ? 180 : 0}deg); margin-top: ${attributes.toRotate ? -(attributes.height - attributes.bulletSize) : 0}px;">
      <line x1="${attributes.bulletSize / 2}" y1="${attributes.bulletSize / 2}"
            x2="${attributes.width - (attributes.bulletSize / 2)}" y2="${attributes.height - (attributes.bulletSize / 2)}"
            style="stroke:${strokeColor};stroke-width:${strokeWidth}" />
    </svg>
  `;
}

const isClickedOnSameColumn = answers => {
  if (answers.length < 2) { return false };

  const answer1 = answers[0];
  const answer2 = answers[1];
  return (answer1.includes('answer_twin') && answer2.includes('answer_twin')) ||
    (!answer1.includes('answer_twin') && !answer2.includes('answer_twin'))
};

// return the bullet point associated to the answer
// we have to handle with the 'resource' in the id add the bullet function is used in the draw function, used in the settings
const bullet = element => {
  const isTwin = element.id.includes('answer_twin') || element.id.includes('answer_resource_twin');
  return isTwin ? element.previousElementSibling : element.nextElementSibling
};

const addGrayToOthers = element => {
  const answers = [...element.parentElement.parentElement.querySelectorAll('.answer-card')];
  const answerToGray = answers.filter(answer => !answer.classList.contains('selected'));
  answerToGray.forEach(el => el.classList.add('text-gray'));
  element.classList.remove('text-gray');
};

const removeGrayTexts = element => {
  const answers = [...element.parentElement.parentElement.querySelectorAll('.answer-card')];
  answers.forEach(el => el.classList.remove('text-gray'));
};

const hide = element => {
  element.classList.remove('d-flex')
  element.classList.add('d-none')
}

const show = element => {
  element.classList.remove('d-none')
  element.classList.add('d-flex')
}

const addSelectedClass = element => element.classList.add('selected');
const removeSelectedClass = element => element.classList.remove('selected');
