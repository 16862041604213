import {TabulatorFull as Tabulator} from 'tabulator-tables';
import { displayUnscopedEnvironmentMessage, unitOrganizationSorter } from 'components/tabulator/utils';

export function loadTabulatorInformations(resources, config = { environment: true }) {
  const initialSort = JSON.parse(localStorage.getItem('informations_sorters'));
  const translations = JSON.parse(document.getElementById('informations').parentElement.dataset.translations);
  const tabulator = new Tabulator('#informations', {
    responsiveLayout: 'hide',
    initialSort: initialSort || [{ column: 'name', dir: 'asc' } ],
    pagination: 'local',
    paginationCounter: function(pageSize, currentRow) {
      return currentRow + ' - ' + (currentRow + pageSize - 1);
    },
    paginationSize: 25,
    paginationButtonCount: 0,
    movableColumns: false,
    placeholder: blankState(),
    data: resources,
    layout: 'fitColumns',
    langs: { 'fr-fr': {} },
    columns: [
      { field: 'id', visible: false },
      { field: 'url', visible: false },
      { field: 'destroy_url', visible: false },
      { field: 'form_resource_ids', visible: false },
      { field: 'type', visible: false },
      { field: 'type_label', visible: false },
      { field: 'updated_at_value', visible: false },
      { field: 'validation', visible: false },
      { title: translations['title'], field: 'name', minWidth: 200, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['environment'], field: 'full_unit_organization_names', visible: false, download: true },
      { title: translations['environment'], sorter: unitOrganizationSorter, field: 'multiple_unit_organizations', visible: config['environment'], width: 200, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, download: false },
      {
        title: translations['last_modification'], field: 'updated_at', width: 160, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false,
        sorter: (_a, _b, aRow, bRow, _column, _dir, _sorterParams) => {
          return parseInt(aRow.getData().updated_at_value) - parseInt(bRow.getData().updated_at_value);
        }
      },
      { title: 'ID', field: 'resource_id', visible: true, maxWidth: 10, vertAlign: 'middle', hozAlign: 'left' },
      { title: translations['external_id'], field: 'external_id', width: 150, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['resources'], field: 'form_resource_count', width: 100, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, sorter: 'number' }
    ],
    rowFormatter: function (row) {
      var data = row.getData();
      var rowEl = row.getElement();
      renderHtmlFor(rowEl, data);
    },
    downloadConfig: { columnHeaders: true }
  });
  const locale = document.querySelector('html').lang
  tabulator.on('dataSorted', function (sorters, rows) {
    const saved_sorters = [];
    sorters.forEach((sorter) => {
      saved_sorters.push({ column: sorter.column.getField(), dir: sorter.dir });
    });
    localStorage.setItem('informations_sorters', JSON.stringify(saved_sorters));
  });
  setTimeout(() => {
    tabulator.setLocale(`${locale}-${locale}`);
    updateInformationsCount();
    updateFilterCounts();
  }, 100);
}

function renderHtmlFor(row, data) {
  // the following object is used to render the html for the row
  // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
  // the values are the attributes to be replaced in the html, matching the properties of the data we receive
  const matchingObj = {
    name: ["name", "type_label", "validation"],
    multiple_unit_organizations: ["full_unit_organization_count", "unit_organization_names", "unit_organization_names", "resource_id", "resource_type"],
    updated_at: ["updated_at"],
    resource_id: ["resource_id", "resource_id"],
    external_id: ["external_id", "external_id"],
    form_resource_count: ["form_resource_count", "destroy_url", "name"]
  };

  const canEditSharedResources = document.querySelector('#setting-content').dataset.sharedAuthorization == 'true';
  const canEditEnvResources = document.querySelector('#setting-content').dataset.envAuthorization == 'true';

  const userScope = JSON.parse(document.querySelector('#setting-content').dataset.envIds);
  const resourceScope = data.full_unit_organization_ids;
  const unscopedUnitOrganizationCount = resourceScope.filter((x) => !userScope.includes(x)).length
  const authorizedScope = unscopedUnitOrganizationCount === 0;

  const sharedResource = data.full_unit_organization_ids.length === 0;
  const singleEnvResource = data.unit_organization_ids.length === 1;

  let editAuthorization = false;
  Object.keys(matchingObj).forEach(templateId => {
    let selectedTemplateId = templateId;

    if (templateId === 'multiple_unit_organizations') {
      if (sharedResource || singleEnvResource) { selectedTemplateId = 'single_unit_organization'; }

    } else if (templateId === 'form_resource_count') {
      if (sharedResource && !canEditSharedResources) { selectedTemplateId = 'form_resource_count_no_edit'; }

      else if (!sharedResource && !canEditEnvResources) { selectedTemplateId = 'form_resource_count_no_edit'; }

      else if (!sharedResource && canEditEnvResources && !authorizedScope) { selectedTemplateId = 'form_resource_count_no_edit'; }

      else { editAuthorization = true; }
    }

    let finalHtml = document.getElementById(selectedTemplateId).innerHTML;
    finalHtml = displayUnscopedEnvironmentMessage(finalHtml, singleEnvResource, unscopedUnitOrganizationCount)
    matchingObj[templateId].forEach(attribute => {
      finalHtml = finalHtml.replaceAll(`{{${attribute}}}`, escapeHtml(data[attribute]));
    });
    row.querySelector(`[tabulator-field="${templateId}"]`).innerHTML = finalHtml;
  });
  row.dataset.edit = editAuthorization;
  row.dataset.url = data.url;
  row.dataset.shared = sharedResource;
  row.classList.add('d-flex', 'align-items-center', 'padded-vertical-10px');
  if (editAuthorization) { row.classList.add('pointer') }
}

export function applyFilter() {
  const tabulator = Tabulator.findTable('#informations')[0];

  let type_filters = localStorage.getItem('informations_types');
  let types = ['none'];
  if (type_filters) {
    types = JSON.parse(type_filters);
    types.push('none');
  }

  let unit_organization_filters = localStorage.getItem('informations_environments');
  let unit_organization_ids = ['none'];
  if (unit_organization_filters) {
    unit_organization_ids = JSON.parse(unit_organization_filters);
    unit_organization_ids.push('none');
  }

  let resource_filters = localStorage.getItem('informations_resources');
  let resources_ids = [];
  if (resource_filters) {
    resources_ids = JSON.parse(resource_filters);
    resources_ids.push('none');
  }

  const query = localStorage.getItem('informations_query') || '';

  let filters = [
    { field: 'name', type: 'like', value: query.trim(), matchAll: true },
    { field: 'type', type: 'in', value: types.map(Number) }
  ];

  tabulator.setFilter(filters);
  if (unit_organization_filters) {
    tabulator.addFilter(unitOrganizationFilter, { unit_organization_ids: unit_organization_ids });
  }
  tabulator.addFilter(resourceFilter, { form_resource_ids: resources_ids });
  updateInformationsCount();
  updateFilterCounts();
}

export function updateFilterCounts() {
  const tabulator = Tabulator.findTable('#informations')[0];
  updateTypeFilterCounts(tabulator, filters('type-filter'));
  updateEnvironmentFilterCounts(tabulator, filters('environment-filter'));
  updateResourcesCount(tabulator, filters('resource-filter'));
}
function updateTypeFilterCounts(tabulator, filters) {
  filters.forEach(filter => {
    let dataCountEl = filter.querySelector('span[data-count]')
    toggleCounter(filter, dataCountEl)
    let value = filter.dataset.value;
    var count = tabulator.getData('active').filter(el => el.type == value).length;
    dataCountEl.innerText = `(${count})`
  })
}
function updateEnvironmentFilterCounts(tabulator, filters) {
  filters.forEach((filter) => {
    let value = parseInt(filter.dataset.value);
    let dataCount = filter.querySelector('span[data-count]')
    var count = () => {
      if (value == 0) {
        // Shared
        return tabulator.getData('active').filter((el) => { return el.unit_organizations_count == 0 }).length;
      } else {
        return tabulator.getData('active').filter((el) => { return el.unit_organization_ids.includes(value) }).length;
      }
    };
    dataCount.innerText = `(${count()})`
  })
}
function updateResourcesCount(tabulator, filters) {
  filters.forEach((filter) => {
    let value = parseInt(filter.dataset.value);
    let dataCountEl = filter.querySelector('span[data-count]')
    toggleCounter(filter, dataCountEl)
    let count = 0
    if (value == 0) {
      // Without template
      count = tabulator.getData('active').filter(el => el.form_resource_count == 0).length;
    } else {
      // With template
      count = tabulator.getData('active').filter(el => el.form_resource_ids.includes(value)).length;
    }
    dataCountEl.innerText = `(${count})`
  })
}
function filters(type) {
  return document.querySelectorAll(`[data-action="click->desktop--actor--account--information--${type}#filter"]`)
}
function toggleCounter(filter, dataCountEl) {
  const isSelected = filter.getAttribute('data-selected') === 'true'
  isSelected ? dataCountEl.classList.remove('d-none') : dataCountEl.classList.add('d-none')
}

export function updateInformationsCount() {
  const tabulator = Tabulator.findTable('#informations')[0];
  const count = tabulator.getData('active').length;
  const labelElement = document.getElementById('informations-label');
  const label = count <= 1 ? labelElement.dataset.singular : labelElement.dataset.plural
  document.getElementById('informations-count').innerText = count;
  document.getElementById('informations-label').innerText = label;
}

// custom filter to check if the form_resource's paperwork are in the one we want to display
function resourceFilter(data, filterParams) {
  return data.form_resource_ids.map(Number).some(el => filterParams.form_resource_ids.map(Number).includes(el))
}

function blankState() {
  return document.querySelector(`[data-code="no_resources_loaded"]`).innerHTML;
}

function escapeHtml(text) {
  if (typeof (text) !== 'string') { return text };

  return text.replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function unitOrganizationFilter(data, filterParams) {
  let data_unit_organization_ids = data.unit_organization_ids;
  if (data_unit_organization_ids.length == 0) {
    data_unit_organization_ids = [0];
  }
  let filter_unit_organization_ids = filterParams.unit_organization_ids.map(Number);
  return data_unit_organization_ids.some(item => filter_unit_organization_ids.includes(item));
}
