import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'
import { applyResourcesFilters } from 'components/tabulator/apply_filters'
import { search } from '../../../../../utils/search';
import { setFiltersWithLocalStorage, updateFilter, selectAllElements, removeAllElements,
         toggleActive, activate, desactivate } from './utils';

export class BaseResourcesController extends Controller {
  // define in children
  static targets = []

  connect() {
    // define in children to call the right elements
    this.defineName()
    useClickOutside(this);
    setFiltersWithLocalStorage(this.singularName, this.menuTarget)
    toggleActive(this.singularName, this.selectTarget);
  }

  filter(event) {
    const current_value = event.currentTarget.dataset.selected == 'true';
    event.currentTarget.dataset.selected = !current_value;
    updateFilter(this.pluralName, this.menuTarget);
    applyResourcesFilters();
    toggleActive(this.singularName, this.selectTarget);
  }

  selectAll() {
    desactivate(this.selectTarget);
    selectAllElements(this.singularName);
    updateFilter(this.pluralName, this.menuTarget);
    applyResourcesFilters();
  }

  removeAll() {
    activate(this.selectTarget);
    removeAllElements(this.singularName);
    updateFilter(this.pluralName, this.menuTarget);
    applyResourcesFilters();
  }

  toggleMenu() {
    const current_state = this.selectTarget.parentElement.dataset.open == 'true';
    this.selectTarget.parentElement.dataset.open = !current_state;
  }

  closeMenu() {
    this.selectTarget.parentElement.dataset.open = false;
  }

  reset() {
    this.closeMenu();
    this.selectAll();
  }

  search() {
    search(this.searchTarget.value, [...document.querySelectorAll(`.${this.singularName}-filter`)]);
  }
}
