import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    $(this.element).sortable({
      update: () => {
        $.ajax({
          url: this.urlValue,
          type: "POST",
          dataType: 'script',
          data: $(this.element).sortable('serialize'),
        });
      }
    });
  }
}
