import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { activateScrolling } from 'pages/sidebar_utils'

export default class extends Controller {
  static targets = ['sidebar']
  static values = {
    graph: Object
  }

  showSidebar() {
    this.setPlaceholder();
    this.loadContent();
    this.displaySidebar();
  }

  setPlaceholder() {
    this.sidebarTarget.innerHTML = document.getElementById('placeholder-sidebar').innerHTML;
  }

  loadContent() {
    $.ajax({
      url: this.graphValue.url,
      type: 'post',
      data: {
        filters: JSON.parse(document.querySelector('[data-desktop--hr--chart-filters-filters-value]').dataset['desktop-Hr-ChartFiltersFiltersValue']),
        graph: this.graphValue
      },
      dataType: 'json'
    }).then((data) => {
      this.sidebarTarget.innerHTML = data.content;
      if (data.is_empty) {
        document.getElementById('tracks').innerHTML = document.getElementById('graph-blank-state').innerHTML;
        document.getElementById('tracks').classList.add('d-flex', 'justify-content-center', 'margin-top-30px');
        document.getElementById('download_csv').remove();
      }
    });
  }

  displaySidebar() {
    if (this.sidebarTarget.getAttribute('activated') == 'false') {
      this.sidebarTarget.setAttribute('activated', 'true');
      document.body.classList.add('overflow-hidden');
      addSidebarBackdrop();
    }
  }

  hideSidebar() {
    const hrSidebar = document.getElementById('hr-sidebar');

    if (this.sidebarTarget.getAttribute('activated') == 'true') {
      this.sidebarTarget.setAttribute('activated', 'false');
      activateScrolling();
      document.querySelector('.large-sidebar-backdrop[data-action="click->desktop--hr--charts--sidebar#hideSidebar"]').remove();
      if (hrSidebar) { hrSidebar.setAttribute('activated', 'false') };
    }
  }

  setControllerValues(event) {
    const datasets = event.currentTarget.dataset
    this.element.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
      { "url": "${datasets.url}",
        "pil": "${datasets.timing}" }
    `);
    this.showSidebar();
  }

  downloadCsv(event) {
    const button = event.currentTarget
    button.setAttribute('disabled', 'true');

    fetch(button.dataset.url, {
      headers: { 'X-CSRF-Token': csrfToken(), 'Content-Type': 'application/json' },
      method: 'post',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        filters: JSON.parse(document.querySelector('[data-desktop--hr--chart-filters-filters-value]').dataset['desktop-Hr-ChartFiltersFiltersValue']),
        graph: this.graphValue
      }),
      dataType: 'html'
    }).then(res => res.blob())
      .then(res => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', 'export_' + Date.now() + '.csv');
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      }
    );
  }
}

const addSidebarBackdrop = () => {
  const backdropEl = document.createElement('div');
  backdropEl.classList.add('large-sidebar-backdrop');
  backdropEl.setAttribute('data-action', 'click->desktop--hr--charts--sidebar#hideSidebar')
  document.querySelector('div[data-controller*="desktop--hr--charts--sidebar"]').appendChild(backdropEl);
};
