import { BaseResourcesController } from "./base_resources_controller";

export default class extends BaseResourcesController {
  static targets = ['select', 'menu']

  defineName() {
    this.pluralName = 'types';
    this.singularName = 'type';
  }
}
