// This controller manages the change of answer_type for a QuestionResource.
// AnswerResources inputs are specific to answer_type hence should be displayed or hidden depending on the answer_type
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['typeSelector', 'list', 'answers', 'description', 'selectionDescription', 'rankingDescription', 'linkedDescription']
  static values = { type: String }

  change() {
    let newType = this.typeSelectorTarget.value;
    this.typeValue = newType;
    if (newType != 'ranking') { this.resetCorrectInput() };
    this.displayDescription(newType)
  }

  resetCorrectInput() {
    [...this.listTarget.children].filter((el) => { return (el.tagName == 'DIV') }).forEach((el) => {
      el.querySelector('.switch-input').checked = false;
    })
  }

  displayDescription(type) {
    this.descriptionTargets.forEach(el => el.classList.add('d-none'))
    this[`${type}DescriptionTarget`].classList.remove('d-none');
  }
}
