import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import { French } from "flatpickr/dist/l10n/fr.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import { Czech } from "flatpickr/dist/l10n/cs.js";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { Slovak } from "flatpickr/dist/l10n/sk.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { Polish } from "flatpickr/dist/l10n/pl.js";

const locales = {
  fr: French,
  en: 'default',
  de: German,
  es: Spanish,
  nl: Dutch,
  ar: Arabic,
  cs: Czech,
  pt: Portuguese,
  sk: Slovak,
  it: Italian,
  pl: Polish
}

// Add 5 values to the html of this controller in order to use the selected dates
// the first :
//   data-utils--flatpickr-identifier-value="desktop--hr--chart-filters" -> refers to the controller we want to use
// the second :
//   data-utils--flatpickr-callback-value="updateDates" -> is the method you want to call in the controller defined before
// the third :
//   data-utils--flatpickr-options-value='{"mode":"range", "dateFormat":"d M Y", "showMonths": 2, "defaultDate": []}' -> is the options to build the datepicker
// the fourth :
//   data-utils--flatpickr-selector-value="definedDaysFrom"
// the fifth, if you want a range :
//   data-utils--flatpickr-range-value="definedDaysTo"
export default class extends Controller {
  static values = {
    identifier: String,
    callback: String,
    selector: String,
    range: String,
    options: Object
  }

  connect() {
    const selector = this.selectorValue;
    flatpickr(`#${selector}`, {
      ...this.basicOptions(),
      ...this.functionOptions(),
      ...this.rangeOptions()
    });
  }

  basicOptions() {
    const options = this.optionsValue;
    if (options.defaultDate) {
      options.defaultDate = [localeDateFor(options.defaultDate[0]), localeDateFor(options.defaultDate[1])];
    }
    return {
      mode: options.mode,
      dateFormat: options.dateFormat,
      locale: locales[document.querySelector('html').lang || 'en'],
      showMonths: options.showMonths,
      defaultDate: options.defaultDate || null
    }
  }

  functionOptions() {
    const identifier = this.identifierValue;
    const callback = this.callbackValue;
    return {
      onChange: function(dates, _startDate, _obj) {
        displayHiddenDays();
        if (dates.length == 2) {
          Stimulus.controllers.find(el => el.identifier == identifier)[callback](dates)
        }
      },
      onOpen: function(_selectedDates, _dateStr, _instance) {
        displayHiddenDays();
      },
      onMonthChange: function() {
        displayHiddenDays();
      },
      onYearChange: function() {
        displayHiddenDays();
      }
    }
  }

  rangeOptions() {
    if (this.hasRangeValue) {
      const range = this.rangeValue;
      return {
        plugins: [new rangePlugin({ input: `#${range}`})]
      }
    }
  }
}

// display other month day, otherwise all start the same day
const displayHiddenDays = () => {
  [...document.querySelectorAll('.flatpickr-day.nextMonthDay')].forEach(el => el.classList.remove('hidden'));
  [...document.querySelectorAll('.flatpickr-day.prevMonthDay')].forEach(el => el.classList.remove('hidden'));
}

const localeDateFor = date => {
  const lang = document.querySelector('html').lang || 'en';
  return moment(date).locale(lang).format('DD MMM Y').replaceAll('.', '')
};
