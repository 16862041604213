import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['list', 'nextButton', 'pagination', 'blankState'];
  static values = { url: String };

  connect() {
    let url = setParamsToPath(this.urlValue, { "content_type": "start" })
    this.fetchActions(url, this.replaceList.bind(this));
  }

  addNextActions() {
    let next_button = this.paginationTarget.querySelector("a[rel='next']");

    if (readyToLoad(next_button, this.listTarget.dataset.status)) {
      this.setListAsLoading();
      let url = setParamsToPath(this.urlValue, {
        "page": (new URL(next_button.href, 'https://exemple.com')).searchParams.get('page'),
        "content_type": "next"
      })
      this.fetchActions(url, this.appendActions.bind(this));
    }
  }

  fetchActions(url, callback) {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      callback(data);
      this.updateCounters.bind(this)(data.count)
    }).catch(error => {
      console.log(error);
    })
  }

  replaceList(data) {
    if (data.count == 0) {
      this.replaceByEmptyList();
    } else {
      this.listTarget.outerHTML = data.content;
      this.observer = new MutationObserver(this.listenListMutation.bind(this));
      this.observer.observe(this.listTarget, { childList: true });
    }
  }

  replaceByEmptyList() {
    let grandParentNode = this.listTarget.parentNode.parentNode;
    if (grandParentNode.hasAttribute('track')) {
      grandParentNode.remove();
    }
    this.updateCounters(0);
  }

  appendActions(data) {
    const list = this.listTarget;
    list.querySelector("div[data-tag='placeholder']").remove();
    list.insertAdjacentHTML('beforeend', data.content);
    this.paginationTarget.innerHTML = data.pagination;
    list.dataset.status = 'ready';
    this.removeNextButton();
  }

  setListAsLoading() {
    const list = this.listTarget;
    this.nextButtonTarget.classList.add('d-none');
    this.nextButtonTarget.classList.remove('d-flex');
    list.dataset.status = 'loading';
    waitingList(list);
  }

  removeNextButton() {
    if (this.hasNextButtonTarget && !this.paginationTarget.querySelector("a[rel='next']")) {
      this.nextButtonTarget.classList.add('d-none');
      this.nextButtonTarget.classList.remove('d-flex');
    } else if (this.hasNextButtonTarget) {
      this.nextButtonTarget.classList.add('d-flex');
      this.nextButtonTarget.classList.remove('d-none');
    }
  }

  listenListMutation(mutationsList) {
    if (this.listTarget.childElementCount === 0) {
      this.replaceByEmptyList();
    } else {
      mutationsList.forEach(({ type, removedNodes }) => {
        let removedActions = [...removedNodes].filter(node => node.hasAttribute('action'))
        if (type === 'childList' && removedActions.length >= 1) { this.addReplacementAction(); }
      });
    }
  }

  addReplacementAction() {
    let next_button = this.paginationTarget.querySelector("a[rel='next']");

    if (readyToLoad(next_button, this.listTarget.dataset.status)) {
      this.setListAsLoading();
      const currentPageElement = this.paginationTarget.querySelector('.current')
      const currentPage = (currentPageElement) ? parseInt(currentPageElement.innerText, 10) : 1
      let url = setParamsToPath(this.urlValue, {
        "page": currentPage,
        "content_type": "replacement"
      })
      this.fetchActions(url, this.appendActions.bind(this));
    } else {
      let count = parseInt(this.listTarget.dataset.actionsCount);
      this.updateCounters(count - 1);
    }
  }

  updateCounters(count) {
    this.listTarget.dataset.actionsCount = count;
    updateGlobalCounter();
    this.resetBlankState();
  }

  resetBlankState () {
    const element = document.getElementById('main')
    // Retrieving the controller you wish to restart
    const controller = this.application.getControllerForElementAndIdentifier(element, 'utils--blank-states');

    // Check that the controller exists
    if (controller) {
      // reset the controller
      controller.disconnect();
      controller.connect();
    }
  }
}

function setParamsToPath(path, params) {
  const url = new URL(path, 'https://exemple.com');
  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key]);
  })

  return `${url.pathname}${url.search}`
}

// insert waiting spinner inside list
function waitingList(list) {
  list.insertAdjacentHTML('beforeend', '<div data-tag="placeholder" class="ph-item border-none no-padding"><div class="ph-picture margin-bottom-20px" style="height: 80px; border-radius: 6px;"></div></div>');
}

function readyToLoad(next_button, status) {
  return (next_button && (status == 'ready'))
}

function updateGlobalCounter() {
  const countElements = document.querySelectorAll('div[data-utils--counters-count-value]');

  if (countElements.length > 0) {
    // Retrieve all elements with a data-actions-count attribute
    const elements = document.querySelectorAll('[data-actions-count]');
    // Initialization of the variable to store the sum
    let sum = 0;
    // Loop over each element to add its value to the sum
    elements.forEach((element) => {
      const value = parseInt(element.getAttribute('data-actions-count'));
      if (!isNaN(value)) {
        sum += value;
      }
    });
    // Update global counter
    countElements.forEach((el) => { el.dataset['utils-CountersCountValue'] = sum.toString(); })
  }
}
