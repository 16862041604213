import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { escapeHtml } from './utils';

export function loadTabulatorActions(actions, config = { environment: true }) {
  const translations = JSON.parse(document.getElementById('actions').parentElement.dataset.translations);
  const tabulator = new Tabulator('#actions', {
    responsiveLayout: 'hide',
    initialSort: [{ column: 'name', dir: 'asc' } ],
    layout: 'fitColumns',
    pagination: false,
    paginationSize: 0,
    paginationButtonCount: 0,
    movableColumns: false,
    data: actions,
    langs: { 'fr-fr': {} },
    columns: [
      { field: 'bulk_select', width: 98, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', headerSort: false, titleFormatter: customHeader, formatter: 'html'},
      { title: '', field: 'type',  vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, visible: false },
      { title: translations['name'], widthGrow: 4, field: 'name', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['type'], widthGrow: 1, field: 'type_name', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['collaborator_name'], widthGrow: 2, field: 'collaborator_name', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      {
        title: translations['due_date'], widthGrow: 1, field: 'due_date', vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false,
        sorter: (_a, _b, aRow, bRow, _column, _dir, _sorterParams) => {
          let aDate = moment(aRow.getData().due_date, 'DD/MM/YYYY').valueOf();
          let bDate = moment(bRow.getData().due_date, 'DD/MM/YYYY').valueOf();

          if (aDate === 1 && bDate === 1) {
            return 0;
          } else {
            return aDate - bDate;
          }
      }
     },
    ],
    rowFormatter: (row) => {
      var data = row.getData();
      var rowEl = row.getElement();
      renderHtmlFor(rowEl, data, tabulator);
    }
  });
  const locale = document.querySelector('html').lang
  setTimeout(() => {
    tabulator.setLocale(`${locale}-${locale}`);
    updateInformationsCount();
  }, 200);
}

const renderHtmlFor = (row, data, tabulator) => {
  let template_id = 'bulk_select';
  let finalHtml = document.getElementById(template_id).innerHTML;
  let bulk_select_cell = row.querySelector(`[tabulator-field="${template_id}"]`)

  if (bulk_select_cell) {
    var position = tabulator.getRowPosition(row);
    finalHtml = finalHtml.replaceAll('{{row_position}}', escapeHtml(position));
    finalHtml = finalHtml.replaceAll('{{resource_id}}', data.id);
    finalHtml = finalHtml.replaceAll('{{resource_type}}', data.type);

    bulk_select_cell.innerHTML = finalHtml;
  }
}
export function updateInformationsCount() {
  const tabulator = Tabulator.findTable('#actions')[0];
  const count = tabulator.getData('active').length;
  const labelElement = document.getElementById('actions-label');
  const label = count <= 1 ? labelElement.dataset.singular : labelElement.dataset.plural
  document.getElementById('actions-count').innerText = count;
  document.getElementById('actions-label').innerText = label;
}


export function customHeader(cell, formatterParams, onRendered){
  return '\
    <div class="bulk-select m-auto d-flex justify-content-center vertical-align-middle">\
      <input data-tabulator-bulk-selection-target="selectAllElement" class="m-auto" type="checkbox" data-action="click->tabulator-bulk-selection#selectAllToogle" />\
    </div>';
};
