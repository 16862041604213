import { Controller } from "@hotwired/stimulus";
import * as applyFilters from '../../../../../javascript/components/tabulator/apply_filters';

export default class extends Controller {
  static targets = ['switch'];
  static values = {
    url: String,
    controller: String
  }
  connect(){
    if (localStorage.getItem(`my_forms_switch`)){
      this.switchTarget.checked = localStorage.getItem(`my_forms_switch`) == 'true';
    } else {
      this.switchTarget.checked = true;
    }
  }

  updateScope() {
    this.updateFilter();
    this.applyFilter();
  }

  updateFilter() {
    localStorage.setItem(`my_forms_switch`, this.switchTarget.checked);
  }

  applyFilter() {
    const functionToCall = `applyFormsFilters`;
    applyFilters[functionToCall]();
  }
}
