import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static values = {
    token: String
  }
  static targets = ['items', 'select', 'option', 'selectedText', 'selectedDescription', 'input']

  connect() {
    useClickOutside(this);
    document.querySelectorAll(`.w-select.w-${this.tokenValue} option`).forEach(el => {
      const newEl = `
        <div class="w-select-item"
             data-action="click->desktop--utils--select-component--select-component#selectOption"
             data-value="${el.value}"
             active="${el.dataset.active == 'true'}">
          <div class="d-flex flex-column justify-content-center align-items-start">
            <span class="w-select-item-text">${el.innerHTML}</span>${description(el)}
          </div>
        </div>
      `
      document.querySelector(`.w-${this.tokenValue} .w-select-items`).insertAdjacentHTML('beforeend', newEl)
    })
  }

  selectOption(event) {
    if (event.currentTarget.getAttribute('active') == 'true') {
      const newValue = event.currentTarget.dataset.value;
      const selectedOption = this.optionTargets.find(el => el.value == newValue);
      this.optionTargets.forEach(option => option.setAttribute('selected', 'false'))
      selectedOption.setAttribute('selected', 'true')
      this.selectTarget.value = newValue;
      this.inputTarget.value = newValue;
      this.selectedTextTarget.innerHTML = selectedOption.innerHTML;
      if (this.hasSelectedDescriptionTarget) {
        this.selectedDescriptionTarget.innerHTML = selectedOption.dataset.description;
      }
    }
    this.close();
  }

  toggle() {
    this._is_open() ? this.close() : this._open();
  }

  close() {
    this.itemsTarget.setAttribute('active', 'false')
  }

  _open() {
    this.itemsTarget.setAttribute('active', 'true')
  }

  _is_open() {
    return this.itemsTarget.getAttribute('active') == 'true';
  }
}

const description = el => {
  return el.dataset.active == 'true' ? displayText(el, 'description') : displayText(el, 'inactiveExplanation')
};

const displayText = (el, attribute) => {
  const text = el.dataset[attribute];
  if (text === '' || text === undefined || text === null) { return '' }

  return `<span class="w-select-item-description">${text}</span>`;
}
