import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = ['actions']

  connect() {
    this.call('ongoing');
  }

  toggleHistory(event) {
    this.call(event.currentTarget.checked ? 'ongoing' : 'done')
  }

  call(listType) {
    $.ajax({
      url: `${this.urlValue}?list_type=${listType}`,
      dataType: 'html',
      success: (html) => {
        this.element.innerHTML = html;
      },
      error: () => {
        // if user's session is expired the server respond with a 401
        // which means that the request failed. We handle the failure in
        // this function which reloads the page, therefore redirecting us
        // to the sign-in page
        window.location.reload();
      }
    });
  }
}
