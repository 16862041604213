// This controller is used to check an call a reverse inherit for the mailing
// IF the switch input is checked
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String }

  call() {
    if (this.inputChecked()) {
      $.ajax({
        url: this.urlValue,
        type: 'DELETE',
        dataType: 'script'
      });
    }
  }

  inputChecked(){
    let input = document.querySelector('input[name=reverse-inherit]')

    return input ? input.checked : false
  }
}
