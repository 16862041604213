import { Controller } from "@hotwired/stimulus"

// This controller handles the delaying of the filter refresh resquests
// How it's works :
//   - At connect, we set an MutationObserver on the controller's blankState
//   - The observer runs its callback when an child element is removed
//   - The callback : display the blank state message
export default class extends Controller  {
  static targets = ['blankState', 'list', 'futurList']
  static values = { url: String, mobility: String }

  connect() {
    this.observer = new MutationObserver(this.displayBlankStateOrNextActions.bind(this));
    this.observer.observe(this.listTarget, { childList: true });
    this.openInitialModal();
  }

  // This method is called when the MutationObserver detect a change in the actions' list
  // There are two possiblities :
  //  - The last action was removed and now the list is empty so we need to show the blankState
  //  - The last action was removed and there are still some actions present, so we make a call to the
  //    next_actions_controller to fetch the next action (to inform the controller that we only want 1 action, we pass
  //    1 as actions_count parameter, by default the controller send 5 actions)
  displayBlankStateOrNextActions(mutationsList) {
    if (this.isListEmpty()) {
      this.showBlankState();
    } else {
      mutationsList.forEach(({ type, removedNodes }) => {
        this.mobilityValue !== 'offboarding' &&
          type === 'childList' &&
          removedNodes.length >= 1 &&
          this.nextActions({ actions_count: 1 });
      });
    }
  }

  showBlankState() {
    this.blankStateTarget.classList.remove('d-none');
    this.blankStateTarget.classList.add('d-flex');
    if (this.mobilityValue === 'offboarding' && this.hasFuturListTarget) {
      this.futurListTarget.classList.remove('d-none');
      this.futurListTarget.classList.add('d-flex');
    }
  }

  nextActions({ actions_count }) {
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'script',
      data: {
        actions_count,
        'iterate_count': this.iterateCount()
      }
    });
  }

  isListEmpty() {
    return this.listTarget.childElementCount === 0;
  }

  iterateCount() {
    return this.listTarget.dataset.iteration || 1;
  }

  disconnect() {
    this.observer.disconnect();
  }

  openInitialModal() {
    let identifier = 'desktop--onboardee--modal';
    let element = document.querySelector('#initial-action');
    if (!element) { return; }

    let controller = Stimulus.controllers.find(controller =>
      controller.context.element === element && controller.context.identifier === identifier
    )
    controller.call();
  }
}
