import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['textarea']
  static values = {
    selector: String,
    apiKey: String,
    language: String,
    user: String,
    attachableType: String,
    attachableId: Number,
    accountColor: String
  }

  connect() {
    let url = `/attachments?attachable_type=${this.attachableTypeValue}&attachable_id=${this.attachableIdValue}`
    let language = this.languageValue == 'fr' ? 'fr_FR' : this.languageValue
    tinymce.init({
      selector: this.selectorValue,
      plugins: 'quickbars fullscreen save preview accordion ai tinycomments autolink codesample emoticons image link advlist lists media searchreplace table visualblocks checklist mediaembed casechange export formatpainter pageembed advtable advcode editimage powerpaste tinymcespellchecker autocorrect a11ychecker inlinecss',
      toolbar: 'fullscreen undo redo | blocks fontfamily fontsizeinput | bold italic underline | alignleft aligncenter alignright alignjustify | indent outdent | numlist bullist',
      quickbars_selection_toolbar: 'bold italic forecolor | quickimage quicklink emoticons',
      quickbars_insert_toolbar: false,
      language: language,
      spellchecker_active: false,
      spellchecker_language: this.languageValue,
      save_enablewhendirty: false,
      statusbar: false,
      advcode_inline: true,
      content_style: "@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,700'); img { max-width: calc(100vw - 2rem); } body { font-family: Lato; color: #2A3453; }",
      font_family_formats: "Lato=lato; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
      color_cols: 3,
      color_map: [
        `${this.accountColorValue}`, 'Account',
        '2A3453', 'Dark blue',
        '2e5bec', 'Blue',
        '000000', 'Black',
        'FFFFFF', 'White',
        'EC0868', 'Raspberry',
        'FA3E3E', 'Red',
        '828282', 'Gray',
        '46CE92', 'Green'
      ],
      extended_valid_elements: 'script[src|async|defer|type|charset],style[*]',
      automatic_uploads: true,
      block_unsupported_drop: true,
      images_upload_url: url,
      file_picker_types: 'image file media',
      file_picker_callback: function (callback, value, meta) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');

        if (meta.filetype == 'file') {
          input.setAttribute('accept', '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt');
        } else if (meta.filetype == 'image') {
          input.setAttribute('accept', 'image/*');
        } else if (meta.filetype == 'media') {
          input.setAttribute('accept', 'video/*,audio/*');
        }

        input.addEventListener('change', function (e) {
          const file = e.target.files[0];

          const formData = new FormData();
          formData.append('file', file);

          fetch(url, {
            method: 'POST',
            body: formData
          })
            .then(response => response.json())
            .then(data => {
              const { location, title } = data;
              callback(location, { title: title, alt: title });
            })
            .catch(error => {
              console.error('Error uploading file:', error);
            });
        });

        input.click();
      },
      tinycomments_mode: 'embedded',
      tinycomments_author: this.userValue,
      ai_shortcuts: [
        { title: 'Résumer', prompt: 'Provide the key points and concepts in this content in a succinct summary - answer must be in french.' },
        { title: 'Améliorer le style', prompt: 'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning - answer must be in french.' },
        { title: 'Simplifier la formulation', prompt: 'Rewrite this content with simplified language and reduce the complexity of the writing, so that the content is easier to understand - answer must be in french.' },
        { title: 'Rallonger', prompt: 'Expand upon this content with descriptive language and more detailed explanations, to make the writing easier to understand and increase the length of the content - answer must be in french.' },
        { title: 'Raccourcir', prompt: 'Remove any repetitive, redundant, or non-essential writing in this content without changing the meaning or losing any key information - answer must be in french.' },
        {
          title: 'Modifier le ton', subprompts: [
            { title: 'Professionnel', prompt: 'Rewrite this content using polished, formal, and respectful language to convey professional expertise and competence - answer must be in french.' },
            { title: 'Décontracté', prompt: 'Rewrite this content with casual, informal language to convey a casual conversation with a real person - answer must be in french.' },
            { title: 'Direct', prompt: 'Rewrite this content with direct language using only the essential information - answer must be in french.' },
            { title: 'Confiant', prompt: 'Rewrite this content using compelling, optimistic language to convey confidence in the writing - answer must be in french.' },
            { title: 'Amical', prompt: 'Rewrite this content using friendly, comforting language, to convey understanding and empathy - answer must be in french.' },
          ]
        },
      ],
      ai_request: (request, respondWith) => {
        const openAiOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKeyValue}`
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            temperature: 0.7,
            max_tokens: 800,
            messages: [{ role: 'user', content: request.prompt }],
          })
        };
        respondWith.string((signal) => window.fetch('https://api.openai.com/v1/chat/completions', { signal, ...openAiOptions })
          .then((response) => response.ok ? response.json() : response.text())
          .then((data) => {
            if (typeof data === 'string') {
              Promise.reject(`Failed to communicate with the ChatGPT API. ${data}`);
            } else if (data.error) {
              Promise.reject(`Failed to communicate with the ChatGPT API because of ${data.error.type} error: ${data.error.message}`);
            } else {
              // Extract the response content from the data returned by the API
              return data?.choices[0]?.message?.content?.trim();
            }
          })
        );
      }
    });
  }

  updateTextarea() {
    let content = tinymce.activeEditor.getContent({ format: 'html' });
    this.textareaTarget.innerHTML = content;
  }

  disconnect() {
    tinymce.remove();
  }
}
