import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startTime', 'endTime', 'startDay', 'endDay']
  static values = { dateFormat: String, timeFormat: String }

  connect() {
    this.increaseEndTime();
    // Timeout because when the datepicker is initialized, it changes the startDay value,
    // and we don't increase the end_date at this time
    setTimeout(() => { this.increaseEndDate(); }, 10);
  }

  increaseEndTime() {
    this.startTimeTarget.onchange = () => {
      let startTimeString = this.startTimeTarget.value;
      let startTime = moment(startTimeString, this.timeFormatValue).toDate();
      let endTime = startTime

      endTime.setHours(startTime.getHours() + 1);
      let endTimeString = moment(endTime).format(this.timeFormatValue);
      this.endTimeTarget.value = endTimeString;
    }
  }

  increaseEndDate(){
    this.startDayTarget.onchange = () => {
      let startDateString = this.startDayTarget.value;
      this.endDayTarget.value = startDateString;
    }
  }
}

