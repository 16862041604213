import SwalController from "./swal_controller";

export default class extends SwalController {
  static values = {
                    text: Object,
                    confirm: Object,
                    cancel: Object,
                    buttonType: String,
                    withoutMandatoryInputs: Boolean,
                    selector: String
                  }

  questions = Array.from(document.querySelectorAll(`[data-selector="${this.selectorValue}"]`))

  shouldFire() {
    return this.withoutMandatoryInputsValue && this.surveyIsEmpty();
  }

  confirm(event) {
    super.confirm(event);
  }

  notFired() {
    this.handleRequest(this.confirmValue);
  }

  surveyIsEmpty() {
    return !this.questions.some(question => this.isFilled(question));
  }

  isFilled(question) {
    if (question.dataset.type === 'free_field') {
      return question.querySelector('textarea').value !== '';
    }

    // If it's not a free_field it's inevitably a multiple / unique or scale question type
    const links = Array.from(question.querySelectorAll('a'));
    return links.some(link => link.classList.contains('selected'));
  }
}
