import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { stringToHTML, htmlToString } from "utils/convert_html";

export default class extends Controller {
  static values = { id: String,
                    saved: Object,
                    default: Object,
                    formResourceUrl: String,
                    newPaperworkResourceUrl: String,
                    editPaperworkResourceUrl: String }
  static targets = ['list', 'input', 'toBeValidatedBox', 'externalIdInput']

  connect() {
    this.loadStorage();
    this.initializeFromStorage();
    this.quitCallback = this.submitOnQuit.bind(this);
    this.updateFullPageTitle();
    document.getElementById('close-button').addEventListener('click', this.quitCallback)
  }

  disconnect() {
    document.getElementById('close-button').removeEventListener('click', this.quitCallback);
  }

  submitOnQuit() {
    // Submit only there's at least one field
    if (document.querySelector('[data-type="paperwork"]')) { this.submit(null) };
    localStorage.removeItem(`form_${this.idValue}`);
  }

  loadStorage() {
    let storage = this.saved() || this.defaultValue;
    setStorage(this.idValue, storage);
  }

  saved() {
    let storage = this.savedValue;
    if (Object.keys(storage).length > 0) { return storage };
  }

  initializeFromStorage() {
    let storage = getStorage(this.idValue);
    this.inputTarget.innerText = storage.name;
    this.externalIdInputTarget.value = storage.external_id;
    if (storage.to_be_validated) { this.toBeValidatedBoxTarget.setAttribute('checked', '') }

    let sortedSection = storage.sections.sort((a, b) => a.position - b.position);
    sortedSection.forEach((section) => { this.addSection(section); });
    if (storage.conditional_logics) { storage.conditional_logics.forEach((conditionalLogic) => {
      this.addConditionalLogic(conditionalLogic);
    }); }

    Object.keys(storage.mobilities).forEach((mobility) => {
      let element = document.querySelector(`[data-mobility="${mobility}"]`);
      if (element) {
        element.dataset.checked = storage.mobilities[mobility];
      }
    });

    document.querySelector('.spinner').classList.add('hidden');
  }

  updateFullPageTitle() {
    let storage = getStorage(this.idValue);
    document.getElementById('full-page-prefix').innerText = this.element.dataset.fullPagePrefix;
    document.getElementById('form-resource-name').innerText = `: ${storage.name}`;
  }

  storeTitle() {
    let storage = getStorage(this.idValue);
    storage.name = this.inputTarget.innerText;

    setStorage(this.idValue, storage);
    this.updateFullPageTitle()
  }

  storeEnv() {
    let storage = getStorage(this.idValue);
    const unitOrganizationIds = document.querySelector('#unit_organization_ids_input')?.value?.split(' ')?.filter(el => !!el) || [];
    storage['unit_organization_ids'] = unitOrganizationIds;

    setStorage(this.idValue, storage);
  }

  updateExternalId() {
    let storage = getStorage(this.idValue);
    storage['external_id'] = this.externalIdInputTarget.value;

    setStorage(this.idValue, storage);
  }

  updateMobility(event) {
    let element = event.target.parentElement;
    let checked = element.dataset.checked == 'true';
    let mobility = element.dataset.mobility;
    element.dataset.checked = !checked;
    let storage = getStorage(this.idValue);
    storage['mobilities'][mobility] = !checked;
    setStorage(this.idValue, storage);
  }

  addConditionalLogic(conditionalLogic) {
    let conditionalLogicHtml = buildConditionalLogicHtml(conditionalLogic)
    document.getElementById('conditional-logic-list').insertAdjacentHTML('beforeend', conditionalLogicHtml);
  }

  addNewConditionalLogic() {
    let newConditionalLogic = newConditionalLogicParams(this.idValue);
    this.addConditionalLogic(newConditionalLogic);
    highlightConditionalLogic(newConditionalLogic.id);
  }

  addSection(section) {
    let id = section['id'];
    let title =  section['title'];
    let description =  section['description'];
    let position = section['position'];
    let iterable = section['iterable'];
    let sectionHtml = buildSectionHtml(id, position, title, description, iterable);
    this.listTarget.insertAdjacentHTML('beforeend', sectionHtml);

    let paperworks = section['paperworks'].sort((a, b) => a.position - b.position);
    if (paperworks.length > 0) {
      paperworks.forEach((paperwork, index) => { this.addPaperwork(id, paperwork, index) });
    }
  }

  addPaperwork(sectionId, paperwork, index) {
    paperwork.position = paperwork.position || index;
    let paperworkHtml = buildPaperworkHtml(paperwork);
    document.querySelector(`.section[id="${sectionId}"] .nested-sortable`).insertAdjacentHTML('beforeEnd', paperworkHtml);
  }

  saveOnPage(event) {
    const button = event.target;
    const text = button.innerText;
    const callback = (responseJSON, _event) => {
      button.innerHTML = text;
      flash(button.dataset.notice);

      // Update URL, if it's a new form
      if (this.idValue == 'new') {
        let url = this.formResourceUrlValue + '/' + responseJSON.id + '/edit';
        window.history.pushState({}, '', url);
      }
      // Reload the form_resource : from new to edition (with all real ids)
      document.querySelector('[data-controller="form-resources"]').outerHTML = responseJSON.form_html;
    }

    // Display spinners and hide sections
    document.querySelector('[data-sortable-type="section"]').classList.add('d-none');
    document.querySelector('.spinner').classList.remove('hidden');
    button.innerHTML = '<i class="fas fa-spinner rotating" aria-hidden="true"></i>';

    this.submit(event, callback);
  }

  submitByNewPaperwork(event) {
    let callback = this.redirectToNewPaperworResourcePage.bind(this)
    this.submit(event, callback);
  }

  submitByEditPaperwork(event) {
    let callback = this.redirectToEditPaperworResourcePage.bind(this)
    this.submit(event, callback);
  }

  submit(event, callback) {
    this.storeEnv();
    if (this.idValue == 'new') {
      this.post(event, callback);
    } else {
      this.patch(event, callback);
    }
  }

  post(event, callback = null) {
    const form = localStorage.getItem('form_new');
    const url = this.formResourceUrlValue;
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      method: 'POST',
      body: form
    })
    .then(response => response.json())
    .then(responseJSON => {
      if (callback) { callback(responseJSON, event) }
    });
    localStorage.removeItem('form_new');
  }

  patch(event, callback = null) {
    const form = localStorage.getItem(`form_${this.idValue}`);
    const url = this.formResourceUrlValue + '/' + this.idValue;
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      method: 'PATCH',
      body: form
    })
    .then(response => response.json())
    .then(responseJSON => {
      if (callback) { callback(responseJSON, event) }
    });
  }

  redirectToNewPaperworResourcePage(responseJSON, event) {
    const section = event.target.closest('[data-type=section][data-position')
    const sectionPosition = positionOf(section);
    const field = event.target.closest('[data-type=paperwork][data-position')
    const fieldPosition = positionOf(field);
    const position = `${sectionPosition}-${fieldPosition}`
    const url = this.newPaperworkResourceUrlValue;

    window.open(`${url}?form_resource=${responseJSON.id}&position=${position}`, '_self')
  }

  redirectToEditPaperworResourcePage(responseJSON, event) {
    const field = event.target.closest('[data-type=paperwork][data-position');
    const paperworkResourceId = field.dataset.paperworkResourceId;
    const url = this.editPaperworkResourceUrlValue.replace('placeholder', paperworkResourceId);

    window.open(`${url}?form_resource=${responseJSON.id}`, '_self')
  }

  closeForm(_ = null, __ = null) {
    localStorage.removeItem('form_new');
    document.querySelector('#close-button').click();
  }

  preview() {
    allowCloseModalWithBackButton('modal-view');
    $('#modal-view').modal('show');
    const previewModal = document.getElementById('modal-view-content');
    let previewHTML = document.getElementById('template-preview-modal').innerHTML;

    previewHTML = previewHTML.replace('{{{name}}}', this.inputTarget.innerHTML)
    previewModal.innerHTML = previewHTML;
  }

  toggleToBeValidated(event) {
    let input = event.target;
    let storage = getStorage(this.idValue);
    storage.to_be_validated = !!input.checked;
    setStorage(this.idValue, storage);
  }
}

export const getStorage = (id) => {
  let storage = localStorage.getItem(`form_${id}`);
  return JSON.parse(storage);
}

export const setStorage = (id, storage) => {
  localStorage.setItem(`form_${id}`, JSON.stringify(storage));
}

export const getStoredSectionFrom = (storage, sectionId) => {
  return storage.sections.find((storedSection) => { return storedSection.id == sectionId });
}

export const getStoredPaperworkFrom = (storedSection, paperworkId) => {
  return storedSection.paperworks.find((storedPaperwork) => { return storedPaperwork.id == paperworkId });
}

export const buildPaperworkHtml = (attributes) => {
  let templateHtml = document.getElementById('template-paperwork').innerHTML;

  templateHtml = templateHtml.replaceAll('{{{unit_organization_ids}}}', attributes['unit_organization_ids']?.join(' '));
  Object.keys(attributes).forEach(key => { templateHtml = templateHtml.replaceAll(`{{{${key}}}}`, attributes[key]) });
  templateHtml = templateHtml.replaceAll('{{{hiddenchecked}}}', isChecked(attributes.hidden));
  templateHtml = templateHtml.replaceAll('{{{mandatorychecked}}}', isChecked(attributes.mandatory));

  return templateHtml
}

export const buildSectionHtml = (id, position, title, description, iterable = false) => {
  let templateHtml = document.getElementById('template-section').innerHTML;
  templateHtml = templateHtml.replaceAll('{{{id}}}', id);
  templateHtml = templateHtml.replaceAll('{{{title}}}', title);
  templateHtml = templateHtml.replaceAll('{{{description}}}', description);
  templateHtml = templateHtml.replaceAll('{{{position}}}', position);
  templateHtml = templateHtml.replaceAll('{{{iterable}}}', iterable);
  templateHtml = templateHtml.replaceAll('{{{iterablechecked}}}', isChecked(iterable));

  return templateHtml
}

export const buildConditionalLogicHtml = (attributes) => {
  let templateHtml = document.getElementById('conditional-logic-template').innerHTML;
  templateHtml = templateHtml.replace('{{{id}}}', attributes.id);
  templateHtml = templateHtml.replace('{{{position}}}', attributes.position);
  templateHtml = templateHtml.replace('{{{conditionRelation}}}', attributes.condition_relation);

  const parsedElement = stringToHTML(templateHtml);
  parsedElement.dataset["conditionalLogicsLogicConditionsValue"] = JSON.stringify(attributes.logic_conditions);
  parsedElement.dataset["conditionalLogicsLogicActionsValue"] = JSON.stringify(attributes.logic_actions);

  return htmlToString(parsedElement);
}

export const buildPaperworkModal = (id) => {
  let templateHtml = document.getElementById('template-paperwork-modal').innerHTML;
  templateHtml = templateHtml.replaceAll('{{{formResourceId}}}', id);

  return templateHtml
}

const isChecked = (boolean) => {
  return (boolean == true || boolean == 'true') ? 'checked' : ''
}

const positionOf = (element) => {
  if (!element) { return 0 }
  const parentList = element.parentElement;
  const position = [...parentList.children].findIndex(el => el == element)

  // safe position -> return position or 0
  // don't want negative position (-1)
  return position
}

export const newId = () => {
  return  "new_" + Date.now()
}

const newConditionalLogicParams = (id) => {
  return {
    id: newId(),
    logic_conditions: [{
      id: newId(),
      operator_type: "is",
      field_id: "",
      value: "",
      collection: null
    }],
    logic_actions: [{
      id: newId(),
      operator_type: "show",
      field_id: ""
    }],
    condition_relation: "and_relation",
    position: nextPosition(id)
  }
}

export const highlightConditionalLogic = (conditionalLogicId) => {
  let sidebar = document.getElementById('conditional-logic-sidebar');
  sidebar.dataset.highlightType = 'conditionalLogic';
  sidebar.dataset.highlightId = conditionalLogicId;
}

export const nextPosition = (id) => {
  const positions = getStorage(id).conditional_logics.map(logic => logic["position"]).sort((a, b) => a - b);
  return positions[positions.length - 1] + 1;
}

export const formId = (element) => {
  let form = element.closest('[data-controller=form-resources]');
  return form.dataset.formResourcesIdValue;
}
