// Use this function to search an element in a list
// Hides the elements not matching the query
// /!\ To make this function work, the innerText to search against must be the first child of the element
export const search = (query, targetElements, textPosition = 'start') => {
  // display all elements in case a previous search has hidden some
  targetElements.forEach(el => display(el));
  // store names to match the query
  let names;
  if (textPosition == 'start') {
    names = targetElements.map(el => el.firstElementChild.innerText);
  } else {
    names = targetElements.map(el => el.lastElementChild.innerText);
  }
  const rejected_elements = [];
  names.forEach((name, i) => {
    if (isMatching(query, name)) { return };
    rejected_elements.push(targetElements[i]);
  });
  rejected_elements.forEach(environment => hide(environment));
};

const isMatching = (query, name) => name.toLowerCase().includes(query.toLowerCase());
const display = element => {
  element.classList.remove('d-none');
  element.classList.add('d-flex');
};
const hide = element => {
  element.classList.remove('d-flex');
  element.classList.add('d-none');
}
