import SwalController from "./swal_controller";

export default class extends SwalController {
  static values = {
    text: Object,
    confirm: Object,
    cancel: Object,
    buttonType: String,
    details: Object
  }

  confirm(event) {
    super.confirm(event);
  }

  confirmRequest(confirmValue) {
    this.handleRequest(confirmValue);
    if (document.getElementById('sidebar-profile')) {
      setTimeout(() => { location.reload(); }, 500)
    }
  }

  afterEnd() {
    if (document.getElementById('hr-sidebar')){
      document.getElementById('hr-sidebar').setAttribute('activated', 'false');
    }

    const tabulator = Tabulator.findTable('#tracks')[0];

    if (tabulator) {
      tabulator.updateData([this.detailsValue]).then(function () {
        tabulator.setData(tabulator.getData());

        const count = Number.parseInt(document.getElementById('tracks-count').innerText, 10);

        if (!tabulator.getFilters()[2].value.includes(this.detailsValue.status)) {
          document.getElementById('tracks-count').innerText = count - 1;
        }
      })
    }
  }
}
