import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { updateCounter, blankState, escapeHtml } from './utils';
import { displayUnscopedEnvironmentMessage, unitOrganizationSorter } from 'components/tabulator/utils';

export function loadTabulatorFormResouces(resources, config = { environment: true }) {
  const initialSort = JSON.parse(localStorage.getItem('form_resources_sorters'));
  const translations = JSON.parse(document.getElementById('form_resources').parentElement.dataset.translations);
  const tabulator = new Tabulator('#form_resources', {
    responsiveLayout: 'hide',
    initialSort: initialSort || [{ column: 'name', dir: 'asc' } ],
    pagination: 'local',
    paginationCounter: function (pageSize, currentRow) {
      const maxNum = currentRow + pageSize - 1;
      return `${currentRow} ${translations.on} ${maxNum < 0 ? 0 : maxNum}`;
    },
    paginationSize: 25,
    paginationButtonCount: 0,
    movableColumns: false,
    placeholder: blankState(),
    data: resources,
    layout: 'fitColumns',
    langs: { 'fr-fr': {} },
    columns: [
      { field: 'id', visible: false },
      { field: 'url', visible: false },
      { field: 'form_resource_ids', visible: false },
      { field: 'mobilities', visible: false },
      { field: 'type', visible: false },
      { field: 'type_label', visible: false },
      { field: 'updated_at_value', visible: false },
      { field: 'validation', visible: false },
      { title: '', field: 'bulk', width: 16, minWidth: 16, maxWidth: 16, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: '', field: 'icon', width: 64, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 },
      { title: translations['title'], field: 'name', minWidth: 300, widthGrow: 4, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 },
      { title: translations['environment'], sorter: unitOrganizationSorter, field: 'multiple_unit_organizations', visible: config['environment'], minWidth: 200, widthGrow: 2, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 1 },
      { title: translations['form_open_count'], sorter: "number", field: 'form_open_count', visible: config['form_open_count'], width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['form_closed_count'], sorter: "number", field: 'form_closed_count', visible: config['form_closed_count'], width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { title: translations['form_validated_count'], sorter: "number", field: 'form_missing_validation_count', visible: config['form_missing_validation_count'], width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { field: 'actions', width: 80, vertAlign: 'middle', hozAlign: 'right', headerHozAlign: 'middle', responsive: 0 },
      { title: '', field: 'bulk', width: 16, minWidth: 16, maxWidth: 16, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false }
    ],
    rowFormatter: function (row) {
      var data = row.getData();
      var rowEl = row.getElement();
      renderHtmlFor(rowEl, data);
    },
    downloadConfig: { columnHeaders: true }
  });
  const locale = document.querySelector('html').lang
  tabulator.on('dataSorted', function (sorters, rows) {
    const saved_sorters = [];
    sorters.forEach((sorter) => {
      saved_sorters.push({ column: sorter.column.getField(), dir: sorter.dir });
    });
    localStorage.setItem('form_resources_sorters', JSON.stringify(saved_sorters));
  });
  setTimeout(() => {
    tabulator.setLocale(`${locale}-${locale}`);
    updateCounter('form_resources');
  }, 200);
}
function renderHtmlFor(row, data) {
  // the following object is used to render the html for the row
  // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
  // the values are the attributes to be replaced in the html, matching the properties of the data we receive
  const matchingObj = {
    name: ["name", "resource_label", "resource_id"],
    multiple_unit_organizations: ["full_unit_organization_count", "unit_organization_names", "unit_organization_names", "resource_id", "resource_type"],
    actions: ["resource_id", "resource_type", "in_shared_environment", "template_present", "mobilities"],
  };
  const controllerEl = document.querySelector('div[data-controller*="desktop--actor--analyse--form-resources--data"]')
  const hasSharedAuthorization = controllerEl.dataset.sharedAuthorization == 'true';
  const hasEnvAuthorization = controllerEl.dataset.envAuthorization == 'true';

  const userScope = JSON.parse(document.querySelector('#setting-content').dataset.envIds);
  const resourceScope = data.full_unit_organization_ids;
  const unscopedUnitOrganizationCount = resourceScope.filter((x) => !userScope.includes(x)).length
  const sharedResource = data.unit_organization_ids.length === 0;
  const singleEnvResource = data.unit_organization_ids.length === 1;

  let editAuthorization = false;
  if (sharedResource && hasSharedAuthorization) {
    editAuthorization = true;
  } else if (!sharedResource && hasEnvAuthorization) {
    editAuthorization = true;
  }

  row.dataset.edit = editAuthorization;
  row.dataset.url = data.forms_url;
  row.dataset.shared = sharedResource;
  row.classList.add('d-flex', 'align-items-center');
  row.querySelector('[tabulator-field=icon]').innerHTML = document.getElementById('icon').innerHTML;
  if (editAuthorization) { row.classList.add('pointer') }
  Object.keys(matchingObj).forEach(template_id => {
    let templateId = template_id
    if (template_id == 'form_resource_count' && !editAuthorization) {
      templateId = 'form_resource_count_no_edit'
    } else if (template_id === 'multiple_unit_organizations' && (sharedResource || singleEnvResource)) {
      templateId = 'single_unit_organization';
    }
    let finalHtml = document.getElementById(templateId).innerHTML;
    finalHtml = displayUnscopedEnvironmentMessage(finalHtml, singleEnvResource, unscopedUnitOrganizationCount)
    matchingObj[template_id].forEach(attribute => {
      finalHtml = finalHtml.replaceAll(`{{${attribute}}}`, escapeHtml(data[attribute]));
    });

    var locale = document.querySelector('html').lang
    finalHtml = finalHtml.replaceAll('{{locale}}', locale);
    row.querySelector(`[tabulator-field="${template_id}"]`).innerHTML = finalHtml;
  });
}
export function updateFilterCounts() {
  updateMobilityFilterCounts();
  updateEnvironmentFilterCounts();
  updateTemplateFilterCounts();
}
function updateMobilityFilterCounts() {
  const tabulator = Tabulator.findTable('#form_resources')[0];
  const filters = document.querySelectorAll('[data-action="click->desktop--actor--analyse--form-resources--mobility-filter#filter"]');
  filters.forEach((filter) => {
    let value = filter.dataset.value;
    let dataCount = filter.querySelector('span[data-count]')
    var count = tabulator.getData('active').filter((el) => el.mobilities.includes(value)).length;
    dataCount.innerText = `(${count})`
  })
}
function updateEnvironmentFilterCounts() {
  const tabulator = Tabulator.findTable('#form_resources')[0];
  const filters = document.querySelectorAll('[data-action="click->desktop--actor--analyse--form-resources--environment-filter#filter"]');
  filters.forEach((filter) => {
    let value = parseInt(filter.dataset.value);
    let dataCount = filter.querySelector('span[data-count]')
    var count = () => {
      if (value == 0) {
        // Shared
        return tabulator.getData('active').filter((el) => { return el.unit_organizations_count == 0 }).length;
      } else {
        return tabulator.getData('active').filter((el) => { return el.unit_organization_ids.includes(value) }).length;
      }
    };
    dataCount.innerText = `(${count()})`
  })
}
function updateTemplateFilterCounts() {
  const tabulator = Tabulator.findTable('#form_resources')[0];
  const filters = document.querySelectorAll('[data-action="click->desktop--actor--analyse--form-resources-template-filter#filter"]');
  filters.forEach((filter) => {
    let value = parseInt(filter.dataset.value);
    let dataCount = filter.querySelector('span[data-count]')
    var count = () => {
      if (value == 0) {
        // Without template
        return tabulator.getData('active').filter((el) => { return el.templates_count == 0}).length;
      } else {
        // With template
        return tabulator.getData('active').filter((el) => { return el.template_ids.includes(value)}).length;
      }
    };
    dataCount.innerText = `(${count()})`
  })
}
