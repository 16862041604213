import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  id = this.element.id.replace('logic_action_', '')
  field = document.querySelector(`.paperwork-list [data-field-id='${this.element.dataset.fieldId}']`);
  logic = this.element.closest('[data-controller="desktop--form--conditional-logics"]');
  operator = this.element.dataset.operator;

  connect() {
    this.initLogicObserver();
    this.execute();
  }

  disconnect() {
    // remove the 'data-logic-action-show' attribute
    // when the logic action is removed from the DOM
    // (when a section iteration is removed)
    this.undo();
  }

  initLogicObserver() {
    let observer = new MutationObserver(this.waitingMet.bind(this));
    observer.observe(this.logic, { attributes: true });
  }

  waitingMet(mutationsList) {
    for(let mutation of mutationsList) {
      let attributeName = mutation.attributeName;
      if (attributeName == "data-met") {
        this.execute();
      }
    }
  }

  execute() {
    this.addTargetControllerToField();
    this.logic.dataset.met == 'true' ? this.do() : this.undo();
  }

  do() {
    switch (this.operator) {
      case 'show':
        return this.markToShow(true);
      case 'hide':
        return this.markToShow(false);
      default:
        return false;
    }
  }

  undo() {
    this.field.removeAttribute(`data-logic-action-show-${this.id}`);
  }

  markToShow(showBoolean) {
    this.field.setAttribute(`data-logic-action-show-${this.id}`, showBoolean)
  }

  addTargetControllerToField() {
    const previousControllers = this.field.dataset.controller || "";
    if (!previousControllers.includes('desktop--form--logic-targets')) {
      this.field.dataset.controller = `${previousControllers} desktop--form--logic-targets`;
    }
  }
}
