// Example in https://www.youtube.com/watch?v=zq_bEALI9TQ

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination"]

  scroll() {
    var next_button = this.paginationTarget.querySelector("a[rel='next']")
    // return if it's the end of page
    if (next_button == null) { return }

    let url = next_button.href
    var body = document.body,
      html = document.documentElement
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (readyToLoad(height)) {
      this.loadMoreActions(url);
    }
  }

  loadMoreActions(url) {
    document.getElementById('action-list').dataset.status = 'loading'
    var temporality = document.getElementById('action-block').dataset.temporality;
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: { 'temporality': temporality },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        document.getElementById('action-list').dataset.status = 'ready';
      }
    });
  }
}

function readyToLoad(height) {
  let isReady = document.getElementById('action-list').dataset.status == 'ready';
  return (onBottonPage(height) && isReady)
}

function onBottonPage(height) {
  var limitBottom = [height - window.innerHeight - 100, height - window.innerHeight]
  return limitBottom.includes(Math.floor(window.pageYOffset))
}
