import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["placeholderElement", "actionsElement", "toolbar", "selectAllElement"];
  static values = {
    table: String,
    translations: Object
  };

  state = 'unchecked';

  connect() {
  }

  selectAllToogle(event) {
    if (this.state === 'checked') {
     this.unselectAll()
    } else {
      this.selectAll();
    }
  }

  selectAll() {
    const tabulator = this.table;
    this.uncheckAll();
    tabulator.getRows().forEach(row => {
      row.getElement().querySelector('[tabulator-field="bulk_select"] input[type="checkbox"]').click();
    })
    if(this.hasSelectAllElementTarget){
      this.selectAllElementTarget.checked = true;
    }
    this.state = 'checked';
  }

  unselectAll() {
    this.uncheckAll();
    this.state = 'unchecked';
  }

  hoverLine(event) {
    var row = event.target.closest('.tabulator-row');
    if (event.target.checked){
      row.classList.add("bg-primary-100", "checked");
      event.target.parentNode.classList.add("checked");
    } else {
      row.classList.remove("bg-primary-100", "checked");
      event.target.parentNode.classList.remove("checked");
    }
    this.updatePlaceholder();
  }

  uncheckAll(){
    const elementsChecked = $(".tabulator-row.checked");
    var controller = this;
    elementsChecked.each(function(){
      this.classList.remove("checked");
      $(this).find(".bulk-select")[0].classList.remove("checked");
      $(this).find("input[type=checkbox]").prop("checked", false);
      $(this).removeClass("bg-primary-100");

      controller.updatePlaceholder();
    });

    if(this.hasSelectAllElementTarget){
      this.selectAllElementTarget.checked = false;
    }
  }

  updatePlaceholder() {
    const elementsChecked = $(".tabulator-row.checked");
    if(elementsChecked.length > 0) {
      this.placeholderElementTarget.innerHTML = elementsChecked.length + " " + this.translationsValue['label'];
      this.actionsElementTarget.classList.add('visible');
      this.actionsElementTarget.classList.remove('invisible');
    } else {
      this.actionsElementTarget.classList.remove('visible');
      this.actionsElementTarget.classList.add('invisible');
    }
  }

  followupModal(event){
    const elementsChecked = $(".tabulator-row.checked");

    const followupController = this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller=form-followup]"), 'form-followup')
    if (followupController) {
      followupController.displayModal(event, elementsChecked);
    }
  }

  get table() {
    const tabulator = Tabulator.findTable(this.tableValue)[0];
    return tabulator;
  }
}
