import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["list"]
  static values = { viewed: Boolean, submitOnConnect: Boolean }

  connect() {
    new Sortable(this.listTarget, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: (_event) => {
        this.updatePositions();
      }
    });

    if (this.submitOnConnectValue) { this.submit(); }
  }

  updatePositions() {
    let items = [...this.listTarget.children].filter((el) => { return (el.tagName == 'DIV') && (el.style.display != 'none') });
    items.forEach((el, index) => {
      let position = index + 1;
      el.querySelector('[data-position]').innerText = position;
      if (el.querySelector('[name*=position]')) { el.querySelector('[name*=position]').value = position };
    })
    this.submit();
  }

  viewedValueChanged() {
    if (this.viewedValue) { this.submit(); }
  }

  submit() {
    let form = this.element.querySelector('form');
    if (form) { $(form).submit(); }
  }
}
