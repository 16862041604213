import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = ["subject", "body"]

  connect() {
    this.updateVariablesBreakdown();
  }

  updateVariablesBreakdown() {
    const subjectContent = this.hasSubjectTarget ? this.subjectTarget.value : '';
    const bodyContent = this.bodyTarget.value;

    fetch("/settings/mailing_resources/update_variables_breakdown", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({ subject: subjectContent, body: bodyContent, options: { track_invitation: document.getElementById('mailing_resource_track_invitation').checked} }),
    })
    .then(response => response.text())
    .then(script => eval(script))
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }
}
