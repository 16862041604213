import { Controller } from "@hotwired/stimulus"

// This controller can to use to download file directly from an URL
export default class extends Controller {
  static values = { url: String, filename: String }

  download() {
    let url = this.urlValue;
    let filename = this.filenameValue;

    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
  }
}
