import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['askBar']
  static values = { currentSignInAt: String }

  connect() {
    const mobile = document.querySelector('.mobile-device');
    if (!mobile) {
      useClickOutside(this);
    }
  }

  openAskBar() {
    const askOverlay = document.getElementById('ask-overlay');
    const query = document.querySelector('#ask-bar input');
    askOverlay.classList.remove('hidden');
    askOverlay.classList.add('ask-overlay-backdrop');
    query.focus();
  }

  closeAskBar() {
    const askOverlay = document.getElementById('ask-overlay');
    askOverlay.classList.add('hidden');
    askOverlay.classList.remove('ask-overlay-backdrop');
  }

  post() {
    event.preventDefault();
    const query = document.querySelector('#ask-bar input').value;
    const filtered_ids = this.askBarTarget.dataset.filteredIds;
    const locale = document.documentElement.lang;
    trackPageLoad('Ask a question to Workelo Ask', {
      'question': query,
      'current_sign_in_at': this.currentSignInAtValue
    })
    this.placeholder();
    this.intializeAnswser();
    fetch('https://workelo-ask-api-jz5lg2jczq-od.a.run.app', {
      method: 'POST',
      body: `query=${encodeURIComponent(query)}&filtered_ids=${encodeURIComponent(filtered_ids)}&locale=${encodeURIComponent(locale)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'rJ84DSB3t7BlyYGWzoQ8LAptjNVkqZLhXST4H9jSBPEjVfZ40C5PIe2Zb0yzj2OWEW1EJFhhx9u5iqxgVqcW3a2nDu2gpAAhmR58WMqYjaLNaQ4SvMG8k7z218eVEsW6'
      }
    })
      .then(response => response.json())
      .then(data => this.loadAnswers(data))
      .catch(error => console.error(error));
  }

  placeholder() {
    const placeholder = document.getElementById('ask-placeholder');
    const glow = document.getElementById('ask-bar-glow');
    placeholder.classList.remove('hidden');
    glow.classList.add('hidden');
  }

  intializeAnswser() {
    const answer = document.getElementById('answer');
    const sources = document.getElementById('sources');
    if (!answer.parentElement.classList.contains('hidden')) {
      answer.parentElement.classList.add('hidden');
    }
    answer.innerHTML = '{{answer}}';
    sources.innerHTML = '';
  }

  loadAnswers(data) {
    const askAnswer = document.getElementById('ask-answer');
    const placeholder = document.getElementById('ask-placeholder');
    const mobile = document.querySelector('.mobile-device');
    askAnswer.innerHTML = askAnswer.innerHTML.replace(/{{answer}}/g, data['answer']);
    data['sources'].forEach((source) => {
      if (mobile) {
        setSourceMobile(source['title']);
      } else {
        setSource(source['id'], source['title']);
      }
    })
    placeholder.classList.add('hidden');
    askAnswer.classList.remove('hidden');
    const sourceIds = data['sources'].map(source => source['id']).join(' ; ')
    trackPageLoad('Get an answer from workelo ask', {
      'question': data['query'],
      'answer': data['answer'],
      'current_sign_in_at': this.currentSignInAtValue,
      'source_id': sourceIds
    })
  }
}

const setSource = (id, title) => {
  const sources = document.getElementById('sources');
  sources.innerHTML += `
    <div data-controller='desktop--onboardee--modal'
         data-action='click->desktop--onboardee--modal#call'
         data-desktop--onboardee--modal-url-value="/onboardee/training_resources/${id}/edit"
         data-desktop--onboardee--modal-method-value='get'
         class='text-bold text-12px hover-text-blue padded-5px pointer'>
      ${title}
    </div>
  `;
}

const setSourceMobile = (title) => {
  const sources = document.getElementById('sources');
  sources.innerHTML += `
    <div class='text-bold text-12px padded-5px'>${title}</div>
  `;
}
