import { Controller } from "@hotwired/stimulus";
import { replaceValues } from './../../../../../pages/sidebar_utils'
import { isAdministrativeTab,
         isAlertTab,
         isFeedbackTab,
         isActionTab,
         defineBackupTab } from 'packs/controllers/desktop/hr/tracks_controller'

// This controller handles the track sidebar from the action list page and the dashboard page (analyse section)
// There is another that handles it from the actor's track page.
//  -> app/javascript/packs/controllers/desktop/hr/tracks_controller.js

export default class extends Controller {
  static values = { trackId: String, employeeId: String, mobility: String, status: String, featFeedback: Boolean }

  show() {
    this.loadSidebarContent();
    this.displaySidebar();
  }

  loadSidebarContent() {
    let backupTab = defineBackupTab(this.statusValue);
    let content = document.getElementById('template-sidebar-content').innerHTML;
    let matches = {
      '{{{trackId}}}': this.trackIdValue,
      '{{{employeeId}}}': this.employeeIdValue,
      '{{{mobility}}}': this.mobilityValue,
      '{{{status}}}': this.statusValue,
      '{{{alertActivated}}}': isAlertTab(null, backupTab),
      '{{{administrativeActivated}}}': isAdministrativeTab(null, backupTab),
      '{{{feedbacksActivated}}}': isFeedbackTab(null, backupTab),
      '{{{actionsActivated}}}': isActionTab(null, backupTab),
      '{{{featFeedback}}}': this.featFeedbackValue
    }

    document.getElementById('hr-sidebar').innerHTML = replaceValues(content, matches);
  }

  displaySidebar() {
    const sidebar = document.getElementById('hr-sidebar');
    if (sidebar.getAttribute('activated') == 'false') {
      sidebar.setAttribute('activated', 'true');
    }
  }
}
