import SelectizesController from "../utils/selectizes_controller";
import { emailRegex } from "utils/regex";

export default class extends SelectizesController {
  static values = {
    url: String,
    resourceType: String,
    resourceId: String,
    labels: String,
    maxItems: Number
  }

  connect() {
    $(this.element).selectize(this.config());
    this.setSigners();
  }

  loadOptions(query, callback) {
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'json',
      data: {
        query: query,
        resource_type: this.resourceTypeValue,
        resource_id: this.resourceIdValue,
        email_as_value: true
      },
      error: () => {
        callback();
      },
      success: (response) => {
        callback(response);
      }
    });
  }

  config() {
    return {
      ...this.basicConfig(),
      create: true,
      createOnBlur: true,
      maxItems: this.maxItemsValue,
      createFilter: emailRegex,
      valueField: 'email',
      labelField: 'label',
      searchField: ['id', 'first_last', 'last_name', 'email'],
      render: {
        option: (item, escape) => { return '<div style="padding: 6px 15px;">' + escape(item.label) + '</div>'; }
      },
      onDropdownOpen: (dropdown) => {
        // Remove the dropdown, if propose to 'Add [newOption]'
        if (dropdown[0].querySelector('.selectize-dropdown-content .create')) { dropdown[0].remove(); }
      },
      onItemAdd: (value, item) => {
      },
      onItemRemove: (item) => {
      }
    }
  }

  // When we intialize the input, we want to retrieve the current recipients
  // But the selectize intializes one option as a concatenation of all selected recipients
  // We need to remove the bad option and create all the options and set them as item (selected option)
  setSigners() {
    let selectize = this.element.selectize
    let badOption = selectize.getValue();
    let recipients = JSON.parse(this.labelsValue);
    let labels = recipients.map((recipient) => { return recipient.email });
    if (badOption) { selectize.removeOption(badOption) }
    if (labels[0]) {
      selectize.addOption(recipients)
      selectize.setValue(labels)
    }
  }
}
