import { BaseController } from "../../../../../javascript/packs/controllers/desktop/actor/filter/base_controller";
import { useClickOutside } from 'stimulus-use';
import { flatpickrFilter } from '../../../../../javascript/components/flatpickr';
import * as applyFilters from '../../../../../javascript/components/tabulator/apply_filters';;
import { activate, desactivate } from '../../../../../javascript/components/filter_utils';

export default class extends BaseController {
  static targets = ["select", "menu", "followingDays", "previousDays", "definedDays", "from", "to"];
  static values = {
    page: String,
    filterType: String
  };

  connect() {
    flatpickrFilter();
    useClickOutside(this);
    this._setFiltersWithLocalStorage()
  }

  filter(event) {
    document.getElementById('definedDaysFrom').value = '';
    document.getElementById('definedDaysTo').value = '';

    let range;
    if (event.target === this.followingDaysTarget) {
      range = dateRange(31, new Date());
    } else if ((event.target === this.previousDaysTarget)) {
      range = dateRange(31, new Date(new Date().setDate(new Date().getDate() - 30)));
    }
    this._applyDates(range);
  }

  toggleCalendar() {
    this.fromTarget.click();
  }

  setRange() {
    if (this.fromTarget.value != '' && this.toTarget.value != '') {
      this.definedDaysTarget.checked = true;
      // the dataset dateFormat is set in the flatpickr.js file, at the change event
      // It allows us to work with correct date format for the calculation
      const size = ((new Date(this.toTarget.dataset.dateFormat) - new Date(this.fromTarget.dataset.dateFormat)) / dayInMs()) + 1;
      const range = dateRange(size, new Date(this.fromTarget.dataset.dateFormat));
      this._applyDates(range);
    }
  }

  reset() {
    [this.followingDaysTarget, this.previousDaysTarget, this.definedDaysTarget].forEach(input => input.checked = false);
    [this.fromTarget, this.toTarget].forEach(input => input.value = '');
    localStorage.setItem(`${this.pageValue}_${this.filterTypeValue}s`, JSON.stringify([]));
    this._applyFilter();
    this.closeMenu();
    desactivate(this.selectTarget)
  }

  _applyDates(range) {
    localStorage.setItem(`${this.pageValue}_${this.filterTypeValue}s`, JSON.stringify(range));
    this._applyFilter();
    activate(this.selectTarget)
  }

  _setFiltersWithLocalStorage() {
    const currentDates = JSON.parse(localStorage.getItem(`${this.pageValue}_${this.filterTypeValue}s`)) || [];
    if (currentDates.length == 0) { return };

    activate(this.selectTarget);
    if (currentDates.length == 31) {
      const isFollowingDays = new Date(currentDates[0]).getMonth() == new Date().getMonth();
      const target = isFollowingDays ? this.followingDaysTarget : this.previousDaysTarget
      target.checked = true;
    } else {
      this.definedDaysTarget.checked = true;
      this.fromTarget.value = moment(new Date(currentDates[0])).format('DD-MM-YYYY');
      this.toTarget.value = moment(new Date(currentDates[currentDates.length - 1])).format('DD-MM-YYYY');
    }
  }

  _applyFilter() {
    const functionToCall = `apply${capitalizeWords(this.pageValue)}Filters`;
    applyFilters[functionToCall]();
  }
}

function dateRange(size, from) {
  let fromInteger = from.setDate(from.getDate());
  const range = [];

  for (let index = 0; index < size; index++) {
    const stringDate = moment(new Date(fromInteger)).format('YYYY-MM-DD');
    range.push(stringDate);
    fromInteger += dayInMs();
  }
  return range;
}

function dayInMs() {
  return 1000 * 60 * 60 * 24;
}

const capitalizeWords = string => {
  return string.replace(/(?:^|_)\S/g, a => { return a.toUpperCase(); }).replace('_', '')
};
