export const setFiltersWithLocalStorage = (filter, menu) => {
  const values = [...menu.querySelectorAll('.form_resource-filter')].map(el => el.dataset.value);
  const current_filters = localStorage.getItem(`form_resources_${filter}s`) || localStorage.setItem(`form_resources_${filter}s`, JSON.stringify(values));
  const elements = document.querySelectorAll(`.form_resource-filter.${filter}-filter`);

  const selected = JSON.parse(current_filters);
  const unselected = [...elements].map(el => el.dataset.value).filter(id => !selected.includes(id));
  unselected.forEach(id => unselect(menu.querySelector(`.${filter}-filter[data-value="${id}"]`)))
}
const unselect = element => {
  element.dataset.selected = false;
}

// persist current filters in localStorage
export const updateFilter = (filter, menu) => {
  const values = [...menu.querySelectorAll(".form_resource-filter[data-selected='true']")].map(el => el.dataset.value);
  localStorage.setItem(`form_resources_${filter}`, JSON.stringify(values));
}

export const selectAllElements = filter => {
  document.querySelectorAll(`.${filter}-filter`).forEach(el => el.dataset.selected = true);
};

export const removeAllElements = filter => {
  document.querySelectorAll(`.${filter}-filter`).forEach(el => el.dataset.selected = false);
};

export const toggleActive = (filter, menu) => {
  isActive(filter) ? activate(menu) : desactivate(menu);
}

const isActive = filter => { return document.querySelectorAll(`.${filter}-filter[data-selected='false']`).length > 0 };
export const activate = menu => menu.classList.add('active');
export const desactivate = menu => menu.classList.remove('active');
