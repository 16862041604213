import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'fileRow', 'fileLabel', 'previewImage', 'fileSizeLimit']

  connect() {

    let input = this.inputTarget
    let fileLabel = this.fileLabelTarget
    let fileRow = this.fileRowTarget
    let previewImg = this.previewImageTarget
    let fileSizeLimit = this.fileSizeLimitTarget;


    input.click();
    input.addEventListener('change', function(el) {
      fileLabel.innerText = "";

      let file = input.files[0]
      let limit = parseInt(fileSizeLimit.dataset.value);

      fileRow.classList.remove('d-none');
      fileRow.classList.add('d-flex');
      if (file) {
        if (file.size <= limit) {
          fileLabel.innerText = file.name;
          let reader = new FileReader();
          reader.onload = function() {
            previewImg.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
        else {
          fileLabel.innerHTML = fileSizeLimit.dataset['translationKey'];
          previewImg.src = "";
          input.value = "";
        }
      } else {
        previewImg.src = "";
      }
    });
  }
}
