import utilsSearchBarsController from "packs/controllers/utils/search_bars_controller"

// This controller is a child of utilsSearchBarsController
export default class extends utilsSearchBarsController {
  static targets = ['input', 'reset', "list", "templateLine", "templateEmpty", "searchBar"]

  connect() {
    super.connect();
    this.fetchList(this.withQueryUrl());
  }

  // Callback run after fetch the data list
  // Overwrite the parent callback function
  callback(responseJSON) {
    this.fillList(responseJSON)
  }

  fillList(data) {
    if (data.size > 0) {
      this.listTarget.innerHTML = '';
      data.results.forEach(lineData => this.listTarget.insertAdjacentHTML('beforeend', this.buildLine(lineData)));
    } else {
      this.listTarget.innerHTML = this.buildEmpty(data);
    }
  }

  buildLine(lineData) {
    let templateHTML = this.templateLineTarget.innerHTML
    let keys = Object.keys(lineData)
    templateHTML = templateHTML.replaceAll(`{{{object-unit_organization_ids}}}`, lineData['unit_organization_ids'].join(' ') || '')
    keys.forEach(key => {
      templateHTML = templateHTML.replaceAll(`{{{object-${key}}}}`, lineData[key])
    })

    let alreadyPresent = !!document.querySelector(`[data-type="paperwork"][data-paperwork-resource-id='${lineData.id}']`)
    templateHTML = templateHTML.replaceAll(`{{{already-present}}}`, alreadyPresent)

    return templateHTML
  }

  buildEmpty(data) {
    let templateHTML = this.templateEmptyTarget.innerHTML
    templateHTML = templateHTML.replaceAll('{{{query}}}', data.query);

    return templateHTML
  }

  withQueryUrl() {
    const query = this.inputTarget.dataset.currentQuery;
    const url = new URL(this.urlValue);
    const unitOrganizationIds = document.querySelector('#unit_organization_ids_input')?.value?.trim()?.replaceAll(' ', ',');
    if (query && (query !== '')) { url.searchParams.set('query', query) }
    if (unitOrganizationIds && (unitOrganizationIds !== '')) {
      url.searchParams.set('unit_organization_ids', unitOrganizationIds)
    }
    return url;
  }
}
