import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import { flash } from '../../components/flashes';
import { csrfToken } from "utils/csrf_token";
import { escapeHtml } from 'components/tabulator/utils'

export default class extends Controller {
  static targets = ['formId', 'wrapper', 'refusePopin' ]
  static values = {
    status: String,
    id: String,
    translations: Object,
    options: Object,
    items: Array
  }

  connect() {
    useClickOutside(this);
    this.build();
  }

  build() {
    if (this.statusValue === 'missing_validation'){
      this.wrapperTarget.classList.remove("d-none");
    }
  }

  validate(event) {
    event.preventDefault();

    var element = event.target;
    if (!element.classList.contains('clicked')) {
      element.classList.add('clicked');
      fetch(element.href, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.wrapperTarget.remove();
        flash(this.translationsValue['validate']);
      })
      .catch(error => {
        this.wrapperTarget.remove();
        flash(this.translationsValue['error'], 'alert');
      });
    } else {
      element.href = 'javascript:void(0)';
    }
  }


  displayRefusePopin(event){
    event.preventDefault();
    var defaults = {
      height: 'auto',
      withDropdownElement: true
    }

    var previousModal = document.getElementById('form-approval-modal')
    if (previousModal) {
      previousModal.remove();
    }

    const mergedOptions = { ...defaults, ...this.optionsValue };
    let itemsValueUpdated = this.itemsValue;

    fetch("/actor/analyse/forms/" + this.idValue, {
      method: "GET",
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {

      itemsValueUpdated = this.itemsValue.map((item) => {
        const paperwork = data.find((p) => item.id === p.id);
        if (paperwork && paperwork.status === 'refused') {
          return { ...item, checked: true };
        }
        return item;
      });


      const dropdownElement = mergedOptions['withDropdownElement'] ? `
        <div class="form-group text optional form_message no-margin" style="flex: 1 1 auto" data-controller="utils--dropdown" data-utils--dropdown-items-value="${escapeHtml(JSON.stringify(itemsValueUpdated))}">
          <p><label class="font-weight-bold">${this.translationsValue['approval_modal']['labels']['select_fields']}</label></p>
          <ul data-utils--dropdown-target="list" class="list-group overflow-auto" style="height:150px">
          </ul>
          <p class="text-right mt-2">
            <a data-action="click->utils--dropdown#selectAll" data-utils--dropdown-target="selectAll">${this.translationsValue['select_all']}</a>
            <span> - </span>
            <a data-action="click->utils--dropdown#clearAll" data-utils--dropdown-target="clearAll">${this.translationsValue['unselect']}</a>
          </p>
        </div>
      ` : ``;

      const modalTemplate = `
        <div id="form-approval-modal" class="modal fade modal-full" tabindex="-1" role="dialog" aria-modal="true" data-form-approvals-target="refusePopin">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="height: ${mergedOptions['height']};">
              <a href="#" class="link-decoration-none" data-action="click->form-approvals#closePopin">
                <i class="fa fa-times-circle float-right close-menu text-grey-500 text-20px margin-20px" aria-hidden="true"></i>
              </a>
              <h2 class="text-grey-800 text-center text-20px text-normal d-flex flex-column justiy-content-center">${this.translationsValue['approval_modal']['title']}</h2>
              <div class="margin-50px">
                <div>
                  <form class="d-flex flex-column justiy-content-center text-left" action="/actor/forms/${this.idValue}/form_approvals/refuse" method="DELETE">
                    ${dropdownElement}
                    <div class="mt-4 form-group text optional form_message" style="flex: 1 1 auto">
                      <label class="font-weight-bold">${this.translationsValue['approval_modal']['labels']['message']}</label>
                      <textarea class="form-control text optional" style="height: 100px" placeholder="${this.translationsValue['reason']}" name="form[message]" id="form_message"></textarea>
                    </div>
                  </form>
                </div>
                <div class="d-flex justify-content-center margin-top-40px">
                  <button class="button button-white margin-lateral-10px" data-action="click->form-approvals#closePopin">
                  ${this.translationsValue['clear']}
                  </button>
                  <button class="button button-blue margin-lateral-10px" data-form-approvals-target="validPopin" data-action="click->form-approvals#refuse">
                  ${this.translationsValue['send']}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      this.wrapperTarget.insertAdjacentHTML('beforeend', modalTemplate);
      $(this.refusePopinTarget).modal("show");
    })
  }

  closePopin(event){
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }
    $(this.refusePopinTarget).modal("hide");
  }

  refuse(event) {
    event.preventDefault();
    var element = event.target;
    if (!element.classList.contains('clicked')) {
      element.classList.add('clicked');
      var form = this.refusePopinTarget.querySelector("form");
      fetch(form.action, {
        method: "DELETE",
        body: new FormData(form),
        headers: {
          'X-CSRF-Token': csrfToken()
        }
      })
      .then(response => response.text())
      .then(data => {
        this.closePopin();
        flash(this.translationsValue['refuse']);
        this.wrapperTarget.remove();
      })
      .catch(error => {
        console.error("Error:", error)
        const notification = document.querySelector('#setting-content').dataset.duplicationNotification;
          flash(notification);
      })
    } else {
    }
  }
}
