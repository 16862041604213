function togglePaperworksDisplay(form_id) {
  var paperworkList = $('#form-' + form_id).find('div[data-target=paperworks-lists]')
  paperworkList.toggleClass('d-none');

  if (paperworkList.hasClass('d-none')){
    $('#see-more-form-' + form_id).html("<i class='fad fa-chevron-double-down'></i>")
  } else {
    $('#see-more-form-' + form_id).html("<i class='fad fa-chevron-double-up'></i>")
  };
}
export { togglePaperworksDisplay }
