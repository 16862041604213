import { Controller } from "@hotwired/stimulus"
import { emailRegex } from "utils/regex";

export default class extends Controller {
  static values = {
    url: String,
    resourceType: String,
    resourceId: String
  }

  connect() {
    $(this.element).selectize(this.config());
  }

  loadOptions(query, callback) {
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'json',
      data: {
        query: query,
        resource_type: this.resourceTypeValue,
        resource_id: this.resourceIdValue
      },
      error: () => {
        callback();
      },
      success: (response) => {
        callback(response);
      }
    });
  }

  config() {
    const options = this.basicConfig();

    if (this.resourceTypeValue == 'SurveyQuestion') {
      return {
        ...options,
        create: false,
        valueField: 'id',
        labelField: 'content',
        searchField: 'content',
        render: {
          option: (item, escape) => { return '<div style="padding: 6px 15px;">' + escape(item.content) + '</div>'; }
        }
      }
    } else if (this.resourceTypeValue == 'MailingResource') {
      return {
        ...options,
        create: true,
        createOnBlur: true,
        maxItems: null,
        createFilter: emailRegex,
        valueField: 'email',
        labelField: 'email',
        searchField: ['id', 'first_last', 'last_name', 'email'],
        render: {
          option: (item, escape) => { return '<div style="padding: 6px 15px;">' + escape(item.email) + '</div>'; }
        },
        onDropdownOpen: (dropdown) => {
          // Remove the dropdown, if propose to 'Add [newOption]'
          if (dropdown[0].querySelector('.selectize-dropdown-content .create')) { dropdown[0].remove(); }
        }
      }
    } else if (this.resourceTypeValue == 'UnitOrganization') {
      return {
        ...options,
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        render: {
          option: function (item, escape) {
            return '<div style="padding: 6px 15px;">' + escape(item.name) + '</div>';
          }
        },
      }
    } else {
      return {
        ...options,
        create: false,
        valueField: 'value',
        labelField: 'value',
        searchField: ['value', 'external_id'],
        render: {
          option: (item, escape) => {
            let label = escape(item.value);
            if (typeof item.external_id !== 'undefined' && item.external_id !== null && item.external_id != ''){
              label += '    <span>' + escape(item.external_id) + '</span>';
            }
            return '<div style="padding: 6px 15px;">' + label + '</div>';
          }
        }
      }
    }
  }

  basicConfig() {
    return {
      preload: true,
      onChange: (_value) => {
        let event = new Event('change');
        this.element.dispatchEvent(event)
      },
      load: (query, callback) => { this.loadOptions(query, callback) }
    }
  }

  emailRegex() {
    return new RegExp("([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" + "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)");
  }
}
