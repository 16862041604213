import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delayInMinutes: {
      type: Number,
      default: 1,
    }
  }

  connect() {
    setInterval(() => this.handleTimeout(), this.delayInMinutesValue * 60 * 1000);
  }

  async handleTimeout() {
    if (await this.isTimeout()) {
      // if user's session is expired we reload
      // the page which redirects us to the sign-in page
      window.location.reload();
    }
  }

  async isTimeout () {
    // Call /check_session_timeout endpoint which responds with
    // the time left before session expires.
    const response = await fetch('/check_session_timeout', {
      headers: {
        'Accept': 'application/json'
      }
    });
    const data = await response.json();
    return data.status === 200 && data.time_left <= 0;
  }
}
