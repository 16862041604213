

import { Controller } from "@hotwired/stimulus"
import { setAnswerTypeForm } from "components/survey"

export default class extends Controller {
  static values = { 
    answerType: String,
    initEdit: String
  }

  connect() {
    setAnswerTypeForm(this.answerTypeValue, this.initEditValue == 'true');
  }
}
