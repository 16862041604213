import { BaseFilterController } from "./base_filter_controller";
import { getFilters, delayRequestFor } from "./utils";

export default class extends BaseFilterController {
  static targets = ['switch'];

  connect() {
    const isImplications = getFilters().implication_scope;
    if (isImplications && !this.switchTarget.checked) { this.updateScope() }
  }

  updateScope(event) {
    // If we don't have any event, it means, it comes from the connect above, so we return
    // as we don't want to make any changes and toggle a request
    // We only want to update the front state of our switch, updating it depending of the filter
    if (!event) { return };

    const isImplication = event.currentTarget.checked;
    const existingFilters = getFilters();
    existingFilters.implication_scope = isImplication;
    document.getElementById('filters').dataset.filters = JSON.stringify(existingFilters);
    delayRequestFor('tracksDelay', 'small');
  }

  updateScopeFromBlankState() {
    // This method is called when the user receive a blank state from a search and the scope is set to
    // only our implied tracks, so we don't get the tracks on scope_on_environment
    // We check if the switch is on, and we click on the switch to unscope
    // which launch the updateScope() method above.
    const switchInput = document.querySelector('.switch-input');
    if(switchInput.checked) { switchInput.click() }
  }
}
