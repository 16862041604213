import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import { flash } from '../../components/flashes';
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = ['formId', 'wrapper', 'trigger', 'popin', 'recipients' ]
  static values = {
    status: String,
    id: String,
    translations: Object,
    options: Object,
    date: String,
    collaboratorFullName: String
  }

  connect() {
    useClickOutside(this);
    if(this.statusValue != "pending"){
      this.triggerTarget.classList.add("disabled");
      this.triggerTarget.setAttribute("disabled", "disabled");
      this.triggerTarget.removeAttribute('data-action');
    }
  }

  click(event) {
    event.preventDefault();
    this.displayModal(event, $([event.target.closest(".tabulator-row")]))
  }

  send(event) {
    event.preventDefault();
    var form = this.popinTarget.querySelector("form");
    fetch(form.action, {
      method: "POST",
      body: new FormData(form),
      headers: {
        'X-CSRF-Token': csrfToken()
      }
    })
    .then(response => response.text())
    .then(data => {
      $(this.popinTarget).modal("hide");
      flash(this.translationsValue['validate']);
    })
    .catch(error => {
      $(this.popinTarget).modal("hide");
      flash(this.translationsValue['error'], 'alert');
    })
  }

  displayModal(event, forms){
    if(event) {
      event.preventDefault();
    }

    let modalTemplate = document.getElementById('followup').innerHTML;
    const table = this.table;
    let recipients = [];

    forms.each(function(index, form) {
      let recipientTemplate = document.getElementById('recipients').innerHTML;
      let data = table.getRow(form).getData();
      modalTemplate = modalTemplate.replaceAll(`{{mobility}}`, data.mobility);
      modalTemplate = modalTemplate.replaceAll(`{{employee_id}}`, data.employee_id);
      modalTemplate = modalTemplate.replaceAll(`{{form_id}}`, data.id);

      if(data.hr_status_key === 'pending'){
        const attributes = {
          collaboratorFullNameValue: data.recipient_label,
          dateValue: this.convertTimestamp(data.followup_date),
          resourceId: data.resource_id,
        }

        let recipientHtml = recipientTemplate;
        Object.entries(attributes).forEach(([k, v]) => {
          recipientHtml = recipientHtml.replaceAll(`{{${k}}}`, v);
        });
        recipients.push(recipientHtml);
      }
    }.bind(this));

    if(recipients.length === 0){
      flash(this.translationsValue['error'], 'alert');
      return;
    }

    this.triggerTarget.insertAdjacentHTML('beforeend', modalTemplate);
    this.recipientsTarget.innerHTML = recipients.join('');


    this.popinTarget.addEventListener('click', function(event) {
      event.stopPropagation();
    });

    $(this.popinTarget).modal("show");
  }

  closePopin(event){
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }
    $(this.popinTarget).modal("hide");
  }


  convertTimestamp(dateIso8601) {
    if(dateIso8601 == "null") {
      return "";
    }

    const date = new Date(dateIso8601);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formatedDate = `${this.addZero(day)}-${this.addZero(month)}-${year} ${this.addZero(hours)}:${this.addZero(minutes)}`;
    return "(relancé le " + formatedDate + ")";
  }

  addZero(num) {
    return (num < 10 ? "0" : "") + num;
  }

  get table() {
    const tabulator = Tabulator.findTable("#forms")[0];
    return tabulator;
  }
}
