import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['content']
  static values = { url: String, clicked: Boolean }

  connect() {}

  async click() {
    if (this.clickedValue) { return }
    this.clickedValue = true;
    const response = await fetch(this.urlValue);
    const data = await response.json();
    this.contentTarget.innerHTML = data.administrativeProgress;
  }
}
