import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['delivery', 'subject', 'photo', 'attachments', 'body', 'bodyWrapper', 'advancedWrapper', 'recipients', 'recipientLabel', 'checkbox']

  static values = { emailSubjectLabel: String,
                    securedPageSubjectLabel: String,
                    emailSubjectPlaceholder: String,
                    securedPageSubjectPlaceholder: String,
                    recipientsPlaceholderSingular: String,
                    recipientsPlaceholderPlural: String,
                    recipientsLabelSingular: String,
                    recipientsLabelPlural: String
                  }

  connect() {
    this.updateForm();
    this._makeDeliveryOptionsClickable();
    setTimeout(() => {
      this.updateRecipientsWrapper();
    }, 500);
  }

  updateForm() {
    this._updateInputs();
    this._updateRadioButtons();
    setTimeout(() => {
      this.updateRecipientsWrapper();
    }, 500);

  }

  updateRecipientsWrapper() {
    const checkbox = this.checkboxTarget;
    const selectizeElement= this.recipientsTarget.selectize;
    const callToAction = document.querySelectorAll('[data-category="call_to_actions"]')[0];
    if(checkbox.checked && this._mailing_resource_delivery() != 'secured_page') {
      this.recipientLabelTarget.innerText = this.recipientsLabelSingularValue;
      selectizeElement.settings.placeholder = this.recipientsPlaceholderSingularValue;
      selectizeElement.updatePlaceholder();
      callToAction.classList.remove('d-none');

      var selectedItems = selectizeElement.items;

      if (selectedItems.length > 1) {
        var firstItem = selectedItems[0];
        selectizeElement.removeItem(firstItem);
      }

    } else {
      this.recipientLabelTarget.innerText = this.recipientsLabelPluralValue;
      selectizeElement.settings.placeholder = this.recipientsPlaceholderPluralValue;
      selectizeElement.updatePlaceholder();
      callToAction.classList.add('d-none');
    }
    var event = new Event('trix-change', { bubbles: true });
    this.bodyTarget.dispatchEvent(event);

  }

  _makeDeliveryOptionsClickable() {
    document.querySelectorAll('.mailing_resource_delivery .radio').forEach(radio => {
      radio.addEventListener('click', () => {
        radio.querySelector('input').click();
      });
    });
  }
  _mailing_resource_delivery(){
    const selectedOption = this.deliveryTargets.find(radio => radio.checked);
    if (!selectedOption) return;

    return selectedOption.value;
  }

  _updateInputs() {
    const deliveryValue = this._mailing_resource_delivery();
    if (!deliveryValue) return;

    switch (deliveryValue) {
      case 'sms':
        this._displaySmsOptions();
        break;
      case 'email':
        this._displayEmailOptions();
        break;
      case 'secured_page':
        this._displaySecuredPageOptions();
        break;
    }
  }

  _displaySmsOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { hide(previewButton) };
    if (this.hasSubjectTarget) { hide(this.subjectTarget) };
    if (this.hasAttachmentsTarget) { hide(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { hide(this.photoTarget) };
    if (this.hasPreviewButtonTarget) { hide(this.previewButtonTarget) };
    if (this.hasBodyTarget && this.hasAttachmentsTarget) {
      hide(this.attachmentsTarget);
      this.bodyWrapperTarget.setAttribute('delivery', 'sms');
    };
    if (this.hasAdvancedWrapperTarget) { display(this.advancedWrapperTarget) };
  }

  _displayEmailOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { hide(previewButton) };
    if (this.hasSubjectTarget) {
      display(this.subjectTarget);
      this.subjectTarget.querySelector('label').innerText = this.emailSubjectLabelValue;
      this.subjectTarget.querySelector('input').setAttribute('placeholder', this.emailSubjectPlaceholderValue);
    };
    if (this.hasAttachmentsTarget) { display(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { display(this.photoTarget) };
    this.bodyWrapperTarget.setAttribute('delivery', 'email');
    if (this.hasAdvancedWrapperTarget) { display(this.advancedWrapperTarget) };
  }

  _displaySecuredPageOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { display(previewButton) };
    if (this.hasSubjectTarget) {
      display(this.subjectTarget);
      this.subjectTarget.querySelector('label').innerText = this.securedPageSubjectLabelValue;
      this.subjectTarget.querySelector('input').setAttribute('placeholder', this.securedPageSubjectPlaceholderValue);
    };
    if (this.hasAttachmentsTarget) { hide(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { display(this.photoTarget) };
    this.bodyWrapperTarget.setAttribute('delivery', 'secured_page');
    if (this.hasAdvancedWrapperTarget) { hide(this.advancedWrapperTarget) };

    if (this.checkboxTarget.checked){
      this.checkboxTarget.click();
    }

  }

  _updateRadioButtons() {
    this.deliveryTargets.forEach(radio => {
      const iconWrapper = radio.nextElementSibling;
      if (iconWrapper) {
        const checkedIcon = iconWrapper.querySelector('.checked_radio_button');
        const notCheckedIcon = iconWrapper.querySelector('.not_checked_radio_button');
        if (radio.checked) {
          checkedIcon.classList.remove('d-none');
          notCheckedIcon.classList.add('d-none');
        } else {
          checkedIcon.classList.add('d-none');
          notCheckedIcon.classList.remove('d-none');
        }
      }
    });
  }
}

const display = (element) => {
  element.classList.remove('d-none');
}

const hide = (element) => {
  element.classList.add('d-none');
}
