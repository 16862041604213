import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['smallSidebar']

  openSidebar() {
    this.smallSidebarTarget.setAttribute('activated', 'true');
    addSidebarBackdrop();
  }

  closeSidebar() {
    this.smallSidebarTarget.setAttribute('activated', 'false');
    document.querySelector('.small-sidebar-backdrop').remove();
  }
}

const addSidebarBackdrop = () => {
  const backdropEl = document.createElement('div');
  backdropEl.classList.add('small-sidebar-backdrop');
  backdropEl.setAttribute('data-action', 'click->desktop--settings--resources--creation-sidebar#closeSidebar');
  document.querySelector('div[data-controller*="desktop--settings--resources--creation-sidebar"]').appendChild(backdropEl);
};
