import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'
import { applyFormResourcesFilters } from 'components/tabulator/apply_filters';
import { search } from 'utils/search';
import { setFiltersWithLocalStorage, updateFilter, selectAllElements, removeAllElements,
  toggleActive, activate, desactivate } from './utils';


export class BaseFilterController extends Controller {
  // defined in children
  static targets = []

  connect() {
    this.defineVariables()
    useClickOutside(this);
    setFiltersWithLocalStorage(this.singular, this.menuTarget)
    toggleActive(this.singular, this.selectTarget);
  }

  filter(event) {
    const current_value = event.currentTarget.dataset.selected == 'true';
    event.currentTarget.dataset.selected = !current_value;
    updateFilter(this.plural, this.menuTarget);
    applyFormResourcesFilters();
    toggleActive(this.singular, this.selectTarget);
  }

  selectAll() {
    desactivate(this.selectTarget);
    selectAllElements(this.singular);
    updateFilter(this.plural, this.menuTarget);
    applyFormResourcesFilters();
  }

  removeAll() {
    activate(this.selectTarget);
    removeAllElements(this.singular);
    updateFilter(this.plural, this.menuTarget);
    applyFormResourcesFilters();
  }

  toggleMenu() {
    const current_state = this.selectTarget.parentElement.dataset.open == 'true';
    this.selectTarget.parentElement.dataset.open = !current_state;
  }

  closeMenu() {
    this.selectTarget.parentElement.dataset.open = false;
  }

  reset() {
    this.closeMenu();
    this.selectAll();
  }

  search() {
    search(this.searchTarget.value, [...document.querySelectorAll(`.${this.singular}-filter`)]);
  }

  defineVariables() {
  }
}
