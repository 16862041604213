import { Controller } from "@hotwired/stimulus"
import { getActors } from 'pages/draggable_template/update_actor_scope';

// This controller stimulus replace the getActors() method
export default class extends Controller {
  connect () {
    getActors();
  }
}

