import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  enableSetHidden = true;

  connect() {
    this.initializeObserver();
    this.updateVisibilty();
  }

  disconnect() {
    // Disable the set_hidden updates
    // When the logic action is removed from the DOM, he removes his attributes that trigger this controller
    // We don't want to change the conditional_hidden, when we disconnect the form
    // (after edit the form -> save and display the form show)
    this.enableSetHidden = false;
  }

  initializeObserver() {
    let observer = new MutationObserver(this.observeAttributes.bind(this));
    observer.observe(this.element, { attributes: true });
  }

  observeAttributes(mutationsList) {
    for(let mutation of mutationsList) {
      let attributeName = mutation.attributeName;
      if (attributeName.includes('data-logic-action-show')) {
        this.updateVisibilty();
      }
    }
  }

  updateVisibilty() {
    const showAttributes = Object.entries(this.element.dataset).filter(([k, _v]) => k.includes('logicActionShow'));
    if (showAttributes.length == 0) {
      this.setInitialState();
    } else {
      this.isMustBeShow(showAttributes) ? this.show() : this.hide()
    }
  }

  // HERE, we have the OR relation, between condition logics and actions (with the "some" method)
  // If ONE of then is true, we want to show this element
  isMustBeShow(showAttributes) {
    return showAttributes.some(([_k, value]) => value == 'true');
  }

  setInitialState() {
    (this.element.hasAttribute('initial-hidden')) ? this.hide() : this.show();
  }

  show() {
    this.element.removeAttribute('hidden');
    this.conditionalHidden = false;
    this.launchCall()
  }

  hide() {
    this.element.setAttribute('hidden', '');
    this.conditionalHidden = true;
    this.launchCall()
  }

  launchCall() {
    // Skip call if we are in form_resource preview
    if (!this.element.hasAttribute('preview-paperwork') && this.enableSetHidden) {
      // Clear the previous waiting call
      if (this.delayedCall) { window.clearTimeout(this.delayedCall); }
      // Launch the new call
      this.delayedCall = window.setTimeout(() => { this.callToSetHidden() }, 300)
    }
  }

  callToSetHidden() {
    // Update the conditional_hidden attributes of this form_assignment
    let url = this.element.dataset.conditionallyHiddenUrl;

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'html',
      data: { conditional_hidden: this.conditionalHidden },
      error: (error) => {
        console.log('ERROR', error);
      }
    });
  }
}
