import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['list']
  static values = { futurActions: Boolean }

  connect() {
    let progress = document.getElementById('onboardee_dashboard').dataset.progress;
    progressBarDashboard(progress);
    this.observer = new MutationObserver(this.updateProgress.bind(this));
    this.observer.observe(this.listTarget, { childList: true });
  }

  updateProgress() {
    let progress;
    if (this.isListEmpty() && this.noFuturActions()){
      progress = 100;
    } else {
      progress = document.getElementById('onboardee_dashboard').dataset.progress;
    }
    progressBarDashboard(progress);
  }

  isListEmpty() {
    return this.listTarget.childElementCount === 0;
  }

  noFuturActions() {
    return this.futurActionsValue === false;
  }
}
