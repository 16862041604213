import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { applyFilter, loadTabulatorInformations } from 'components/tabulator_informations';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    displayPlaceholder();
    loadData(this.urlValue);
  }

  openResource(event) {
    // target is name div, env div, external_id div or form count div
    let target = event.currentTarget;
    // the parent is the full row, where we attached url dataset
    const parent = target.parentElement.parentElement;
    if (parent.dataset.edit == 'false') { return };

    const ctrlKey = event.metaKey || event.ctrlKey;
    target.dataset.url = parent.dataset.url;
    target.dataset.action = "click->desktop--actor--navigations#navigate";
    setTimeout(() => { clickOn(target, ctrlKey); }, 10);
  }

  export() {
    const tabulator = Tabulator.findTable('#informations')[0];
    tabulator.download('xlsx', 'export_informations.xlsx', { sheetName: 'export' });
  }
}

function loadData(url) {
  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
  .then(response => response.json())
  .then(responseJSON => {
    const resources = responseJSON.data;
    const config = responseJSON.config;
    document.getElementById('informations').outerHTML = `<div id="informations"></div>`;
    loadTabulatorInformations(resources, config);
    applyFilter();
  });
}

function displayPlaceholder() {
  document.getElementById('informations').innerHTML = document.getElementById('placeholder').innerHTML;
}

const clickOn = (target, ctrlKey = false) => {
  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    ctrlKey: ctrlKey,
    metaKey: ctrlKey // for mac
  });

  target.dispatchEvent(event);
}
