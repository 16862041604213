import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchTemplates', 'searchOrganizations', 'datePicker'];
  static values = {
    filters: Object
  };

  connect() {
    this.clickHandler = event => {
      const filterDiv = document.querySelector('div[data-controller="desktop--hr--chart-filters desktop--hr--charts--delayer"]');
      if (!filterDiv.contains(event.target)) { hideMenus() };

      const customFilterDiv = document.querySelector('div[data-controller="utils--flatpickr"]');
      const calendar = document.querySelector('.flatpickr-calendar')

      const definedDaysToElement = document.querySelector('#definedDaysTo');
      const isEmpty = definedDaysToElement && definedDaysToElement.value === '';

      if (isEmpty && !calendar.contains(event.target) && !customFilterDiv.contains(event.target)) {
        hide(document.getElementById('definedDaysFrom'));
        hide(document.getElementById('definedDaysTo'));
        hide(document.getElementById('dates-arrow'));
      };
    };

    document.addEventListener('click', this.clickHandler);
  }

  disconnect() {
    document.removeEventListener('click', this.clickHandler);
  }

  // 'filter-value' contains the value to add or remove from the filter, for each button
  // 'filter-name' contains the value of the which filter look for in the object
  toggleValue(event) {
    let filters = this.filtersValue;
    let value = event.currentTarget.dataset.filterValue;
    value = isNaN(value) ? value : +value;
    const filterName = event.currentTarget.dataset.filterName;
    const isChecked = !document.getElementById(`${filterName}-${value}-check`).classList.contains('d-none');

    if (filters['mobilities'].includes('nilMobility')) { filters['mobilities'] = [] }
    isChecked ? filters[filterName].splice(filters[filterName].indexOf(value), 1) : filters[filterName].push(value);
    if (filters['mobilities'].length == 0) { filters['mobilities'] = ['nilMobility'] }

    this.filtersValue = filters;
    document.getElementById(`${filterName}-${value}-check`).classList.toggle('d-none');

    this.hideOrDisplayTemplateSection(value);
    this.activateOrDesactiveMenu(filterName);
    // when we click on a specific value, the charts are refreshed with the delayer controller
  }

  selectAll(event) { this.updateSelection(event, 'select') }

  removeAll(event) { this.updateSelection(event, 'remove') }

  updateSelection(event, action) {
    const [filters, type] = [this.filtersValue, event.currentTarget.parentElement.dataset.type];
    const ids = [...document.querySelectorAll(`div[data-filter-name=${type}]`)].map(el => isNaN(el.dataset.filterValue) ? el.dataset.filterValue : +el.dataset.filterValue);
    const properties = {
      condition: action == 'select' ? ids.length == filters[type].length : filters[type].length == 0,
      values: action == 'select' ? ids : [null]
    }
    if (properties.condition) { return }; // prevent to refresh for nothing

    filters[type] = properties.values;
    this.filtersValue = filters;
    ids.forEach(id => {
      const check = document.getElementById(`${type}-${id}-check`);
      action == 'select' ? display(check) : hide(check);
    });
    this.refreshCharts();
    this.activateOrDesactiveMenu(type);
  }

  search(event) {
    const target = `${event.currentTarget.dataset['desktop-Hr-ChartFiltersTarget']}Target`;
    const value = this[target].value;
    const type = this[target].dataset.searchType;
    const elements = [...document.querySelectorAll(`div[data-filter-name=${type}]`)];
    elements.forEach(el => displayLine(el));
    const names = elements.map(el => el.firstElementChild.innerText)
    const unmatching_elements = [];
    names.forEach((name, i) => {
      if (!name.toLowerCase().includes(value.toLowerCase())) {
        unmatching_elements.push(elements[i]);
      }
    });
    unmatching_elements.forEach(el => hideLine(el));
  }

  refreshCharts() {
    ['track_line', 'track_horizontal_bars', 'feedback_vertical_bars'].forEach((codeName, index) => {
      this.refreshTrackOrFeedbackChart(codeName, index);
    });
    ['signature_line'].forEach((codeName, index) => {
      this.refreshSignatureChart(codeName, index);
    });
    const surveyCodenames = {
      surveyLine: 'survey_line',
      surveyVerticalBars: 'survey_vertical_bars'
    }
    Object.entries(surveyCodenames).forEach(([camelName, lowerName]) => {
      this.refreshSurveyChart(camelName, lowerName);
    });
  }

  refreshTrackOrFeedbackChart(codeName, index) {
    const controller = Stimulus.controllers
      .filter(controller => controller.chart)
      .find(el => el.chart.canvas.parentNode.attributes['data-desktop--hr--chart-url-value'].value.match(codeName));

    if (controller) {
      controller.element.setAttribute('loading', true);

      $.ajax({
        url: `/${locale}/actor/analyse/dashboard/data`,
        type: 'POST',
        data: {
          filters: this.filtersValue,
          code_name: codeName,
          index: index
        },
        dataType: 'json'
      }).then(data => {
        handleDisplay(data, controller);
        document.querySelector('.track-number').innerText = data.triggered_tracks_count

        // to prevent this code to be executed for every graph we use the index
        // we refresh the pils, level orga & email feedback dropdowns
        if (+index == 0) {
          document.getElementById('pils').innerHTML = data.pils
          document.getElementById('email-feedbacks-subfilter').innerHTML = data.email_feedbacks
          document.getElementById('level-organizations-subfilter').innerHTML = data.level_organizations
        }
      })
    }
  }

  refreshSurveyChart(camelName, lowerName) {
    document.querySelectorAll(`[data-desktop--hr--chart-code-name-value="${camelName}"]`).forEach(surveyChart => {
      const surveyQuestionResourceId = surveyChart.dataset.surveyQuestionResourceId;

      let controller = Stimulus.controllers
        .filter(controller => controller.chart)
        .filter(controller => controller.chart.canvas.parentNode.hasAttribute('data-survey-question-resource-id'))
        .find(el => el.chart.canvas.parentNode.attributes['data-survey-question-resource-id'].value == surveyQuestionResourceId);

        controller.element.setAttribute('loading', true);

      $.ajax({
        url: `/${locale}/actor/analyse/dashboard/data`,
        type: 'POST',
        data: {
          filters: this.filtersValue,
          code_name: lowerName,
          survey_question_resource_id: surveyQuestionResourceId
        },
        dataType: 'json'
      }).then(data => {
        handleDisplay(data, controller);
      })
    });
  }

  refreshSignatureChart(codeName, index) {
    const controller = Stimulus.controllers
      .filter(controller => controller.chart)
      .find(el => el.chart.canvas.parentNode.attributes['data-desktop--hr--chart-url-value'].value.match(codeName));

    if (controller) {
      controller.element.setAttribute('loading', true);

      $.ajax({
        url: `/${locale}/actor/analyse/signatures/data`,
        type: 'POST',
        data: {
          filters: this.filtersValue,
          code_name: codeName,
          index: index
        },
        dataType: 'json'
      }).then(data => {
        handleDisplay(data, controller);
        document.querySelector('.track-number').innerText = data.triggered_tracks_count

        // to prevent this code to be executed for every graph we use the index
        // we refresh the pils, level orga & email feedback dropdowns
        if (+index == 0) {
          document.getElementById('level-organizations-subfilter').innerHTML = data.level_organizations
        }
      })
    }
  }

  toggleMenu(event) {
    const menu = document.getElementById(`${event.currentTarget.dataset.menu}-menu`);
    if (menu.classList.contains('menu-opened')) {
      hideMenu(menu);
    } else {
      hideMenus();
      displayMenu(menu);
    }
  }

  selectDate(event) {
    hide(document.getElementById('definedDaysFrom'));
    hide(document.getElementById('definedDaysTo'));
    hide(document.getElementById('dates-arrow'));
    document.getElementById('definedDaysFrom').classList.remove('active');
    document.getElementById('definedDaysTo').classList.remove('active');
    document.querySelector('button[data-menu=dates] .active').classList.remove('active');
    event.currentTarget.classList.add('active');
    const dateScope = event.currentTarget.dataset.dateScope;
    const filters = this.filtersValue;
    if (dateScope == filters.dates) { return }; // prevent to refresh for nothing

    filters.dates = dateScope;
    this.filtersValue = filters;
    this.refreshCharts();
  }

  displayCalendar() {
    display(document.getElementById('definedDaysFrom'));
    display(document.getElementById('definedDaysTo'));
    display(document.getElementById('dates-arrow'));
    if (document.getElementById('definedDaysTo').value == '') {
      document.getElementById('definedDaysFrom').click();
    } else {
      document.querySelector('button[data-menu=dates] .active').classList.remove('active');
      this.datePickerTarget.classList.add('active');
      this.updateDates([moment(document.getElementById('definedDaysFrom').value, 'Do MMM YYYY', document.querySelector('html').lang)._d,
                        moment(document.getElementById('definedDaysTo').value, 'Do MMM YYYY', document.querySelector('html').lang)._d])
    }
  }

  // called on date change from flatpicker controller
  updateDates(dates) {
    const firstDateUnchanged = localeDateFor(this.filtersValue.dates[0]) == document.getElementById('definedDaysFrom').value;
    const secondDateUnchanged = localeDateFor(this.filtersValue.dates[1]) == document.getElementById('definedDaysTo').value;
    // prevent to refresh for nothing
    if (dates.length == 2 && firstDateUnchanged && secondDateUnchanged) { return };

    document.querySelector('button[data-menu=dates] .active').classList.remove('active');
    this.datePickerTarget.classList.add('active');
    document.getElementById('definedDaysFrom').classList.add('active-date');
    document.getElementById('definedDaysTo').classList.add('active-date');
    const filters = this.filtersValue;
    filters.dates = [moment(dates[0]).format('YYYY-MM-DD'), moment(dates[1]).format('YYYY-MM-DD')];
    this.filtersValue = filters
    this.refreshCharts();
  }

  hideOrDisplayTemplateSection(mobility) {
    if (!mobilityIsFiltered(mobility)) { return }

    const mobilitySection = document.querySelector(`div[data-mobility-template=${mobility}]`);
    isHidden(mobilitySection) ? display(mobilitySection) : hide(mobilitySection);
    this.updateTemplates(mobility);
  }

  // when we select/unselect a mobility this method :
  // 1. add/remove all the id in the state
  // 2. display/hide the checks
  updateTemplates(mobility) {
    if (!mobilityIsFiltered(mobility)) { return };

    const filters = this.filtersValue;
    const mobilitySection = document.querySelector(`div[data-mobility-template=${mobility}]`);
    const ids = [...mobilitySection.querySelectorAll('div[data-filter-name=templates]')].map(el => el.dataset.filterValue);
    isHidden(mobilitySection) ? unselectAndHide(ids, filters) : selectAndDisplay(ids, filters);
    this.filtersValue = filters;
  }

  activateOrDesactiveMenu(filterName) {
    let filterElements = this.element.querySelectorAll(`[data-filter-name=${filterName}] span[id].d-none`)
    let menu = this.element.querySelector(`button[data-menu=${filterName}]`)

    filterElements.length >= 1 ? menu.classList.add('active') : menu.classList.remove('active')
  }
}

const locale = document.querySelector('html').lang

const unselectAndHide = (ids, filters) => {
  ids.forEach(id => {
    // remove from state
    const newId = isNaN(id) ? id : +id;
    const idIndex = filters.templates.indexOf(newId);
    if (idIndex != -1) { filters.templates.splice(idIndex, 1) }
    // hide the check
    const checkSpan = document.querySelector(`span#templates-${id}-check`);
    hide(checkSpan);
  });
};

const selectAndDisplay = (ids, filters) => {
  ids.forEach(id => {
    // add to state
    const newId = isNaN(id) ? id : +id;
    if (!filters.templates.includes(newId)) { filters.templates.push(newId) }
    // display the check
    const checkSpan = document.querySelector(`span#templates-${id}-check`);
    display(checkSpan);
  });
};

const mobilityIsFiltered = (value) => {
  return ['onboarding', 'crossboarding', 'offboarding'].includes(value)
}

const hideMenus = () => {
  menus().forEach(menu => {
    menu.classList.remove('menu-opened');
    menu.classList.add('menu-closed');
  });
};

const menus = () => {
  return [document.getElementById('mobilities-menu'), document.getElementById('organizations-menu'), document.getElementById('templates-menu'), document.getElementById('environments-menu')].filter(el => el != null);
};

const displayMenu = (menu) => {
  menu.classList.remove('menu-closed');
  menu.classList.add('menu-opened');
};

const hideMenu = (menu) => {
  menu.classList.remove('menu-opened');
  menu.classList.add('menu-closed');
};

const hide = (element) => {
  element.classList.add('d-none');
};

const hideLine = (element) => {
  element.classList.remove('d-flex');
  element.classList.add('d-none');
};

const displayLine = (element) => {
  element.classList.remove('d-none');
  element.classList.add('d-flex');
};

const display = (element) => {
  element.classList.remove('d-none');
};

const isHidden = (element) => {
  return element.classList.contains('d-none');
};

export const handleDisplay = (data, controller) => {
  let element = controller.element;
  let blankStates = element.parentElement.querySelectorAll('div[data-element=blank');

  if (data.datasets.length == 0) {
    if (blankStates.length == 0) { element.insertAdjacentHTML('afterend', document.getElementById('graph-blank-state').innerHTML); }
    element.classList.add('d-none');
    element.setAttribute('loading', false);
  } else {
    if (blankStates.length != 0) { blankStates.forEach((el) => el.remove()) }
    element.classList.remove('d-none');
    if (controller.chart) { controller.chart.destroy() };
    controller.draw(data);
  }
};

const localeDateFor = date => {
  const lang = document.querySelector('html').lang || 'en';
  return moment(date).locale(lang).format('DD MMM Y').replaceAll('.', '')
};
