import { BaseFilterController } from "./base_filter_controller";
import {
  setPagesNumber,
  getFilters,
  updateTracksCount,
  refreshTabulator
} from "./utils";
import { flatpickrFilter } from '../../../../../components/flatpickr';

export default class extends BaseFilterController {
  static targets = ["select", "menu", "followingDays", "previousDays", "definedDays", "from", "to"];

  connect() {
    flatpickrFilter();
  }

  filter(event) {
    // reinitialize definedDays if set before
    document.getElementById('definedDaysFrom').value = '';
    document.getElementById('definedDaysTo').value = '';

    this.filters = getFilters();
    let range;
    if (event.target === this.followingDaysTarget) {
      range = dateRange(31, new Date());
    } else if ((event.target === this.previousDaysTarget)) {
      range = dateRange(31, new Date(new Date().setDate(new Date().getDate() - 30)));
    }
    this.filters.column_filters.date = range;
    this.setFilters('date', range);
    this.applyFilters();
    refreshTabulator();
    this.toggleButtonActive();
    setPagesNumber();
    updateTracksCount();
  }

  toggleCalendar() {
    this.fromTarget.click();
  }

  setRange() {
    this.filters = getFilters();
    if (this.fromTarget.value != '' && this.toTarget.value != '') {
      this.definedDaysTarget.checked = true;
      // the dataset dateFormat is set in the flatpickr.js file, at the change event
      // It allows us to work with correct date format for the calculation
      const size = ((new Date(this.toTarget.dataset.dateFormat) - new Date(this.fromTarget.dataset.dateFormat)) / dayInMs()) + 1;
      const range = dateRange(size, new Date(this.fromTarget.dataset.dateFormat));
      this.filters.column_filters.date = range;
      this.setFilters('date', range);
      this.applyFilters();
      this.toggleButtonActive();
      setPagesNumber();
      updateTracksCount();
    }
  }

  resetFilter() {
    [this.followingDaysTarget, this.previousDaysTarget, this.definedDaysTarget].forEach(input => input.checked = false);
    [this.fromTarget, this.toTarget].forEach(input => input.value = '');
    this.setFilters('date', []);
    this.applyFilters();
    this.toggleButtonActive();
    setPagesNumber();
    updateTracksCount();
  }

  column() {
    return 'date';
  }
}

function dateRange(size, from) {
  let fromInteger = from.setDate(from.getDate());
  const range = [];

  for (let index = 0; index < size; index++) {
    const stringDate = moment(new Date(fromInteger)).format('YYYY-MM-DD');
    range.push(stringDate);
    fromInteger += dayInMs();
  }
  return range;
}

function dayInMs() {
  return 1000 * 60 * 60 * 24;
}
