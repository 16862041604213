import { Controller } from "@hotwired/stimulus"

export default class IdentificationResourceDataController extends Controller {
  static targets = [
    'validation', 'validationRegex', 'validationRequired',
    'collectionValues', 'collectionValueInput'
  ]

  updateDataForm() {
    const select = document.getElementById("identification_resource_data");
    const value = select.value

    if (value == 'free_field' || value == 'date') {
      this.hideValidationSection();
      this.hideCollectionValuesSection();

    } else if (value == 'validated_field') {
      this.validationTarget.style.display = 'block';
      this.hideCollectionValuesSection();

    } else if (value == 'collection') {
      this.hideValidationSection();
      this.collectionValuesTarget.style.display = 'block';

    }
  }

  hideValidationSection() {
    this.validationTarget.style.display = 'none';
    this.validationRegexTarget.value = '';
    this.validationRequiredTarget.checked = false;
  }

  hideCollectionValuesSection() {
    this.collectionValuesTarget.style.display = 'none';
    this.collectionValueInputTargets.forEach(input => {
      input.click();
    });
  }
}
