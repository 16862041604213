import SwalController from "./swal_controller";

export default class extends SwalController {
  static values = { text: Object, confirm: Object, cancel: Object, buttonType: String }

  confirm(event) {
    super.confirm(event);
  }

  beforeEnd(result) {
    if (result.isConfirmed) {
      const input = '<input type="hidden" name="finalized" id="finalized" value="true">'
      document.getElementById('handover-texts').insertAdjacentHTML('beforeend', input);
      document.getElementById('handover-texts').submit();
    }
  }
}
