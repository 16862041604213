function prevQuestion(event, position) {
  if (position != 1) {
    event.preventDefault();
    $('#quiz-page-' + position).addClass('d-none');
    $('#quiz-page-' + (position - 1)).removeClass('d-none');

    window.scrollTo(0, 0);
  }
}
export { prevQuestion }
