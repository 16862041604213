import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import { getStorage, setStorage, buildConditionalLogicHtml, newId } from 'packs/controllers/form_resources_controller';
import { stringToHTML, htmlToString } from "utils/convert_html";

export default class extends Controller {
  static values = {
    id: String,
    position: Number,
    logicConditions: Array,
    logicActions: Array,
    conditionRelation: String
  }
  static targets = [ 'logicConditionList', 'logicActionList' ]

  relationTypes = JSON.parse(document.getElementById('logic-condition-relation-types').value);
  newAction = {
    id: newId(),
    operator_type: 'show',
    field_id: ''
  }
  newCondition = {
    id: newId(),
    operator_type: 'show',
    field_id: '',
    value: '',
    collection: "[]"
  }
  enableStoring = false;
  currentRelation = this.conditionRelationValue;

  initialize() {
    useClickOutside(this);
    this.appendLogicConditions();
    this.appendLogicActions();
    this.enableStoring = true;
    this.initializeSavingObserver();
    this.initializeChildrenObservers();
    this.initializeConditionRelationObservers();
    setTimeout(this.storeConditionLogic, 300);
  }

  stickDropdown() {
    this.element.setAttribute('dropdown-focus', '');
  }

  clickOutside() {
    this.hideDropdown();
    this.element.removeAttribute('dropdown-focus');
  }

  hideDropdown() {
    $(this.element.querySelector('[data-toggle=dropdown]')).dropdown('hide');
  }

  appendLogicConditions() {
    this.logicConditionsValue.forEach((logicCondition) => {
      let html = this.buildLogicCondition(logicCondition);
      this.logicConditionListTarget.insertAdjacentHTML('beforeend', html);
    })
  }

  appendLogicActions() {
    this.logicActionsValue.forEach((logicAction) => {
      let html = this.buildLogicAction(logicAction);
      this.logicActionListTarget.insertAdjacentHTML('beforeend', html);
    })
  }

  buildLogicCondition(logicCondition) {
    let templateHtml = document.getElementById('logic-condition-template').innerHTML;
    templateHtml = templateHtml.replaceAll('{{{conditionalLogicId}}}', this.idValue);
    templateHtml = templateHtml.replaceAll('{{{id}}}', logicCondition.id);
    templateHtml = templateHtml.replaceAll('{{{field}}}', logicCondition.field_id);
    templateHtml = templateHtml.replaceAll('{{{operatorType}}}', logicCondition.operator_type);
    templateHtml = templateHtml.replaceAll('{{{value}}}', logicCondition.value);
    let html = stringToHTML(templateHtml);
    html.dataset.logicConditionsCollectionValue = logicCondition.collection || "[]";
    templateHtml = htmlToString(html);

    return templateHtml
  }

  buildLogicAction(logicAction) {
    let templateHtml = document.getElementById('logic-action-template').innerHTML;
    templateHtml = templateHtml.replaceAll('{{{conditionalLogicId}}}', this.idValue);
    templateHtml = templateHtml.replaceAll('{{{id}}}', logicAction.id);
    templateHtml = templateHtml.replaceAll('{{{operatorType}}}', logicAction.operator_type);
    templateHtml = templateHtml.replaceAll('{{{field}}}', logicAction.field_id);

    return templateHtml
  }

  duplicate() {
    let newAttributes = this.toJSON()
    newAttributes.id = newId();
    newAttributes.logic_conditions.forEach((el) => el.id = newId())
    newAttributes.logic_actions.forEach((el) => el.id = newId())
    let html = buildConditionalLogicHtml(newAttributes);

    this.element.insertAdjacentHTML('afterend', html);
    this.clickOutside();
  }

  remove() {
    let fId = this.formId();
    let storage = getStorage(fId);
    this.element.remove();
    let storedConditionLogics = storage.conditional_logics;
    storage.conditional_logics = storedConditionLogics.filter(conditionLogic => conditionLogic.id !== this.idValue);

    setStorage(fId, storage);
  }

  addLogicAction(event) {
    let logicAction = event.target.closest('[logic-action]')
    let html = this.buildLogicAction(this.newAction);
    logicAction.insertAdjacentHTML('afterend', html);
  }

  addLogicCondition(event) {
    let logicCondition = event.target.closest('[logic-condition]')
    let html = this.buildLogicCondition(this.newCondition);
    logicCondition.insertAdjacentHTML('afterend', html);
  }

  toJSON() {
    return {
      id: this.idValue,
      logic_conditions: this.logicConditionsToJSON(),
      logic_actions: this.logicActionsToJSON(),
      condition_relation: this.conditionRelationValue,
      position: this.positionValue
    }
  }

  logicConditionsToJSON() {
    const conditionIds = Array.from(this.element.querySelectorAll('[logic-condition]')).map(el => el.id)
    const conditionControllers = Stimulus.controllers.filter(el => el.identifier == 'logic-conditions')
                                                     .filter(el => conditionIds.includes(el.element.id))
    return conditionControllers.map((el) => el.toJSON())
  }

  logicActionsToJSON() {
    const actionIds = Array.from(this.element.querySelectorAll('[logic-action]')).map(el => el.id)
    const actionControllers = Stimulus.controllers.filter(el => el.identifier == 'logic-actions')
                                                  .filter(el => actionIds.includes(el.element.id))
    return actionControllers.map((el) => el.toJSON())
  }

  // To avoid spamming, we observe the actions and conditions
  // When all elements are ready, we store the conditional logic
  initializeSavingObserver() {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === "data-ready-to-store") {
          this.storeConditionLogic();
        }
      });
    });

    // Monitor children's modifications
    observer.observe(this.element, { subtree: true, attributes: true });
  }

  initializeChildrenObservers() {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        // if the list is empty, we remove the conditonal logic
        if (mutation.target.children.length == 0) { this.remove(); }
      });
    });

    observer.observe(this.logicConditionListTarget, { childList: true });
    observer.observe(this.logicActionListTarget, { childList: true });
  }

  elementsAreReadyToStore = () => {
    const elements = Array.from(this.element.querySelectorAll('[data-ready-to-store]'));
    return elements.every((child) => child.dataset["readyToStore"] === 'true');
  }

  storeConditionLogic() {
    if (this.enableStoring && this.elementsAreReadyToStore()) {
      let storage = getStorage(this.formId());
      let storedConditionLogics = storage.conditional_logics || (storage.conditional_logics = []);
      let conditionLogic = this.toJSON();
      let storedConditionLogic = storedConditionLogics.find(el => { return el.id == this.idValue });

      if (storedConditionLogic) {
        storedConditionLogic.logic_conditions = conditionLogic.logic_conditions;
        storedConditionLogic.logic_actions = conditionLogic.logic_actions;
        storedConditionLogic.condition_relation = conditionLogic.condition_relation;
      } else {
        storedConditionLogics.push(conditionLogic);
      }

      setStorage(this.formId(), storage);
    }
  }

  initializeConditionRelationObservers() {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName.includes('condition-relation')) {
          this.updateConditionRelationTargets();
        }
      });
    });

    observer.observe(this.element, { attributes: true });
  }

  updateConditionRelationTargets() {
    if (this.currentRelation != this.conditionRelationValue) {
      this.currentRelation = this.conditionRelationValue;
      this.element.querySelectorAll('[data-logic-conditions-target="conditionRelation"]').forEach(el => el.selectize.setValue(this.conditionRelationValue));
      this.storeConditionLogic();
    }
  }

  formId() {
    let form = this.element.closest('[data-controller=form-resources]');
    return form.dataset.formResourcesIdValue;
  }
}
