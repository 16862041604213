import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    id: String,
    formResource: Object,
    url: String
  }

  connect() {
    const formResource = this.hasformResourceValue ? this.formResourceValue : JSON.parse(localStorage.getItem(`form_${this.idValue}`));
    $.ajax({
      url: this.urlValue,
      type: 'POST',
      dataType: 'html',
      data: { local_storage: formResource },
      error: (error) => {
        console.log('ERROR', error);
      },
      success: (response) => {
        this.element.outerHTML = response;
      }
    });
  }
}
