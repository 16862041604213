export function checkPasswordLenght(input) {
  if (passwordIsLongEnough(input.value)) {
    markMessageAsValid(input, 'check-length');
  } else {
    markMessageAsError(input, 'check-length');
  }
  if (passwordIsValid(input.value)) { markInputAsValid(input) } else { markInputAsError(input) }
}

export function checkPasswordVariety(input) {
  if (passwordIsVariedEnough(input.value)) {
    markMessageAsValid(input, 'check-variety');
  } else {
    markMessageAsError(input, 'check-variety');
  }
  if (passwordIsValid(input.value)) { markInputAsValid(input) } else { markInputAsError(input) }
}

export function checkUniformity(passwordInput, confirmationInput) {
  if (passwordConfirmationValid(passwordInput.value, confirmationInput.value)) {
    markMessageAsValid(confirmationInput, 'check-uniformity');
    markInputAsValid(confirmationInput)
    displayElement('#conditions-block');
  } else if (confirmationInput.value != '') {
    markMessageAsError(confirmationInput, 'check-uniformity');
    markInputAsError(confirmationInput)
  }
}

export function displayElement(selector) {
  let element = document.querySelector(selector);
  if (element) {
    replaceClasses(element, 'invisible', 'visible')
  }
}

export function passwordIsValid(value) {
  return (passwordIsVariedEnough(value)) && (passwordIsLongEnough(value));
}

export function passwordConfirmationValid(password, passwordConfirmation) {
  return (password === passwordConfirmation) && (passwordIsValid(password));
}

export function passwordIsVariedEnough(value) {
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d])/;
  return (value.match(regex));
}

export function passwordIsLongEnough(value) {
  return (value.length >= 12);
}

export function markMessageAsError(input, checkId){
  replaceClasses('#' + checkId, ['text-gray', 'text-grey-700', 'text-green'], 'text-danger-400');
  replaceClasses('#' + checkId + ' i', 'fa-check', 'fa-times');
}

export function markMessageAsValid(input, checkId){
  replaceClasses('#' + checkId, ['text-gray', 'text-grey-700', 'text-danger-400'], 'text-green');
  replaceClasses('#' + checkId + ' i', 'fa-times', 'fa-check');
}
export function markInputAsError(input) {
  replaceClasses(input, 'border-color-green', 'border-color-danger-400');
}

export function markInputAsValid(input) {
  replaceClasses(input, 'border-color-danger-400', 'border-color-green');
}

export function hideError(elementId) {
  replaceClasses('#' + elementId, 'visible', 'invisible');
}

export function displayError(elementId) {
  replaceClasses('#' + elementId, 'invisible', 'visible');
}

function replaceClasses(selector, oldClasses, newClasses) {
  $(selector).removeClass(oldClasses);
  $(selector).addClass(newClasses);
}
