import { BaseFilterController } from "./base_filter_controller";

export default class extends BaseFilterController {
  static targets = ["select", "menu", "building", "pending_invitation", "in_progress", "upcoming_invitation"];

  controller() {
    return 'Status';
  }

  column() {
    return 'status';
  }

  // return the controller name in html
  htmlTarget() {
    return 'status';
  }
}
