import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["point", "link"]

  load(event) {
    updateLinks(event.currentTarget, this.pointTarget, this.linkTargets);
    waitingList();
    loadContentFor(event.currentTarget);

    const selector = document.querySelector("#track-selector")
    if (selector){
      const regex = /temporality=\w+/;
      const temporality = event.currentTarget.dataset.temporality;
      const link = selector.href.replace(regex, '');
      selector.href = `${link}?temporality=${temporality}`;
    }
  }
}

function waitingList() {
  $('#action-list').html('<div class="text-center padded-top-20px"><i class="fad fa-spinner text-gray text-40px rotating"></i><div>');
}

function updateLinks(target, point, links) {
  point.remove();
  $(links).addClass('text-gray')
  $(target).append(point).removeClass('text-gray');
}

function loadContentFor(target) {
  let url = target.dataset.url
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    data: { 'origin': 'temporality' },
    success: (data) => {
      $('#action-block').replaceWith(data['content']);
    }
  });
}
