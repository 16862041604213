import { Controller } from "@hotwired/stimulus";
import * as applyFilters from '../../../../../javascript/components/tabulator/apply_filters';

export default class extends Controller {
  static targets = ['input', 'cleanQuery']
  static values = {
    page: String
  }

  connect() {
    const savedQuery = localStorage.getItem(`${this.pageValue}_query`);
    this.inputTarget.value = savedQuery;
    this.toggleIcon();
  }

  query() {
    window.clearTimeout(this.searchTimer);
    this.searchTimer = window.setTimeout(() => {
      this.toggleIcon();
      this.updateFilter();
      this.applyFilter();
    }, parseInt(500));
  }

  cleanQuery() {
    this.inputTarget.value = '';
    this.query();
  }

  updateFilter() {
    localStorage.setItem(`${this.pageValue}_query`, this.inputTarget.value);
  }

  toggleIcon() {
    if (this.inputTarget.value.length > 0) {
      this.cleanQueryTarget.classList.remove('invisible');
    } else {
      this.cleanQueryTarget.classList.add('invisible');
    }
  }

  applyFilter() {
    const functionToCall = `apply${capitalizeWords(this.pageValue)}Filters`;
    applyFilters[functionToCall]();
  }
}

const capitalizeWords = string => {
  return string.replace(/(?:^|_)\S/g, a => { return a.toUpperCase(); }).replace('_', '')
};
