// Bottom loading - Mobile
function initBottomLoading(action_path, onboardee_id) {
  // define loading
  function bottomLoading(event, action_path, onboardee_id) {
    var scrollHeight = Math.floor($(document).height());
    var scrollPosition = Math.floor($(window).height() + $(window).scrollTop());

    if (scrollHeight - scrollPosition === 0) {
      stopBottomLoading();
      addNextActions(event, action_path, onboardee_id);
    }
  }

  // initialize loading
  $(document).on("touchmove", function(event) {
    bottomLoading(event, action_path, onboardee_id)
  });
  $(document).on("scroll", function(event) {
    bottomLoading(event, action_path, onboardee_id)
  });

  // call loading
  bottomLoading(event, action_path, onboardee_id)
}
export { initBottomLoading }


function stopBottomLoading() {
  $(document).off("touchmove");
  $(document).off("scroll");
}
export { stopBottomLoading }
