function updateBoardeeActions(action) {
  const availableActionsCounter = document.getElementById('available-actions-counter');
  const doneActionsCounter = document.getElementById('done-actions-counter');
  const timelineSection = action.closest('.timeline-section');
  // TODO: Remove this condition when v1 is removed
  if (!timelineSection) {
    action.remove();
    return 'exit_v1'; // Exit if v1
  }

  displayCheckmarkAnimation(action);

  setTimeout(() => {
    action.classList.add('fade-out');

    updateFirstActionHandler(action);

    updateBlockingActions();

    moveUpNextElements(action, timelineSection)

    updateDoneActions(action);

    updateCounter(availableActionsCounter, -1);

    updateCounter(doneActionsCounter, 1);

    updateProgressBar(doneActionsCounter, availableActionsCounter);
  }, 2000);
}

function displayCheckmarkAnimation(action) {
  setTimeout(() => {
    const actionHandler = action.querySelector('.action-handler');
    const checkCircleComponent = document.querySelector('#check-circle-component')?.innerHTML;
    if (checkCircleComponent) {
      actionHandler.innerHTML = checkCircleComponent;
    }
  }, 750);
}

function updateFirstActionHandler(action) {
  if (action.classList.contains('first-action')) {
    let nextAction = action.nextElementSibling;
    while (nextAction && nextAction.classList.contains('fade-out')) {
      nextAction = nextAction.nextElementSibling;
    }
    if (nextAction) {
      nextAction.classList.add('first-action');
      displayFirstActionHandler(nextAction);
    }
  }
}

function updateBlockingActions() {
  const missingCompletionActions = document.getElementById('missing-completion-actions');
  if (missingCompletionActions && missingCompletionActions.querySelectorAll('.user-action:not(.fade-out)').length === 0) {
    const missingValidationActions = document.getElementById('missing-validation-actions');
    missingValidationActions.classList.remove('d-none');
  }
}

function displayFirstActionHandler(action) {
  const firstActionState = action.querySelector('.action-handler .first-action-state')
  if (firstActionState) {
    firstActionState.classList.remove('d-none');
  }
  const regularActionState = action.querySelector('.action-handler .regular-action-state')
  if (regularActionState) {
    regularActionState.classList.add('d-none');
  }
}

function moveUpNextElements(action, timelineSection) {
  if (timelineSection && timelineSection.querySelectorAll('.user-action:not(.fade-out').length === 0) {
    timelineSection.classList.add('fade-out');
    updateFirstActionHandlerInNextTimelineSection(action, timelineSection)
    moveUpNextSiblings(timelineSection, timelineSection);
  } else {
    moveUpNextSiblings(action, action);
    moveUpNextSiblings(timelineSection, action);
  }
}

function updateFirstActionHandlerInNextTimelineSection(action, timelineSection) {
  if (action.classList.contains('first-action')) {
    let nextTimelineSection = timelineSection.nextElementSibling;
    while (nextTimelineSection && nextTimelineSection.classList.contains('fade-out')) {
      nextTimelineSection = nextTimelineSection.nextElementSibling;
    }

    if (nextTimelineSection) {
      const nextAction = nextTimelineSection.querySelector('.user-action:not(.fade-out)');
      if (nextAction) {
        nextAction.classList.add('first-action');
        displayFirstActionHandler(nextAction);
      }
    } else {
      const availableSection = document.getElementById('available');
      const emptyStateContent = document.querySelector('#empty-state-content').innerHTML;
      availableSection.innerHTML = emptyStateContent;
    }
  }
}

function moveUpNextSiblings(element, removedElement) {
  removedElement.style.height = `${removedElement.offsetHeight}px`; // Prevent the removed element from adjusting its height if the user reduces the screen width
  let nextSibling = element.nextElementSibling;
  while (nextSibling) {
    let totalMoveUpDistance = 0;
    if (nextSibling.style.getPropertyValue('--move-up-distance')) {
      totalMoveUpDistance = parseFloat(nextSibling.style.getPropertyValue('--move-up-distance')) || 0;
    }

    // Calculate the distance to move up the next sibling
    let moveUpDistance;
    if (removedElement.classList.contains('timeline-section')) {
      moveUpDistance = timelineSectionOffsetDistance(removedElement);
    } else {
      moveUpDistance = actionOffsetDistance(removedElement);
    }

    // Update the total move up distance and set the new values for the CSS animation
    totalMoveUpDistance -= moveUpDistance
    nextSibling.style.setProperty('--move-up-distance', `${totalMoveUpDistance}px`);
    nextSibling.style.setProperty('--move-up-initial-position', `${totalMoveUpDistance + moveUpDistance}px`);
    nextSibling.classList.add('move-up');

    // Remove .move-up class after the animation ends to prevent it from executing again
    // when the user goes back to the 'available actions' tab
    const currentSibling = nextSibling;
    currentSibling.addEventListener('animationend', function () {
      currentSibling.style.transform = `translateY(${totalMoveUpDistance}px)`;
      currentSibling.classList.remove('move-up');
    });

    nextSibling = nextSibling.nextElementSibling;
  }
}

function timelineSectionOffsetDistance(timelineSection) {
  // When a timeline section is removed, the next elements must move up by the height of its
  // last remaining action + the height of its title + its margin bottom
  const actionCards = timelineSection.querySelectorAll('.user-action');
  const lastAction = actionCards[actionCards.length - 1];
  const lastActionHeight = lastAction.offsetHeight;
  const lastActionMarginBottom = parseFloat(getComputedStyle(lastAction).marginBottom);
  const timelineSectionTitle = timelineSection.querySelector('.title');
  const timelineSectionTitleHeight = timelineSectionTitle.offsetHeight;
  const timelineSectionMarginBottom = parseFloat(getComputedStyle(timelineSection).marginBottom);
  return lastActionHeight + lastActionMarginBottom + timelineSectionTitleHeight + timelineSectionMarginBottom;
}

function actionOffsetDistance(action) {
  // When an action is removed, the next elements must move up by the height of the action + its margin bottom
  const actionHeight = action.offsetHeight;
  const actionMarginBottom = parseFloat(getComputedStyle(action).marginBottom);
  return actionHeight + actionMarginBottom;
}

function updateDoneActions(action) {
  const url = action.getAttribute('data-url-to-refresh-done-actions');
  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    document.getElementById('done').innerHTML = data.timeline_html;
  }).catch(error => {
    console.log(error);
  })
}

function updateCounter(counterElement, increment) {
  if (counterElement) {
    const count = parseInt(counterElement.dataset.count) + increment;
    counterElement.dataset.count = count;
    counterElement.innerText = count > 10 ? '10+' : count.toString();
  }
}

function updateProgressBar(doneActionsCounter, availableActionsCounter) {
  if (doneActionsCounter && availableActionsCounter) {
    const futureActionsCounter = document.getElementById('future-actions-counter');
    const doneActionsCount = parseInt(doneActionsCounter.dataset.count);
    const availableActionsCount = parseInt(availableActionsCounter.dataset.count);
    const futureActionsCount = parseInt(futureActionsCounter.dataset.count);
    const progress = Math.round(doneActionsCount / (doneActionsCount + availableActionsCount + futureActionsCount) * 100);
    document.getElementById('onboarding-progress').innerHTML = `
      <label>
        <progress value="${Math.max(7, progress)}" max="100" style="--color: var(--custom-color-primary);" id="onboarding-score">${progress}</progress>
        ${progress} %
      </label>
    `;
  }
}

export { updateBoardeeActions };
