import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['template']

  connect() {
    const modalId = this.element.dataset.target;
    this.modalContent = document.querySelector(`${modalId} #content`);
  }

  fill() {
    this.modalContent.innerHTML = this.templateTarget.innerHTML;
  }
}
