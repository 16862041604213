import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['template', 'content']
  static values = { url: String }

  async connect() {
    const response = await fetch(this.urlValue);
    const data = await response.json();
    this.load(data);
  }

  load(fillable_pdfs) {
    fillable_pdfs.map(fillable_pdf => {
      let template = this.templateTarget.innerHTML;
      Object.keys(fillable_pdf).forEach(key => template = template.replaceAll(`{{{${key}}}}`, fillable_pdf[key] || ''));
      this.contentTarget.insertAdjacentHTML('beforeend', template);
    })
  }
}
