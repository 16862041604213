import { Controller } from "@hotwired/stimulus"

// <div data-controller="utils--character-counters" data-utils--character-counter-limit-value="25">
//   <%= f.input :title, input_html: { "data-utils--character-counters-target": "input" } %>
//   <div>
//     <span data-utils--character-counters-target='counter'>0</span>
//     caractères sur
//     <span data-utils--character-counters-target='limit'>25</span>
//   </div>
// </div>

export default class extends Controller {
  static targets = ['input', 'counter', 'limit']
  static values = { limit: Number }

  connect() {
    this.inputTarget.addEventListener('input', this.updateCounter.bind(this));
  }

  updateCounter() {
    this.counterTarget.innerText = this.inputTarget.value.length;
    if (this.hasLimitTarget && this.hasLimitValue) { this.toggletLimitError(); }
  }

  toggletLimitError() {
    (this.inputTarget.value.length > this.limitValue) ? this.addLimitError() : this.removeLimitError();
  }

  addLimitError() {
    this.inputTarget.classList.add('border-color-danger-400');
    this.counterTarget.parentNode.classList.add('text-danger-400')
  }

  removeLimitError() {
    this.inputTarget.classList.remove('border-color-danger-400');
    this.counterTarget.parentNode.classList.remove('text-danger-400')
  }
}
