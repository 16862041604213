import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['counter']
  static values = { channelToken: String }

  connect() {
    App[`actions-counter-${this.channelTokenValue}`] = App.cable.subscriptions.create(
      { channel: 'NotificationHandlerChannel', channel_token: this.channelTokenValue },
      {
        connected: () => {
          this.updateCounter();
        },
        received: response => {
          localStorage.setItem('late_track_ids', response.late_track_ids)
          localStorage.setItem('last_notification_update', new Date)
          this.updateCounter();
        }
      }
    )
  }

  decrement(track_id, all = false) {
    let track_ids = this.current_late_track_ids();
    const index = track_ids.indexOf(track_id.toString())
    if (index < 0) { return };

    if (all) {
      track_ids = track_ids.filter(id => id != track_id.toString())
    } else {
      track_ids.splice(index, 1)
    }
    localStorage.setItem('late_track_ids', track_ids)
    this.updateCounter();
  }

  increment(track_id) {
    const track_ids = this.current_late_track_ids();
    track_ids.push(track_id);
    localStorage.setItem('late_track_ids', track_ids)
    this.updateCounter();
  }

  updateCounter() {
    this.counterTarget.innerText = this.current_late_track_ids().length;
    this.handleDisplay();
  }

  current_late_track_ids() {
    const track_ids = localStorage.getItem('late_track_ids');
    if (track_ids) {
      return track_ids.split(',').filter(el => parseInt(el));
    } else {
      return [];
    }
  }

  handleDisplay() {
    +this.counterTarget.innerText <= 0 ? this.counterTarget.classList.add('d-none') : this.counterTarget.classList.remove('d-none')
  }
}
