import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subjectEditor", "subjectPreview", "emailEditor", "emailPreview" ]

  connect() {
    // global variables
    this.replacedEditor = '';
    this.replacedSubject = '';
    this.replacedTrix = '';

    // get variables and placeholders from the backend
    this.variables = JSON.parse(this.emailEditorTarget.dataset.variables);

    if (this.emailEditorTarget.dataset.correspondance) {
      this.correspondance = JSON.parse(this.emailEditorTarget.dataset.correspondance);
      this.changeLanguage();
    }

    this.updateEditor();
    this.updateSubject();

    let toolbar = document.getElementById('trix-toolbar-1');
    if (toolbar) {
      toolbar.addEventListener('click', (event) => {
        this.updateEditor();
      });
      toolbar.click();
    }
  }

  updateEditor() {
    this.emailPreviewTarget.innerHTML = this.emailEditorTarget.innerHTML;
    this.replacedEditor = this.emailPreviewTarget.innerHTML;
    this.updateHtmlWithVariables();
    this.emailPreviewTarget.innerHTML = this.replacedEditor;
  }

  updateSubject() {
    this.subjectPreviewTarget.innerHTML = this.subjectEditorTarget.value;
    this.replacedSubject = this.subjectPreviewTarget.innerHTML;
    this.updateHtmlWithVariables();
    if (this.subjectPreviewTarget.innerHTML === '') {
      this.subjectPreviewTarget.innerHTML = this.subjectEditorTarget.dataset.subject;
    } else {
      this.subjectPreviewTarget.innerHTML = this.replacedSubject;
    }
  }

  updateHtmlWithVariables() {
    Object.keys(this.variables).forEach(variable => {
      const reg = new RegExp(`@${variable}`, 'gi');
      if (reg.test(this.replacedEditor)) {
        this.replacedEditor = this.replacedEditor.replace(reg, this.variables[variable]);
      }
      if (reg.test(this.replacedSubject)) {
        this.replacedSubject = this.replacedSubject.replace(reg, this.variables[variable]);
      }
    });
  }

  changeLanguage() {
    this.replacedTrix = this.emailEditorTarget.innerHTML;

    const regex = new RegExp('@[a-zÀ-ÿ|_]+', 'gmi')
    const matches = this.replacedTrix.match(regex)

    if (matches) {
      const variables = [...matches];
      variables.forEach(variable => {
        Object.values(this.correspondance).forEach(value => {
          const key = Object.keys(this.correspondance).find(key => this.correspondance[key] === value)
          if (value.includes(variable.substring(1))) {
              this.replacedTrix = this.replacedTrix.replace(variable, `@${key}`)
          }
        });
      });
    }
    this.emailEditorTarget.innerHTML = this.replacedTrix;
  }
}
