import SwalController from "../swal_controller";

export default class extends SwalController {
  static values = {
    text: Object,
    confirm: Object,
    cancel: Object,
    buttonType: String,
    details: Object
  }

  confirm(event) {
    super.confirm(event);
  }

  confirmRequest(confirmValue) {
    this.handleRequest(confirmValue)
    document.querySelector('body').innerHTML = this.message();
    setTimeout(() => { window.open(this.element.dataset.redirectionUrl, '_self') }, 2000)
  }

  message() {
    if (this.element.dataset.type == 'close') {
      return document.getElementById('closedTrackMessage').innerHTML
    } else {
      return document.getElementById('cancelledTrackMessage').innerHTML
    }
  }
}
