function replaceValues(html, object) {
  Object.entries(object).forEach(entry => {
    let key = entry[0];
    let value = entry[1];

    html = html.replaceAll(key, value);
  });

  return html;
}

export { replaceValues }

export const activateScrolling = () => {
  setTimeout(()=> {
    if (!document.querySelector('.large-sidebar-backdrop')) {
      document.body.classList.remove('overflow-hidden');
    }
  }, 20)
}
