import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "selectAll", "clearAll"];
  static values = {
    items: Array
  }

  connect() {
    this.buildList();
  }

  toggleDropdown() {
    this.listTarget.classList.toggle("hidden");
  }

  toggleItem(event) {
    event.stopPropagation();
  }

  selectAll() {
    this.listTarget.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = true;
    });
  }

  clearAll() {
    this.listTarget.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  hideList() {
    this.listTarget.classList.add("hidden");
  }

  buildList() {
    const listHTML = this.itemsValue.map((item) => {
      var checked = item.checked == true ? 'checked' : '';
      return `
      <li class='list-group-item'>
        <label>
          <input type="checkbox" ${checked} value="${item.id}" data-action="change->dropdown#toggleItem" name="paperwork_ids[]" />
          ${item.name}
        </label>
      </li>
    `
    }).join("");
    this.listTarget.innerHTML = listHTML;
  }
}
