import { SelectizeEmployeesController } from "../utils/selectize_employees_controller";

export default class extends SelectizeEmployeesController {
  static values = {
    labels: String,
    initialItems: Array
  }

  connect() {
    $(this.element).selectize(this.config());
    this.setEmployees();
    this.setInitialValue();
  }

  config() {
    let options = this.basicConfig();

    return {
      ...options,
      maxItems: null
    }
  }

  // When we intialize the input, we want to retrieve the current employees
  // But the selectize intializes one option as a concatenation of all selected employees
  // We need to remove the bad option and create all the options and set them as item (selected option)
  setEmployees() {
    let selectize = this.element.selectize
    let badOption = selectize.getValue();
    let employees = JSON.parse(this.labelsValue);
    let labels = employees.map((employee) => { return employee.email });

    if (badOption) { selectize.removeOption(badOption) }
    if (labels[0]) {
      selectize.addOption(employees)
      selectize.setValue(labels)
    }
  }

  setInitialValue() {
    const selectize = this.element.selectize;
    const initialItems = this.initialItemsValue;

    initialItems.forEach(initialItem => {
      initialItem = JSON.parse(initialItem);
      if (initialItem) {
        const initialValue = initialItem[this.valueFieldValue];
        selectize.addOption(initialItem);
        selectize.addItem(initialValue);
      }
    });
  }
}
