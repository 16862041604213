// ==============================================================================
// Track Calendars
// ==============================================================================
function initCalendarD1(){
  var calendarEl = document.getElementById('calendar_d1');
  var locale = calendarEl.dataset.locale
  var employeeId = calendarEl.dataset.employee
  var startDate = calendarEl.dataset.start
  var zone = calendarEl.dataset.zone
  var current_step = calendarEl.dataset.step

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    plugins: [ timeGridPlugin ],
    header: {
      left: '',
      center: 'title',
      right: ''
    },
    views: {
      timeGridDay: {
        slotDuration: '00:30',
        minTime: '08:00',
        maxTime: '20:00'
      }
    },
    contentHeight: 'auto',
    nowIndicator: true,
    events: '/internal_api/v1/employees/' + employeeId + '/meetings?time_zone=' + zone,
    defaultDate: startDate,
    defaultView: 'timeGridDay',
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    },
    eventClick: function(info) {
      var regex = /\d+/
      var meetingId = info.el.id.match(regex)[0]
      $.ajax({
        url: '/' + locale + '/onboardees/meetings/' + meetingId,
        type: "GET",
        data: { 'devise': 'desktop' },
      });
    },
  });

  calendar.render();
}
export { initCalendarD1 }


function initCalendarW1(){
  var calendarEl = document.getElementById('calendar_w1');
  var locale = calendarEl.dataset.locale
  var employeeId = calendarEl.dataset.employee
  var startDate = calendarEl.dataset.start
  var zone = calendarEl.dataset.zone
  var current_step = calendarEl.dataset.step

  var date = new Date(startDate)
  var weekDay = date.getDay()

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    plugins: [ dayGridPlugin ],
    header: {
      left: '',
      center: 'title',
      right: 'prev,next'
    },
    contentHeight: 'auto',
    nowIndicator: true,
    events: '/internal_api/v1/employees/' + employeeId + '/meetings?time_zone=' + zone,
    defaultDate: startDate,
    firstDay: weekDay,
    defaultView: 'dayGridWeek',
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    },
    eventClick: function(info) {
      var regex = /\d+/
      var meetingId = info.el.id.match(regex)[0]
      $.ajax({
        url: '/' + locale + '/onboardees/meetings/' + meetingId,
        type: "GET",
        data: { 'devise': 'desktop' },
      });
    },
    eventPositioned: function(info) {
      info.el.setAttribute('data-toggle', 'popover');
      info.el.setAttribute('data-container', 'body');
      info.el.setAttribute('data-boundary', 'window');
      info.el.setAttribute('data-placement', 'bottom');
      info.el.setAttribute('data-trigger', 'hover');
      info.el.setAttribute('data-content', info.event.title);
      $('[data-toggle="popover"]').popover();
    },
  });

  calendar.render();
}
export { initCalendarW1 }


function initCalendarW1Mobile(){
  var calendarEl = document.getElementById('calendar_w1_mobile');
  var locale = calendarEl.dataset.locale
  var employeeId = calendarEl.dataset.employee
  var startDate = calendarEl.dataset.start
  var stepEndDate = calendarEl.dataset.end
  var zone = calendarEl.dataset.zone
  var current_step = calendarEl.dataset.step

  var date = new Date(startDate)
  var weekDay = date.getDay()

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    plugins: [ timeGridPlugin ],
    header: {
      left: '',
      center: 'title',
      right: 'prev,next'
    },
    views: {
      timeGridDay: {
        slotDuration: '00:30',
        minTime: '08:00',
        maxTime: '20:00'
      }
    },
    validRange: {
      start: startDate,
      end: stepEndDate
    },
    contentHeight: 'auto',
    nowIndicator: true,
    events: '/internal_api/v1/employees/' + employeeId + '/meetings?time_zone=' + zone,
    defaultDate: startDate,
    firstDay: weekDay,
    defaultView: 'timeGridDay',
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    },
    eventClick: function(info) {
      var regex = /\d+/
      var meetingId = info.el.id.match(regex)[0]
      $.ajax({
        url: '/' + locale + '/onboardees/meetings/' + meetingId,
        type: "GET",
        data: { 'devise': 'desktop' },
      });
    },
  });

  calendar.render();
}
export { initCalendarW1Mobile }


function initCalendarM1(){
  var calendarEl = document.getElementById('calendar_m1');
  var locale = calendarEl.dataset.locale
  var employeeId = calendarEl.dataset.employee
  var startDate = calendarEl.dataset.start
  var currentDate = calendarEl.dataset.current
  var zone = calendarEl.dataset.zone
  var current_step = calendarEl.dataset.step

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    plugins: [ dayGridPlugin ],
    header: {
      left: '',
      center: 'title',
      right: 'prev,next'
    },
    events: '/internal_api/v1/employees/' + employeeId + '/meetings?time_zone=' + zone + '&&split=true',
    nowIndicator: true,
    defaultDate: currentDate,
    defaultView: 'dayGridMonth',
    eventLimit: 1,
    contentHeight: 'auto',
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    },
    eventClick: function(info) {
      var regex = /\d+/
      var meetingId = info.el.id.match(regex)[0]
      $.ajax({
        url: '/' + locale + '/onboardees/meetings/' + meetingId,
        type: "GET",
        data: { 'devise': 'desktop' },
      });
    },
  });

  calendar.render();
}
export { initCalendarM1 }


function initCalendarY1(){
  var calendarEl = document.getElementById('calendar_y1');
  var locale = calendarEl.dataset.locale
  var employeeId = calendarEl.dataset.employee
  var startDate = calendarEl.dataset.start
  var stepEndDate = calendarEl.dataset.end
  var currentDate = calendarEl.dataset.current
  var zone = calendarEl.dataset.zone
  var current_step = calendarEl.dataset.step

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    plugins: [ dayGridPlugin, listPlugin ],
    header: {
      left: 'listCustom, dayGridMonth',
      center: 'title',
      right: 'prev,next'
    },
    views: {
      listCustom: {
        type: 'list',
        nowIndicator: true,
        duration: {year:1}
      }
    },
    events: '/internal_api/v1/employees/' + employeeId + '/meetings?time_zone=' + zone + '&&split=true',
    defaultDate: currentDate,
    defaultView: 'dayGridMonth',
    eventLimit: 1,
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    },
    eventClick: function(info) {
      var regex = /\d+/
      var meetingId = info.el.id.match(regex)[0]
      $.ajax({
        url: '/' + locale + '/onboardees/meetings/' + meetingId,
        type: "GET",
        data: { 'devise': 'desktop' },
      });
    },
  });

  calendar.render();
}
export { initCalendarY1 }


// ==============================================================================
// Meet_at Calendar
// ==============================================================================
function initializeMeetAtCalendar(){
  var calendarEl = document.getElementById('meet_at_calendar');
  var locale = calendarEl.dataset.locale
  var meeting_invitation = calendarEl.dataset.meeting_invitation
  var startDate = calendarEl.dataset.start
  var zone = calendarEl.dataset.zone
  var buttonTextList = calendarEl.dataset.button_text_list
  var buttonTextmonth = calendarEl.dataset.button_text_month

  var calendar = new Calendar(calendarEl, {
    locales: allLocales,
    locale: locale,
    height: '100%',
    plugins: [ listPlugin ],
    header: {
      left: 'listCustom',
      center: 'title',
      right: 'prev,next'
    },
    views: {
      listCustom: {
        type: 'list',
        nowIndicator: true,
        duration: { month:1 }
      }
    },
    buttonText: {
      list: buttonTextList,
      month: buttonTextmonth
    },
    events: '/api/v1/meeting_invitations/' + meeting_invitation + '/public_meetings?time_zone=' + zone + '&futur_meetings=true',
    defaultDate: startDate,
    defaultView: 'listCustom',
    eventLimit: 1,
    eventRender: function(info) {
      info.el.id = 'meeting-' + info.event.id
      info.el.classList.add('event-meeting')
    }
  });


  calendar.render();
}
export { initializeMeetAtCalendar }
