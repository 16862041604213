import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { surveyQuestionResourceId: String}

  openSidebar() {
    const controllerDiv = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
    const locale = document.querySelector('html').lang

    controllerDiv.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
      {
        "survey_type": "survey_line",
        "survey_question_resource_id": ${this.surveyQuestionResourceIdValue},
        "url": "/${locale}/actor/analyse/surveys"
      }
    `);
    document.getElementById('trigger-sidebar').click()
  }
}
