import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']
  static values = { url: String }

  connect() {
    this.analyse();
  }

  analyse() {
    document.getElementById('duplication_template_button').setAttribute('disabled', 'true');
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'script',
      data: { employee: { unit_organization_ids: this.inputTarget.value } }
    });
  }
}
