import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    if (!this.hasUrlValue) {
      console.error('You need to pass a url to fetch the remote content');
      return;
    }

    this.load();
  }

  load() {
    $.ajax({
      url: this.urlValue,
      dataType: 'html',
      success: (html) => {
        this.element.innerHTML = html;
      },
      error: () => {
        // if user's session is expired the server respond with a 401
        // which means that the request failed. We handle the failure in
        // this function which reloads the page, therefore redirecting us
        // to the sign-in page
        window.location.reload();
      }
    });
  }
}
