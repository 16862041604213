import Sortable from 'sortablejs';
import { csrfToken } from "utils/csrf_token";

export function draggableCharts() {
  var element = document.getElementById('chart-modules');
  new Sortable(element, {
    animation: 150,
    filter: '.undraggable',
    ghostClass: 'opacity-40',
    onEnd: function (event) {
      const ids = [];
      [...event.target.children].forEach((el, i) => {
        const id = el.dataset.id;
        if (id) { ids.push(id) };
      });
      $.ajax({
        url: '/hr/charts/chart_assignment_positions',
        type: 'post',
        headers: {
          'X-CSRF-Token': csrfToken()
        },
        data: { chart_assignment: ids },
        dataType: 'script'
      });
    }
  });
}
