import { Controller } from "@hotwired/stimulus"

// To reuse this modal controller you need to do the following things:

// To-do for each form initiating a dropzone :
// - Put a data-controller='utils--dropzones'
// - Put a data-utils--dropzones-url-value='INSERT_URL'
// - Put a data-utils--dropzones-extensions-value='INSERT_EXTENSION_WHITELIST' | example :  extension_whitelist(...)
// - Put a data-utils--dropzones-max-size-value='INSERT_MAX_SIZE' | example : t('upload.size.paperwork_file_max')
// - Put a data-utils--dropzones-file-size-value='INSERT_MAX_SIZE' | example : t('upload.size.paperwork_file_max')
// - Put a data-utils--dropzones-request-type-value='INSERT_REQUEST_TYPE' | example :'POST'
// - Put a data-utils--dropzones-param-name-value='INSERT_IMPUT_NAME' | example : 'paperwork_document[file]'
// - Put a data-utils--dropzones-multiple-value='INSERT_MULTIPLE_VALUE' | example : 'true'
// - Put a data-utils--dropzones-element-id-value='INSERT_ELEMENT_ID' | example : 'dropzone-elements'


// More details for each attributes :
//  - url is the target url where do you want use your dropped file
//  - extensions is the whitelist of extensions accepted by the dropzone input
//  - maxSize is the maximum size accepted for one dropped file
//  - fileSize is the maximum number of files that can be dropped
//  - requestType is the request type for your url (ex: POST or PATCH)
//  - paramName is the name of the file input
//  - disabled can be disable this input, if is true, while initiating it
//  - multiple is to allow multiple files to be dropped
//  - elementId is the id of the element where you want to store the data of the dropped files

// 'successmultiple' or 'success' is the callback of the JSON request (first call)
// We use this callback to get the created data and store them in the html
// then 'queuecomplete' is called. We use this to get the created data and make a second call with it to do actions in JS

// For more information to composed the html dropzone :
// https://docs.dropzone.dev/configuration/basics/layout
export default class extends Controller {
  static values = {
    url: String,
    extensions: String,
    maxSize: String,
    maxFiles: String,
    requestType: String,
    paramName: String,
    disabled: String,
    multiple: String,
    elementId: String
  }

  connect() {
    this.initDropzone();
  }

  options() {
    const baseOptions = {
      paramName: this.paramNameValue,
      acceptedFiles: this.extensionsValue,
      timeout: 180000, // ms
      maxFiles: this.maxFilesValue || 1,
      maxFilesize: this.maxSizeValue // MB
    }
    const multipleOptions = {
      uploadMultiple: true,
      parallelUploads: 5,
    }
    return this.multipleValue == 'true' ? { ...baseOptions, ...multipleOptions } : { ...baseOptions }
  }

  initDropzone() {
    const url = this.urlValue;
    const requestType = this.requestTypeValue;
    const element = document.getElementById(this.elementIdValue);

    let drop;
    let dropzone = new Dropzone(this.element, {
      ...this.options(),
      queuecomplete: () => {
        $.ajax({
          url: url,
          type: requestType,
          dataType: 'script',
          data: {
            drop: drop,
            elements: element ? JSON.parse(element.dataset.data) : null
          },
        });
      },
      drop: _files => {},
      successmultiple: (_files, data, _request) => {
        drop = 'success'
        // dataset-data returns : [{ id: id, class: 'ClassName' }, { id: id, class: 'ClassName' }]
        // data.elements is the elements we juste created from the controller
        if (element) {
          if (element.dataset.data == '' || element.dataset.data == undefined) {
            element.dataset.data = JSON.stringify(data.elements);
          } else {
            element.dataset.data = JSON.stringify(JSON.parse(element.dataset.data).concat(data.elements));
          }
        }
      },
      error: (_files, _error_message, _request) => { drop = 'error' },
      success: (_files, _data, _request) => { drop = 'success' }
    });

    if (this.disabledValue == 'true') {
      dropzone.disable();
    }
  }
}
