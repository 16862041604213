import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import * as applyFilters from '../../../../../components/tabulator/apply_filters';
import { search } from 'utils/search';
import { capitalizeWords } from 'utils/functions';
import { toggleActive, updateLocalStorage, selectAllElements, removeAllElements, activate, desactivate, setFiltersWithLocalStorage } from '../../../../../components/filter_utils';

export class BaseController extends Controller {
  static targets = [];
  static values = {};

  connect() {
    useClickOutside(this);
    setFiltersWithLocalStorage(this.pageValue, this.filterTypeValue, this.menuTarget)
    toggleActive(this.pageValue, this.selectTarget);
  }

  filter(event) {
    const current_value = event.currentTarget.dataset.selected == 'true';
    event.currentTarget.dataset.selected = !current_value;
    updateLocalStorage(this.pageValue, this.filterTypeValue, this.menuTarget);
    this.applyFilter();
    toggleActive(this.pageValue, this.selectTarget);
  }

  selectAll() {
    desactivate(this.selectTarget);
    selectAllElements(this.filterTypeValue);
    updateLocalStorage(this.pageValue, this.filterTypeValue, this.menuTarget);
    this.applyFilter();
  }

  removeAll() {
    activate(this.selectTarget);
    removeAllElements(this.filterTypeValue);
    updateLocalStorage(this.pageValue, this.filterTypeValue, this.menuTarget);
    this.applyFilter();
  }

  toggleMenu() {
    const current_state = this.menuTarget.parentElement.dataset.open == 'true';
    this.menuTarget.parentElement.dataset.open = !current_state;
  }
  closeMenu() {
    this.menuTarget.parentElement.dataset.open = false;
  }

  reset() {
    this.closeMenu();
    this.selectAll();
  }

  query() {
    const elementsToSearchIn = [...document.querySelectorAll(`.${this.filterTypeValue}-filter`)]
    search(this.queryInputTarget.value, elementsToSearchIn, 'end');
  }

  applyFilter() {
    const functionToCall = `apply${capitalizeWords(this.pageValue)}Filters`;
    applyFilters[functionToCall]();
  }
}
