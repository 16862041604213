import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static values = {
    date: String,
    url: String
  }

  loadDocumentsByDate(event) {
    const date = event.currentTarget.dataset.date;
    const dateBlock = document.getElementById(`date-${date}`);
    const documentsBlock = dateBlock.querySelector('#documents');

    if (documentsBlock) {
      documentsBlock.classList.toggle('hidden');
    } else {
      dateBlock.innerHTML = document.getElementById('placeholder').innerHTML;

      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          date: date,
        })
      })
      .then(response => response.json())
      .then(data => {
        dateBlock.innerHTML = data.content;
      }).catch(error => {
        console.log(error);
      })
    }
  }

  loadDocumentCsv(event) {
    const documentId = event.currentTarget.dataset.documentId;
    const documentBlock = document.getElementById(`document-${documentId}`);

    if (documentBlock.querySelector('#csv')) {
      // we 'close' the element
    } else {
      documentBlock.innerHTML = document.getElementById('placeholder').innerHTML;

      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          document_id: documentId,
        })
      })
        .then(response => response.json())
        .then(data => {
          documentBlock.innerHTML = data.content;
        }).catch(error => {
          console.log(error);
        })
    }
  }
}
