function identifyUser(userProperties) {
  // define propreties
  var userId = userProperties.id
  delete userProperties.id;

  // identify user
  analytics.identify(userId, userProperties);
}
export { identifyUser }


function trackPageLoad(eventTitle, eventProperties) {
  eventProperties['event_time'] = new Date().toISOString();
  setTimeout(() => { analytics.track(eventTitle, eventProperties) }, 100);
}
export { trackPageLoad }
