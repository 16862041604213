import confetti from 'canvas-confetti';

const rocketMoveAround = () => {
  setTimeout(() => {
    rocket.classList.add("rocket-move");
  });
  setTimeout(() => {
    rocket.classList.add("rocket-return");
    rocket.classList.remove("rocket-move");
  }, 1500);
  setTimeout(() => {
    rocket.classList.add("rocket-is-back");
  }, 3000);
  setTimeout(() => {
    rocket.classList.remove("rocket-is-back");
    rocket.classList.remove("rocket-return");
  }, 3500);
};

const celebrate = () => {
  showModal();
  setTimeout(() => rocketMoveAround(), 30000);
  setTimeout(() => fireWorks(), 250);
};

const showModal = () => {
  setTimeout(() => {
    $(modal).modal('show');
  }, 2000);
};

export { rocketMoveAround, celebrate }

const fireWorks = () => {
  const duration = 5000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 9999 };

  function randomInRange(min, max) {
    return randomNum() * (max - min) + min;
  }

  const interval = setInterval(function() {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 80 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: randomNum() - 0.2 } }));
    confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: randomNum() - 0.2 } }));
  }, 250);
}

// Alternative function to Math.random() which is a hotspot security
const randomNum = () => {
  const numArr = window.crypto.getRandomValues(new Uint8Array(1))[0].toString().split('');
  numArr.unshift('0', '.');
  return +numArr.join('');
};
