function verifyEmail(locale = 'fr') {
  const email = document.getElementById('user_email');

  const emailIcon = document.getElementById('email-checks-send-email')
  if (emailIcon) emailIcon.addEventListener('click', (event) => send_request());
  document.addEventListener('keydown', (event) => { if (event.key === 'Enter') send_request() });

  const send_request = () => {
    $.ajax({
      url: '/' + locale + '/auth_strategies',
      type: 'POST',
      dataType: 'script',
      data: { 'email': email.value }
    })
  };
}

export { verifyEmail }
