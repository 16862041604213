import { BaseFilterController } from "./base_filter_controller";

export default class extends BaseFilterController {
  static targets = ["select", "menu", "search"];

  controller() {
    return 'Organization';
  }

  column() {
    return 'organization_ids';
  }

  // return the controller name in html
  htmlTarget() {
    return 'organization';
  }

  search() {
    const value = this.searchTarget.value;
    const organizations = [...document.querySelectorAll('.organization')];
    organizations.forEach(organization => display(organization));
    const organization_names = organizations.map(organization => organization.firstElementChild.innerText);
    const unmatching_organizations = [];
    organization_names.forEach((organization_name, i) => {
      if (!organization_name.toLowerCase().includes(value.toLowerCase())) {
        unmatching_organizations.push(organizations[i]);
      }
    });
    unmatching_organizations.forEach(organization => hide(organization));
    this.selectTarget.parentElement.style.height = this.selectTarget.offsetHeight + this.selectTarget.nextElementSibling.offsetHeight + 'px';
  }
}

function display(organization) {
  organization.classList.remove('d-none');
  organization.classList.add('d-flex');
}

function hide(organization) {
  organization.classList.remove('d-flex');
  organization.classList.add('d-none');
}
