// To use this modal controller :

// STEP 1 : set the trigger
// The trigger element needs :
//   data-controller :                              'desktop--onboardee--modal'
//   data-action :                                  'click->desktop--onboardee--modal#call'
//   data-desktop--onboardee--modal-url-value :      url that you want to call
//   data-desktop--onboardee--modal-method-value :   method of the call request
// Example :
//   <button data-controller='desktop--onboardee--modal'
//           data-action='click->desktop--onboardee--modal#call'
//           data-desktop--onboardee--modal-url-value='/tests'
//           data-desktop--onboardee--modal-method-value='get'>
//     Click here
//   </button>

// STEP 2 : place your wrapper
// You need a element with the attribute 'modal-wrapper' to receive the new modal
// Example :
//   <div modal-wrapper></div>

// STEP 3 : define your modal's layout in back controller
// Example :

// respond_to do |format|
//   format.html { render layout: 'modal_full' }
// end

// STEP 4 (optional) : set the hide callback
// You can define a callback when the modal is hidden, in the view as content_for 'hide_callback'
// The callback function CANNOT take arguments and MUST BE KNOWN to the window
// Example :
//   content_for(:hide_callback, 'myFunction')

import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { connectScorm } from '../settings/templates/modal_controller';

export default class extends Controller {
  static values = {
    url: String,
    method: String,
    layout: String,
    open: Boolean
  }

  call(event) {
    event && event.preventDefault();
    this.clearOldModals();
    this.addNewModal();
  }

  // Clean old modals to safe the display of the new modal
  clearOldModals() {
    document.querySelectorAll('div[modal]').forEach((el) => { el.remove() });
  }

  addNewModal() {
    if (this.openValue) return; // Prevent fetching multiple modals
    this.openValue = true

    if (!this.hasUrlValue) {
      console.error('You need to pass a url to fetch the remote content');
      return;
    }

    $.ajax({
      url: this.urlValue,
      method: this.setMethod(),
      data: { 'modal_layout': true },
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      dataType: 'html',
      success: (html) => {
        this.append(html);
        this.setHideCallback();
        this.show();

        const preview_modal = document.getElementById('modal-full');
        const scormIframe = document.querySelector('iframe[title="scorm"]');
        if (scormIframe) { connectScorm(preview_modal); };
      },
      error: () => {
        // if user's session is expired the server respond with a 401
        // which means that the request failed. We handle the failure in
        // this function which reloads the page, therefore redirecting us
        // to the sign-in page
        window.location.reload();
      }
    });
  }

  setMethod() {
    return (this.methodValue || 'get').toUpperCase();
  }

  append(html) {
    let div = document.createElement("div");
    div.innerHTML = html;
    div.childNodes.forEach((node) => { document.querySelector('[modal-wrapper]').appendChild(node) })
  }

  show() {
    $('div[modal]').modal('show');
    document.querySelectorAll("div[data-controller='asks']").forEach((el) => {
      el.dataset.controller = 'asks-disabled'
    });
  }

  setHideCallback() {
    $('div[modal]').on('hide.bs.modal', this.hideCallback.bind(this));
  }

  // The callback is owned by the modal element, as data-hide-callback
  hideCallback() {
    const callback = $('div[modal]')[0].dataset.hideCallback
    if (callback && (window[callback] instanceof Function)) { window[callback]() }
    this.openValue = false
    document.querySelectorAll("div[data-controller='asks-disabled']").forEach((el) => {
      el.dataset.controller = 'asks'
    });
  }
}
