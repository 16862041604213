export const updateCounter = (page, counter = null) => {
  const tabulator = Tabulator.findTable(`#${page}`)[0];
  const activeRowsCount = counter ?? tabulator.getData('active').length;
  const labelElement = document.getElementById(`${page}-label`);
  const label = activeRowsCount <= 1 ? labelElement.dataset.singular : labelElement.dataset.plural
  document.getElementById(`${page}-count`).innerText = activeRowsCount;
  document.getElementById(`${page}-label`).innerText = label;
}

export const blankState = () => {
  return document.querySelector(`[data-code="no_resources_loaded"]`).innerHTML;
}

export const escapeHtml = text => {
  if (typeof (text) !== 'string') { return text };

  return text.replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export const displayUnscopedEnvironmentMessage = (finalHtml, singleEnvResource, unscopedUnitOrganizationCount) => {
  const unscopedUnitOrganizationDisplay = (singleEnvResource && unscopedUnitOrganizationCount == 1) ? 'd-block' : 'd-none';
  const unscopedUnitOrganizationsDisplay = (singleEnvResource && unscopedUnitOrganizationCount > 1) ? 'd-block' : 'd-none';
  finalHtml = finalHtml.replaceAll(`{{unscoped_unit_organization_display}}`, unscopedUnitOrganizationDisplay);
  finalHtml = finalHtml.replaceAll(`{{unscoped_unit_organizations_display}}`, unscopedUnitOrganizationsDisplay);
  finalHtml = finalHtml.replaceAll(`{{unscoped_unit_organizations_count}}`, unscopedUnitOrganizationCount);

  return finalHtml
}

export const unitOrganizationSorter = (_a, _b, aRow, bRow, _column, _dir, _sorterParams) => {
  let aCount = aRow.getData().full_unit_organization_count;
  let bCount = bRow.getData().full_unit_organization_count;
  if (aCount === 1 && bCount === 1) {
    return bRow.getData().unit_organization_names.localeCompare(aRow.getData().unit_organization_names);
  } else {
    return aCount - bCount;
  }
}
