import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String
  };

  connect() {
    $(this.selectTarget).selectize(this.config());
  }

  config() {
    return {
      ...this.basicConfig(),
      create: false,
      valueField: 'id',
      labelField: 'name',
      sortField: 'name',
      searchField: ['name', 'external_id'],
      render: {
        option: (item, escape) => {
          let label = escape(item.name);
          if (typeof item.external_id !== 'undefined' && item.external_id !== null && item.external_id != ''){
            label += `<span class="text-lighter margin-left-10px">[${escape(item.external_id)}]</span>`;
          }
          return `<div style="padding: 6px 15px;">${label}</div>`;
        }
      }
    }
  }

  basicConfig() {
    return {
      preload: true,
      onChange: (_value) => {
        let event = new Event('change');
        this.selectTarget.dispatchEvent(event)
      },
      load: (query, callback) => {
        $.ajax({
          url: this.urlValue,
          type: 'GET',
          dataType: 'json',
          data: {
            query: query
          },
          error: () => {
            callback();
          },
          success: (response) => {
            callback(response);
          }
        });
       }
    }
  }
}
