import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  select(event) {
    let element = event.target;
    let templateId = element.dataset.templateId;
    let dropzone = document.querySelector('.dropzone')
    let employeeId = dropzone.dataset.employeeId;
    let url = `/profile_photos?employee_id=${employeeId}&template_id=${templateId}`
    dropzone.dataset["utils-DropzonesUrlValue"] = url;
    document.querySelectorAll('.image').forEach((image) => {
      image.dataset.selected = false;
    });
    element.dataset.selected = true;
    Dropzone.forElement('.dropzone').destroy();
    Stimulus.controllers.find(function (el) {
      return el.identifier == 'utils--dropzones';
    }).connect();
  }
}
