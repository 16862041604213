import { Controller } from "@hotwired/stimulus"
import { markLinkAsActive } from 'desktop/actor/navbar/navbar_component_controller';

export default class extends Controller {
  static values = { linkTarget: String }

  connect() {
    const link = document.querySelector(`navbar [link=${this.linkTargetValue}]`);
    if (link) { markLinkAsActive(link) }
  }
}
