import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title"]

  connect() {
    this.titleTarget.innerText = document.getElementById('paperwork_resource_name').value;
  }

  updateTitle(event) {
    this.titleTarget.innerText = event.target.value;
  }
}
