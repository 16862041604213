import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['phoneNumber', 'otpEmail', 'otpSms']

    connect() {
        this.togglePhoneNumberInputs(); // Ensure correct initial state
        if(this.hasOtpEmailTarget){
          this.otpEmailTarget.addEventListener('change', () => this.togglePhoneNumberInputs());
        }
        if(this.hasOtpSmsTarget){
          this.otpSmsTarget.addEventListener('change', () => this.togglePhoneNumberInputs());
        }
    }

    togglePhoneNumberInputs() {
        if (this.hasOtpSmsTarget && this.otpSmsTarget.checked) {
            this.phoneNumberTarget.classList.remove('hidden');
        } else {
            if (this.hasOtpSmsTarget) {
              this.phoneNumberTarget.classList.add('hidden');
            }
        }
    }
}
