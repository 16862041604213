import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['sidebar', 'resizer']
  static values = {
    url: String
  }

  openSidebar(event) {
    const paperworkId = event.currentTarget.dataset.paperworkIdValue
    this.loadContent(paperworkId)
    this.sidebarTarget.setAttribute('activated', 'true');
  }

  closeSidebar() {
    this.sidebarTarget.setAttribute('activated', 'false');
    this.sidebarTarget.innerHTML = '';
  }

  loadContent(paperworkId) {
    fetch(`${this.urlValue}?paperwork_id=${paperworkId}`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.sidebarTarget.innerHTML = data.content;
      const resizer = this.resizerTarget;
      resizer.addEventListener("mousedown", () => {
        document.addEventListener("mousemove", this.resize, false);
        document.addEventListener("mouseup", () => {
          document.removeEventListener("mousemove", this.resize, false);
        }, false);
      });
    }).catch(error => {
      console.log(error);
    })
  }

  resize(e) {
    const sidebar = document.querySelector("#two-thirds-sidebar");
    sidebar.style.width = `${window.innerWidth - e.x}px`;
  }
}
