import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

import { chartTypes, chartOptions } from '../../../../utils/chartjs/options';
import { handleDisplay } from "./chart_filters_controller";

Chart.register(annotationPlugin)
Chart.register(ChartjsPluginStacked100);

export default class extends Controller {
  static targets = ['canvas']
  static values = { codeName: String, url: String }

  async connect() {
    const data = await this.getData(this.urlValue);
    document.querySelector('.track-number').innerText = data.triggered_tracks_count
    handleDisplay(data, this);
  }

  draw(data) {
    this.element.setAttribute('loading', false)
    this.chart = new Chart(this.canvasTarget, {
      type: this.getType(),
      data: data,
      plugins: this.getPlugins(),
      options: this.getOptions()
    });
    if (data.title) {
      this.element.parentElement.querySelector('#chart-title').innerHTML = data.title;
    }
  }

  // We need to convert the type (e.g. area to something that Chartjs understands)
  getType() {
    return chartTypes[this.codeNameValue];
  }

  async getData(url) {
    this.element.setAttribute('loading', true)
    return await $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json'
    });
  }

  getPlugins() {
    return (this.codeNameValue === 'trackHorizontalBars') ? [ChartDataLabels] : [];
  }

  getOptions() {
    return chartOptions[this.codeNameValue];
  }
}
