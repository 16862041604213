import { Controller } from "@hotwired/stimulus"

const DELAY = 400 // ms

export default class extends Controller {
  static targets = ['input', 'submit']

  connect() {
    this._search();

    window.setTimeout(() => { this.inputTarget.focus() }, DELAY);
  }

  // Call on input event
  updateSearch() {
    // Kill the previous delay
    window.clearTimeout(this.timer);
    // Set the new search delay
    this.timer = window.setTimeout(() => { this._search() }, DELAY)
  }

  _search() {
    this.submitTarget.click();
  }
}
