function displayKitCoordinators(id, locale) {
  $.ajax({
    url: '/' + locale + '/onboardees/kits/' + id,
    type: "GET",
  });
}
export { displayKitCoordinators }


///////////////////////////////////
// Desktop Part
///////////////////////////////////
function initNavigatorSection() {
  let mainNavLinks = document.querySelectorAll('#navigator a');
  let firstTop = document.querySelector('scroll-container').scrollTop + 50;
  let navigatorDate = document.getElementById('navigator-date')

  displayNavigator(firstTop)

  document.querySelector('scroll-container').addEventListener('scroll', event => {
    let fromTop = document.querySelector('scroll-container').scrollTop + 50;
    displayNavigator(fromTop)

    mainNavLinks.forEach(function(link) {
      let section = document.querySelector(link.hash);

      if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
        link.classList.add('current-link', 'background-color-customed', 'text-white', 'h-100');
        navigatorDate.innerHTML = link.dataset['date']
      } else {
        link.classList.remove('current-link', 'background-color-customed', 'text-white', 'h-100');
      }
    });
  });

  function displayNavigator(top) {
    let firstSection = document.querySelector(mainNavLinks[0].hash)
    let navigatorMenu = document.getElementById('navigator')
    let navigatorDate = document.getElementById('navigator-date')

    if (firstSection.offsetTop > top) {
      navigatorMenu.classList.add('invisible')
      navigatorDate.classList.add('invisible')
    } else {
      navigatorMenu.classList.add('visible')
      navigatorDate.classList.add('visible')
      navigatorMenu.classList.remove('invisible')
      navigatorDate.classList.remove('invisible')
    }
  };
};
export { initNavigatorSection }

function initStopPropagation() {
  $('.resources-list, .calendars').on('wheel', e => {
    e.stopPropagation();
  });
};
export { initStopPropagation }


function initSmoothScrolling() {
  document.querySelector('scroll-container').addEventListener("wheel", event => {
    // event.preventDefault();
    scrollIfBigWheel(event.deltaY);
  });
  document.querySelector('html').addEventListener('keydown', event => {
    scrollIfPress(event.keyCode);
  });

  function scrollIfBigWheel(deltaY) {
    var current_id = defineCurrentId();
    if ((deltaY >= 3)) {
      scrollSmoothTo($(current_id).next());
    } else if ((deltaY <= -3)) {
      scrollSmoothTo($(current_id).prev());
    }
  };

  function scrollIfPress(keyCode) {
    var current_id = defineCurrentId();
    if ((keyCode === 37) || (keyCode === 38)) {
      // Left and Up
      scrollSmoothTo($(current_id).prev());
    } else if ((keyCode === 39) || (keyCode === 40)) {
      // Right and Down
      scrollSmoothTo($(current_id).next());
    }
  }

  function defineCurrentId() {
    var current_nav_step = document.querySelector('.current-link');
    if (current_nav_step) {
      return current_nav_step.getAttribute("href");
    } else {
      return '#track_intro';
    }
  }
};
export { initSmoothScrolling }


function scrollSmoothTo(element) {
  if (element[0]){
    $('scroll-container').stop().animate( { scrollTop: element[0].offsetTop }, 200);
  };
};
export { scrollSmoothTo }


///////////////////////////////////
// Mobile Part
///////////////////////////////////
function scrollToNextBlock(element_tag) {
  var currentElement = document.querySelector(`#${element_tag}-block`)
  if (currentElement.nextElementSibling){
    $('#onboardee_track').stop().animate( { scrollTop: currentElement.nextElementSibling.offsetTop - 60 }, 200);
  }
}
export { scrollToNextBlock }
