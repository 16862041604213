import SwalController from "./swal_controller";

export default class extends SwalController {
  static values = { text: Object, confirm: Object, cancel: Object, buttonType: String, notice: String }

  confirmRequest(confirmValue) {
    flash(this.noticeValue, "notice");
    this.handleRequest(confirmValue);
  }
}
