import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { checkContrast: Boolean, message: String, invalidMessage: String, previewTarget: String }

  connect() {
    $(this.element).minicolors({
      letterCase: 'uppercase',
      change: this._updateInput.bind(this)
    })
  }

  _updateInput(hex) {
    if(hex && this.checkContrastValue && this._isValidContrastRatio(hex)) {
      this._hideError();
      this._previewColor();
      $(this.element.form).submit();
    } else if (hex && this.checkContrastValue) {
      this._showError();
      flash(this.messageValue, 'alert');
    } else {
      this._showError();
      flash(this.invalidMessageValue, 'alert');
    }
  }

  _isValidContrastRatio(hex = null) {
    let color = hex || this.element.value;
    let rgb = hexToRgb(color);
  
    // Choose background color (here, white for text on a light background)
    // By default, we choice de white background
    let backgroundColor = { r: 255, g: 255, b: 255 }; 
  
    // Relative luminance calculation for selected color and background color
    let luminanceColor = getLuminance(rgb.r, rgb.g, rgb.b);
    let luminanceBackground = getLuminance(backgroundColor.r, backgroundColor.g, backgroundColor.b);
  
    // Contrast calculation
    let contrastRatio = (luminanceBackground + 0.05) / (luminanceColor + 0.05);
    if (luminanceColor > luminanceBackground) {
      contrastRatio = (luminanceColor + 0.05) / (luminanceBackground + 0.05);
    }
  
    return (contrastRatio >= 3) ? true : false
  };

  _previewColor() {
    if (this.hasPreviewTargetValue) {
      let currentColor = this.element.value;
      document.documentElement.style.setProperty('--test-color', currentColor);
      document.documentElement.style.setProperty('--test-color-hover', hexToHSL(currentColor, -4));
    }
  }

  _showError() {
    this.element.closest(".form-group").classList.add('has-error');
  }

  _hideError() {
    this.element.closest(".form-group").classList.remove('has-error');
  }
}

// Fonction pour convertir hex en RGB
function hexToRgb(hex) {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
} 

function getLuminance(r, g, b) {
  let a = [r, g, b].map(function(v) {
    v /= 255;
    return (v <= 0.03928) ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}


function hexToHSL(hex, lightness_delta = 0) {
  // Enlève le # si présent
  hex = hex.replace(/^#/, '');

  // Convertit en RGB
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Trouver la valeur min et max
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);

  // Calculer la luminosité (L)
  let l = (max + min) / 2;

  let h, s;

  // Si min et max sont égaux, c'est un gris, donc pas de saturation et la teinte est 0
  if (max === min) {
      h = s = 0;
  } else {
      let d = max - min;

      // Saturation
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      // Teinte (H)
      switch (max) {
          case r:
              h = (g - b) / d + (g < b ? 6 : 0);
              break;
          case g:
              h = (b - r) / d + 2;
              break;
          case b:
              h = (r - g) / d + 4;
              break;
      }
      h = Math.round(h * 60);
  }

  // Luminosité et saturation en pourcentage
  s = Math.round(s * 100);
  l = Math.round(l * 100) + lightness_delta;

  return `hsl(${h}, ${s}%, ${l}%)`;
}
