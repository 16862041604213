function setAnswerTypeForm(answer_type, init_edit = false) {
  switch (answer_type) {
    case 'free_field':
      hideCommentable();
      hideAnwsersForm();
      checkCommentable(init_edit)
      break;
    case 'scale':
      showCommentable();
      hideAnwsersForm();
      uncheckCommentable(init_edit)
      break;
    default:
      showCommentable();
      showAnwsersForm();
      uncheckCommentable(init_edit)
      break;
  }

  function hideCommentable() {
    $('#commentable_option').removeClass('d-flex');
    $('#commentable_option').addClass('d-none');
  }
  function showCommentable() {
    $('#commentable_option').removeClass('d-none');
    $('#commentable_option').addClass('d-flex');
  }
  function hideAnwsersForm() {
    $('#anwser_form').addClass('d-none');
  }
  function showAnwsersForm() {
    $('#anwser_form').removeClass('d-none');
  }
  function checkCommentable(init_edit) {
    if (!init_edit) {
      $('input#survey_question_resource_commentable_true').prop("checked", true);
    }
  }
  function uncheckCommentable(init_edit){
    if (!init_edit) {
      $('input#survey_question_resource_commentable_false').prop("checked", true);
    }
  }
}
export { setAnswerTypeForm }


function selectSurveyAnswer(event, url) {
  event.preventDefault();
  $.ajax({
    url: url,
    type: "PATCH",
    data: '',
  });
}
export { selectSurveyAnswer }


