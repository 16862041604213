import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { flash } from '../../components/flashes';

export default class extends Controller {
  static targets = ['query', 'list', 'closeButton']
  static values = { url: String }

  connect() {
    let observer = new MutationObserver(this.delayRequest);
    observer.observe(this.element, { attributes: true });
  }

  // Executed callback when a mutation is observed
  delayRequest(mutationsList) {
    for (let mutation of mutationsList) {
      let delay = mutation.target.dataset.delay

      if ((mutation.attributeName == 'data-delay') && (delay)) {
        delaySelection(delay)
      }
    }
  }

  delayedSearch() {
    this.listTarget.dataset.delay = 1000;
  }

  search() {
    const query = this.queryTarget.value
    const url = this.urlValue + '?query=' + query
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
    .then(response => response.json())
    .then(responseJSON => {
      this.loadImages(responseJSON)
    });
  }

  loadImages(images) {
    [...document.querySelectorAll('.image')].forEach(el => el.remove());
    images.forEach(image => {
      let template = document.querySelector('#template-image').innerHTML;
      template = template.replace('{{{url}}}', image.url);
      template = template.replace('{{{id}}}', image.id);
      this.listTarget.insertAdjacentHTML('beforeend', template);
    })
  }

  update(event) {
    const url = event.target.src.replace('&w=200', '&w=2000');
    const id = event.target.parentElement.dataset.imageId;
    const photoType = document.querySelector('#cards-list').dataset.bannerType;
    document.querySelector("#close-button").click();
    flash(this.element.dataset.successNotice);
    fetch('/account_photos', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        'photo_type': photoType,
        id: id,
        url: url
      }),
    })
      .then(response => response.json())
      .then(responseJSON => {
        document.querySelector("#cards-list").style.backgroundImage = `url(${responseJSON.url})`;
      });
  }
}

// Need this variable to store and clear previous timeout
let timer;
// Clear previous tracksTimer and launch filter
function delaySelection(delay) {
  const controller = Stimulus.controllers.find(controller => controller.element.dataset.controller.includes('account-photos'))
  window.clearTimeout(timer);
  timer = window.setTimeout(() => { controller.search() }, parseInt(delay))
}
