import { Controller } from "@hotwired/stimulus"
import ProgressBar from 'progressbar.js';

// Example :
//   <div data-controller='user-role'>
//     <div id="A" data-user-role-target="screen">
//       Content
//       <button data-user-role-target="button" data-action="click->user-role#goToScreen" data-go-to-screen="B"> Go To B </button>
//     </div>
//     <div id="B" data-user-role-target="screen">
//       Content
//     </div>
//   </div>

export default class extends Controller {
  static targets = ['button', 'screen']
  static values = { goToPath: String }

  connect() {}

  goToScreen(event) {
    const currentButton = event.currentTarget
    const goToScreen = currentButton.dataset.goToScreen
    this.screenTargets.forEach((screen) => {
      if (screen.id == goToScreen) {
        screen.classList.remove('d-none')
        screen.classList.add('d-flex')
      } else {
        screen.classList.add('d-none')
        screen.classList.remove('d-flex')
      }
    })
    window.scrollTo(0, 0)
  }

  startLoader() {
    const delay = 5000
    progressBar(delay)
    waitAndExecute(() => {
      window.location.href = this.goToPathValue
    }, delay + 500);
  }
}

function progressBar(delay) {

  const bodyStyles = getComputedStyle(document.body);
  const primaryColor = bodyStyles.getPropertyValue('--custom-color-primary').trim();
  var bar = new ProgressBar.Circle(container, {
    color: primaryColor || '#2e5bec',
    trailColor: "var(--grey-200)",
    strokeWidth: 4,
    easing: 'easeInOut',
    duration: delay,
    text: {
      style: {
        color: '#2A3453',
        position: 'absolute',
        left: '50%',
        top: '50%',
        padding: 0,
        margin: 0,
        transform: {
          prefix: true,
          value: 'translate(-50%, -50%)'
        }
      },
      autoStyleContainer: true,
    },
    step: function (state, circle) {
      var value = Math.round(circle.value() * 100);
      if (value === 0) {
        circle.setText('');
      } else {
        circle.setText(value + " %");
      }
    }
  });
  bar.animate(1.0);
}

function waitAndExecute(callback, delay) {
  setTimeout(callback, delay);
}
