import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']
  static values = {
    applicationId: String,
    publicKey: String,
    displayEnv: Boolean,
    railsEnv: String
  }

  connect() {
    algoliaAutoCompFirstLevelOrganizations(
      this.publicKeyValue,
      `#${this.inputTarget.id}`,
      !!this.displayEnvValue,
      this.applicationIdValue,
      this.railsEnvValue
    )
  }
}

// For FIRST_LEVEL on the employees#new and #edit pages
function algoliaAutoCompFirstLevelOrganizations(public_key, id_to_complete, display_env, applicationID, RailsEnv){
  let organization_client = algoliasearch(applicationID, public_key);

  let organization_index = organization_client.initIndex(`UnitOrganization_${RailsEnv}`);

  autocomplete(id_to_complete,
  { hint: false }, {
    source: autocomplete.sources.hits(organization_index, {hitsPerPage: 5}),
    displayKey: 'name',
    templates: {
      suggestion: function(suggestion) {
        return '<span>' +
          suggestion._highlightResult.name.value + '</span><span>' +
          suggestion._highlightResult.first_level_parent_name.value + '</span>';
      }
    }
  })
  .on('autocomplete:selected', function(event, suggestion, dataset) {
    $('#organization_id').val(suggestion.id);
    if (display_env === true) {
      document.getElementById('environment_name').innerText = suggestion.algolia_environment_name;
    } else {
      document.getElementById('search-form').submit();
    }
  });
};
