import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  fieldInput = document.querySelector(`[data-input-field-id='${this.element.dataset.fieldId}']`);

  connect() {
    this.fieldInput.addEventListener('change', () => { this.check(); });
    this.check();
  }

  check() {
    this.element.dataset.met = this.isMet() ? true : false;
  }

  isMet() {
    const value = this.fieldInput.value;
    const operator = this.element.dataset.operator;

    switch (operator) {
      case 'is':
        return value == this.conditionValue() && value != '';
      case 'is_not':
        return value != this.conditionValue() && value != '';
      case 'is_empty':
        return !value;
      case 'is_included':
        return this.conditionCollection().includes(value);
      case 'is_not_empty':
        return !!value;
      default:
        return false
    }
  }

  conditionValue() {
    return this.element.dataset.value;
  }

  conditionCollection() {
    return JSON.parse(this.element.dataset.collection);
  }
}
