import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { loadTabulatorActions } from 'components/tabulator/actions';
import { applyActionsFilters } from 'components/tabulator/apply_filters.js';

export default class extends Controller {
  static targets = ['queryInput', 'cleanQuery']
  static values = {
    url: String
  }

  connect() {
    loadData(this.urlValue);
  }
}
function loadData(url) {
  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
  .then(response => response.json())
  .then(responseJSON => {
    const resources = responseJSON.data;
    const config = responseJSON.config;
    document.getElementById('actions').outerHTML = `<div id="actions"></div>`;

    loadTabulatorActions(resources, config);
    applyActionsFilters();
  });
}
function displayPlaceholder() {
  document.getElementById('actions').innerHTML = document.getElementById('placeholder').innerHTML;
}
