// This controller is used for the SearchBarComponent in a whole tabulator
// It cannot be used for a search inside a filter search bar (eg: environment filter)

// To make it works:
// 1. copy/paste this code snippet on the tabulator page where you want to have a query filter
// <div class="w-100" data-controller="desktop--actor--filter--search"
//                    data-desktop--actor--filter--search-page-value="PAGE">
//   <%= render Desktop::Actor::SearchBarComponent.new(:PAGE, 'desktop--actor--filter--search') %>
// </div>
// 2. replace the PAGE value by the name you want to have the filter

import { Controller } from "@hotwired/stimulus";
import * as applyFilters from 'components/tabulator/apply_filters';
import { capitalizeWords } from 'utils/functions';

export default class extends Controller {
  static targets = ['queryInput', 'cleanQuery'];
  static values = {
    page: String
  };

  connect() {
    this.queryInputTarget.value = localStorage.getItem(`${this.pageValue}_query`) || '';
    this._toggleIcon();
  }

  query() {
    this._toggleIcon();
    this._updateFilter();
    this._applyFilter();
  }

  cleanQuery() {
    this.queryInputTarget.value = '';
    this._toggleIcon();
    this._updateFilter();
    this._applyFilter();
    window.history.pushState({}, '', window.location.search.replace(/query=\w+/, ''))
  }

  _updateFilter() {
    localStorage.setItem(`${this.pageValue}_query`, this.queryInputTarget.value);
  }

  _toggleIcon() {
    this.cleanQueryTarget.classList.toggle('invisible', this.queryInputTarget.value.length === 0)
  }

  _applyFilter() {
    const functionToCall = `apply${capitalizeWords(this.pageValue)}Filters`;
    applyFilters[functionToCall]();
  }
}
