import { Controller } from "@hotwired/stimulus";
import { draggableTemplate, createInvisibleAddWeekButton } from 'pages/draggable_template/draggable_template';
import { cleanElementModalContent } from 'pages/draggable_template/utils';
import { cleanPreviewModal } from './modal_controller';
import { allowToRemoveTemporalityBlock } from 'pages/draggable_template/remove_tempo_block';

export default class extends Controller {
  connect() {
    $('#edit_element').on('hidden.bs.modal', function() {
      cleanElementModalContent();
    });

    $('#modal-view').on('hidden.bs.modal', function() {
      cleanPreviewModal();
    });

    draggableTemplate();
    $('#add_new_week').on('hide.bs.modal', function () {
      // remove potentials temporary weeks
      [...document.querySelectorAll(".week.dashed-border-blue")].forEach(e => e.remove());
      createInvisibleAddWeekButton();
    })
    allowToRemoveTemporalityBlock();
  }
}
