import { BaseFilterController } from "./base_filter_controller";

export default class extends BaseFilterController {
  static targets = ['select', 'menu', 'search']

  defineVariables() {
    this.singular = 'type'
    this.plural = 'types'
  }
}
