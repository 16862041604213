import { Controller } from "@hotwired/stimulus";
import { initializeSelectize, fields } from "packs/controllers/logic_conditions_controller.js";
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static values = { conditionalLogicId: String, id: String, operator: String, field: String }
  static targets = [ 'field', 'operator', 'dropdownTrigger']

  enableStoring = false;

  connect() {
    useClickOutside(this);
    this.initMouseHover();
    this.initializeOperator();
    this.initializeFields();
    this.enableStoring = true;
    this.element.dataset['readyToStore'] = 'true'
  }

  remove() {
    this.element.dataset['readyToStore'] = 'true';
    this.element.remove();
  }

  initializeOperator() {
    let element = this.operatorTarget;
    let options = this.operators();
    let callback = (value) => {
      this.operatorValue = value;
      this.storeConditionLogic();
    }

    initializeSelectize(element, options, callback, this.operatorValue);
  }

  initializeFields() {
    let element = this.fieldTarget;
    let options = fields();
    let callback = (value) => {
      this.fieldValue = value;
      this.storeConditionLogic();
    }

    initializeSelectize(element, options, callback, this.fieldValue);
  }

  storeConditionLogic() {
    if (this.enableStoring) { this.element.dataset['readyToStore'] = 'true' }
  }

  toJSON() {
    return {
      id: this.idValue || newId(),
      operator_type: this.operatorTarget.value,
      field_id: this.fieldTarget.value
    }
  }

  operators() {
    return JSON.parse(document.getElementById('logic-action-operator-types').value);
  }

  initMouseHover() {
    this.element.addEventListener('mouseover', () => { this.dropdownTriggerTarget.classList.remove('invisible')});
    this.element.addEventListener('mouseout', () => { this.dropdownTriggerTarget.classList.add('invisible')});
  }

  stickDropdown() {
    this.element.setAttribute('dropdown-focus', '');
  }

  clickOutside() {
    this.hideDropdown();
    this.element.removeAttribute('dropdown-focus');
  }

  hideDropdown() {
    $(this.element.querySelector('[data-toggle=dropdown]')).dropdown('hide');
  }
}
