import { format } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale';

export const tooltip = {
  enabled: false,
  position: 'nearest',
  intersect: true,
  external({ chart, tooltip }) {
    const generateTooltip = () => {
      const headText = headTextFor(chart, tooltip)
      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      const html = `<div class="chart-tooltip" style="opacity: 1;
                                                        left: ${positionX + tooltip.caretX + 'px'};
                                                        top: ${positionY + tooltip.caretY + 'px'};">
                        <div id="content">
                          <div class="head">${headText}</div>
                            <div class="body">
                              <p class="description">
                                 ${tooltip.body.map(bodyLine => bodyLine.lines).map((body, _index) => {
                                   const { description, figure } = (body[0].match(/^(?<description>.+): (?<figure>.+)$/) ||
                                                                   body[0].match(/(?<figure>.+)/)).groups;
                                   return `<span class="figure">${figure}</span>${description ? description : ''}`
                                  }).join('')}
                              </p>
                            </div>
                          </div
                        </div>
                      </div>`;

      const fragment = document.createRange().createContextualFragment(html);
      chart.canvas.parentNode.appendChild(fragment);
    }

    let tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltipElement) tooltipElement.remove();
    generateTooltip()

    // // When the cursor goes away opacity pass to 0 so we need to remove the tooltipElement
    // // in order to prevent duplicates
    tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltip.opacity === 0) {
      tooltipElement.remove();
    }
  }
}

export const surveyLinetooltip = {
  enabled: false,
  position: 'nearest',
  intersect: true,
  external({ chart, tooltip }) {
    const generateTooltip = () => {
      const countData = Object.fromEntries(
        Object.entries(chart.data.datasets[0].count).map(([k, v]) => [new Date(k).toISOString(), v])
      );
      const headText = headTextFor(chart, tooltip)

      let count = ''
      if (countData) {
        count = `<i class="fas fa-users"></i> ` + countData[new Date(tooltip.title[0]).toISOString()];
      }
      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      const html = `<div class="chart-tooltip" style="opacity: 1;
                                                        left: ${positionX + tooltip.caretX + 'px'};
                                                        top: ${positionY + tooltip.caretY + 'px'};">
                        <div id="content">
                          <div class="head">${headText}</div>
                            <div class="body">
                              <p class="description">
                                 ${tooltip.body.map(bodyLine => bodyLine.lines).map((body, _index) => {
                                   const { figure } = (body[0].match(/(?<figure>.+)/)).groups;
                                   return `<span class="figure">${figure}</span>${count}`
                                  }).join('')}
                              </p>
                            </div>
                          </div
                        </div>
                      </div>`;

      const fragment = document.createRange().createContextualFragment(html);
      chart.canvas.parentNode.appendChild(fragment);
    }

    let tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltipElement) tooltipElement.remove();
    generateTooltip()

    // // When the cursor goes away opacity pass to 0 so we need to remove the tooltipElement
    // // in order to prevent duplicates
    tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltip.opacity === 0) {
      tooltipElement.remove();
    }
  }
}

export const feedbackTooltip = {
  enabled: false,
  position: 'nearest',
  intersect: true,
  external({ chart, tooltip }) {
    const generateTooltip = () => {
      const headText = headTextFor(chart, tooltip)

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      const html = `<div class="chart-tooltip" style="opacity: 1;
                                                        left: ${positionX + tooltip.caretX + 'px'};
                                                        top: ${positionY + tooltip.caretY + 'px'};">
                        <div id="content">
                          <div class="head">${headText}</div>
                            <div class="body">
                              <p class="description">
                                 ${tooltip.body.map(bodyLine => bodyLine.lines).map((body, index) => {
                                   const { description, figure } = (body[0].match(/^(?<description>.+): (?<figure>.+)$/) ||
                                                                   body[0].match(/(?<figure>.+)/)).groups;
                                   return `<span class="figure">${figure}</span>${description}`
                                  }).join('')}
                              </p>
                            </div>
                          </div
                        </div>
                      </div>`;

      const fragment = document.createRange().createContextualFragment(html);
      chart.canvas.parentNode.appendChild(fragment);
    }

    let tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltipElement) tooltipElement.remove();
    generateTooltip()

    // // When the cursor goes away opacity pass to 0 so we need to remove the tooltipElement
    // // in order to prevent duplicates
    tooltipElement = chart.canvas.parentNode.querySelector('.chart-tooltip');
    if (tooltip.opacity === 0) {
      tooltipElement.remove();
    }
  }
}

const headTextFor = (chart, tooltip) => {
  const chartType = chart.canvas.parentElement.dataset['desktop-Hr-ChartCodeNameValue'];

  if (chartType != 'trackHorizontalBars') {
    const locale = document.querySelector('html').lang
    const dateFnsLocale = dateFnsLocales[locale];

    return format(Date.parse(tooltip.title[0]), 'MMM yy', { locale: dateFnsLocale });
  } else {
    return tooltip.title;
  }
}
