import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
    static targets = ['otp', 'userOtp', 'submitButton'];
    static values = {
        translations: Object
      }

    connect() {
        this.otpTarget.addEventListener('input', () => {
            this.userOtpTarget.classList.remove('has-error');
        });
    }

    sendRequest(event) {
        event.preventDefault();
        this.submitButtonTarget.disabled = true;

        const otpValue = this.otpTarget.value;

        fetch(`/fr/users/otp_validations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken()
            },
            body: JSON.stringify({ otp: otpValue })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                flash(this.translationsValue['valid'], 'notice');
                this.userOtpTarget.classList.remove('has-error');
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            } else {
                this.userOtpTarget.classList.add('has-error');
                this.submitButtonTarget.disabled = false;
                flash(this.translationsValue['invalid'], 'alert');
            }
        })
        .catch(error => {
            this.userOtpTarget.classList.add('has-error');
            this.submitButtonTarget.disabled = false;
            flash(this.translationsValue['invalid'], 'alert');
        });
    }
}
