// ============================================================================
// Functions to update easily the dashboard manager after validate an action
// ============================================================================

function updateDashboardAfterValidation(line_selector, employee_id, new_line, last_action) {
  hidePopover(line_selector);
  displayDoneActionMessage(line_selector);
  updateActionLines(line_selector, new_line, employee_id);
  if (last_action) { removeNextActionsButton(employee_id); }
}
export { updateDashboardAfterValidation }


function hidePopover(line_selector) {
  $(`${line_selector} div[data-toggle=popover]`).popover('hide');
}

function displayDoneActionMessage(line_selector) {
  $(line_selector).addClass('bg-green text-white');
  $(line_selector).html($('template#action-done').html());
}

function updateActionLines(line_selector, new_line, employee_id) {
  $(line_selector).fadeOut(400, function() {
    if (new_line) { addNextAction(new_line, employee_id) }
    // Remove validated action
    $(this).remove();
  });
}

function addNextAction(new_line, employee_id) {
  new_line.hide();
  $(`#actions-list-${employee_id}`).append(new_line);
  new_line.fadeIn(50);
  $('[data-toggle="popover"]').popover();
}

// If last_Action
function removeNextActionsButton(employee_id) {
  $(`#next-actions-buttons-${employee_id}`).remove();
}



function updateActionCountFor(employee_id, delta) {
  let block = document.getElementById(`employee-block-${employee_id}`);
  let count = parseInt(block.dataset.actionsCount);
  block.dataset.actionsCount = count + delta;
}
export { updateActionCountFor }
