function sendTestEmail(element) {
  let preview_content = document.getElementById('email-template-preview-text').innerHTML
  let subject = document.getElementById('email-template-preview-subject').innerHTML

  $.ajax({
    url: element.dataset['url'],
    type: "POST",
    data: { 'preview_content': preview_content, 'subject': subject }
  });
};
export { sendTestEmail };
