import { Controller } from "@hotwired/stimulus";
import { applyFormResourcesFilters } from 'components/tabulator/apply_filters';

export default class extends Controller {
  static targets = ['input', 'cleanQuery']

  connect() {
    const saved_query = localStorage.getItem('form_resources_query');
    this.inputTarget.value = saved_query;
    this.toggleIcon();
  }

  query() {
    this.toggleIcon();
    this.updateFilter();
    applyFormResourcesFilters();
  }

  updateFilter() {
    const query = this.inputTarget.value;
    localStorage.setItem('form_resources_query', query);
  }

  cleanQuery() {
    this.inputTarget.value = '';
    this.query();
  }

  toggleIcon() {
    if (this.inputTarget.value.length > 0) {
      this.cleanQueryTarget.classList.remove('invisible');
    } else {
      this.cleanQueryTarget.classList.add('invisible');
    }
  }
}
