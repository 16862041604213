import { Controller } from "@hotwired/stimulus";
import { applyResourcesFilters } from 'components/tabulator/apply_filters'

export default class extends Controller {
  static targets = ['queryInput', 'cleanQuery']
  delay = 400

  connect() {
    const saved_query = localStorage.getItem('resources_query');
    this.queryInputTarget.value = saved_query;
    this.toggleIcon();
  }

  query() {

    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.toggleIcon();
      this.updateFilter();
      applyResourcesFilters();
    },
    parseInt(this.delay))
  }

  updateFilter() {
    const query = this.queryInputTarget.value;
    localStorage.setItem('resources_query', query);
  }

  cleanQuery() {
    this.queryInputTarget.value = '';
    this.toggleIcon();
    this.updateFilter();
    applyResourcesFilters();
  }

  toggleIcon() {
    if (this.queryInputTarget.value.length > 0) {
      this.cleanQueryTarget.classList.remove('invisible');
    } else {
      this.cleanQueryTarget.classList.add('invisible');
    }
  }
}
