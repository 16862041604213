import { Controller } from "@hotwired/stimulus";
import { search } from 'utils/search';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ["menu", "select", "search"];
  static values = {
    table: String,
    exception_keys: Array
  };

  connect() {
    useClickOutside(this);
    let getTabulator;
    let tabulator;
    getTabulator = setInterval(() => {
      tabulator = Tabulator.findTable(this.tableValue)[0];
      if (tabulator) {
        clearInterval(getTabulator)
        this._renderColumns(tabulator)
      }
    }, 1000);
  }

  toggleMenu() {
    const currentState = this.selectTarget.parentElement.dataset.open == 'true'
    this.selectTarget.parentElement.dataset.open = !currentState;
  }

  closeMenu() {
    this.selectTarget.parentElement.dataset.open = false;
  }

  search() {
    const elementsToSearchIn = [...document.querySelectorAll('.visible-field-item')]
    search(this.searchTarget.value, elementsToSearchIn);
  }

  toggleColumn(event) {
    this.table.toggleColumn(event.currentTarget.dataset.columnName);

    var edit_type = this.table.getColumn(event.currentTarget.dataset.columnName).isVisible() == true ? 'show' : 'hide';
    trackPageLoad('Edit field visibility', {
      'edit_type': edit_type,
      'url': window.location.href,
      'referrer': document.referrer,
      'event_time': new Date().toISOString()
    })

    this._renderColumns(this.table)
    this.saveColumnState();
    if (this.searchTarget.value != '') { this.search() }
  }

  _renderColumns(tabulator) {
    const columns = tabulator.getColumns();
    const columnsHtml = columns.map(column => {
      const attributes = {
        columnName: column.getField(),
        isVisible: column.isVisible(),
        columnTitle: column.getDefinition()['title']
      }
      let html = document.getElementById('visible_fields').innerHTML;

      if (this.exceptionKeysValue.includes(attributes.columnName) || attributes.columnTitle == undefined) {
        html = '';
      } else {
        Object.entries(attributes).forEach(([k, v]) => html = html.replaceAll(`{{${k}}}`, v))
      }
      return html
    }).join('');
    this.menuTarget.querySelectorAll('.visible-field').forEach(el => el.remove());
    this.menuTarget.insertAdjacentHTML('beforeend', columnsHtml)
  }

  get table() {
    const tabulator = Tabulator.findTable(this.tableValue)[0];
    return tabulator;
  }

  getColumns() {
    return this.table.getColumns();
  }

  saveColumnState() {
    const columnStates = {};
    const columns = this.getColumns();

    columns.forEach((column) => {
      const columnName = column.getField();

      if(!this.exceptionKeysValue.includes(columnName)){
        const isVisible = column.isVisible();
        columnStates[columnName] = { visible: isVisible };
      }
    });
    localStorage.setItem("tabulator-" + this.tableValue, JSON.stringify(columnStates));
  }
}
