// This controller is used to initialize a search bar

// HOW DOES IT WORK ?
// 1. We set a mutation observer on the data-current-query attribute, on input
// 2. Everytime we change the input value, we change data-current-query value,
// 3. We delete the previous delayed request (if it exists), and we delay the new request.
//    This delay is defined by the delatValue.
// 4. When the delay is over, we fetch the item list thanks to the url (defined by urlValue) with
//    query as parameter (defined in data-current-query)
// 5. We replace the previous list (define by the searchTargetValue) by the new list,
//    if the requestQuery matchs with the new query

// BONUS 1 : when the input value is not empty, we display the reset button
// BONUS 2 : if data-change-path is true, we change the current url by the new one (with query as parameter)

// EXAMPLE HTML (search bar):
//     <div data-controller="utils--search-bars"
//         data-utils--search-bars-delay-value='200'
//         data-utils--search-bars-change-path-value='true'
//         data-utils--search-bars-url-value='<%= onboarding_track_template_assignments_url(subdomain: current_account.subdomain, track_id: track.id, locale: I18n.locale) %>'
//         data-utils--search-bars-search-target-value='#template-list'
//         class="d-flex overflow-hidden bg-white small-border-radius border-horizontal-grey-500"
//         style="border-width: 1px;">
//       <input type="text"
//             data-utils--search-bars-target="input"
//             data-action="keyup->utils--search-bars#search"
//             data-current-query='<%= params[:query] %>'
//             value='<%= params[:query] %>'
//             placeholder="<%= t('utilities.fields.search_template') %>"
//             style="width: 300px; border: none; height: 36px; padding: 0 15px; outline: none;">
//       <div data-action="click->utils--search-bars#reset"
//           data-utils--search-bars-target="reset"
//           class="invisible d-flex align-items-center pointer padded-right-10px">
//         <%= icon('fas', 'times-circle text-grey-500 text-16px') %>
//       </div>
//     </div>

// EXAMPLE HTML (list):
//     <div id="template-list" data-current-query='<%= params[:query] %>'></div>

import { Controller } from "@hotwired/stimulus"
import { Callbacks } from "jquery";
export default class utilsSearchBarsController extends Controller {
  static targets = ['input', 'reset']
  static values = {
    delay: String,         // in ms - waiting time before starting the request
    changePath: String,    // parameter involving or not the modification of the url by urlValue + new params
    url: String,           // url where to request to get the list of new items
    searchTarget: String,  // selector to define the list element to update
  }

  // Need this varaible to store and clear previous timeout
  searchTimer = null;

  connect() {
    this.updateCleanerDisplay();
    let observer = new MutationObserver(this.startSearch.bind(this));
    observer.observe(this.inputTarget, { attributes: true });
  }

  // Executed the search when a mutation is observed
  startSearch(mutationsList) {
    for(let mutation of mutationsList) {
      if ((mutation.attributeName == 'data-current-query') && this.delayValue) {
       this.delaySearch();
      }
    }
  }

  search() {
    this.inputTarget.dataset.currentQuery = this.inputTarget.value;
    this.updateCleanerDisplay();
  }

  reset() {
    this.inputTarget.dataset.currentQuery = '';
    this.inputTarget.value = '';
    this.updateCleanerDisplay();
    this.inputTarget.focus();
  }

  // Clear previous tracksTimer and launch filter and flag requests
  delaySearch() {
    let delay = this.delayValue
    window.clearTimeout(this.searchTimer);
    this.searchTimer = window.setTimeout(() => { this.launchSearch() }, parseInt(delay));
  }

  launchSearch() {
    if (this.hasSearchTargetValue) {
      let target = document.querySelector(this.searchTargetValue);
      target.dataset.currentQuery = this.inputTarget.dataset.currentQuery;
    }

    let url = this.withQueryUrl();
    if (this.changePathValue == 'true') { this.changePath(url); }
    this.fetchList(url);
  }

  // Change the current path of browser
  changePath(newUrl) {
    window.history.pushState(null, null, newUrl);
  }

  fetchList(url) {
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(responseJSON => {
      this.callback(responseJSON);
    });
  }

  // Callback run after fetch the data list
  callback(responseJSON) {
    const target = document.querySelector(this.searchTargetValue);
    const list = responseJSON.list
    const responseQuery = responseJSON.query || '';
    // Replace the list only if current query match with the response query
    if (target.dataset.currentQuery == responseQuery) { $(target).replaceWith(list); }
  }

  // Composed and return the new URL with query params
  withQueryUrl() {
    const query = this.inputTarget.dataset.currentQuery;
    const url = new URL(this.urlValue);
    if (query && (query !== '')) { url.searchParams.set('query', query) }

    return url;
  }

  // Cleaner Methods
  updateCleanerDisplay() {
    this.inputTarget.value == '' ? this.hideCleaner() : this.showCleaner()
  }

  showCleaner() {
    this.resetTarget.classList.remove('invisible');
  }

  hideCleaner() {
    this.resetTarget.classList.add('invisible');
  }
}
