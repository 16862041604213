import { Controller } from "@hotwired/stimulus"
import { initPieChart } from 'components/pie_chart';


export default class extends Controller {
  static values = { data: Array }

  connect() {
    initPieChart(this.dataValue);
  }
}
