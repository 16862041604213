import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    defaultDate: String,
    format: String,
    locale: String,
  }

  connect() {
    const date = this.defineDate();

    $(this.element).datetimepicker({
      format: this.formatValue,
      locale: this.localeValue,
      date: date
    });
    this.element.disabled = false;
  }

  defineDate() {
    let date;

    if (this.element.value != ''){
      date = moment(this.element.value, this.formatValue).toDate();
    } else {
      date = moment(this.defaultDate, this.formatValue).toDate();
    }

    return date;
  }
}
